import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import UnavailableBanner from './UnavailableBanner';
import { Grid } from '@material-ui/core';

const styles = theme => ({
// const styles = theme => console.log(theme) || ({
    root: {
      flexgrow: 1,
      maxWidth: "100%",
      marginBottom: '525px'
    },
    landingHeader: {
      display: 'flex',
      width: '100%',
      background: 'transparent',    
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    landing: {    
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
      backgroundSize: "cover",		
      float: 'left',
      width: '100%',
    },
    topheader: {
      padding: '0px 32px 7px 40px',
    },
    currencytext: {
      color: '#1E1E1E',
      fontWeight: '654',
    }
  })

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, info);
    }
  
    render() {
      const {classes} = this.props;

      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <Grid container className={classes.root} spacing={0} direction="column" justifyContent="center" alignItems="inherit">
            
              <div className={classes.landing}>    
                <div className={classes.landingHeader}>
                  <UnavailableBanner/>
                </div>
              </div>
          </Grid>  
        );
      }
     return this.props.children;
    }
  }

  export default withStyles(styles)(ErrorBoundary);