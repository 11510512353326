import { combineReducers } from 'redux'
import start from './start'
import activeStep from './activeStep'
import person from './person'
import addresses from './addresses'
import thirdparty from './thirdparty'
import exportImport from './exportImport'
import mailship from './mailship'
import ship from './ship'
import carrier from './carrier'
import currencies from './currencies'
import coins from './coins'
import others from './others'
import review from './review'
import receipt from './receipt'

export default combineReducers({
  start,
  activeStep,
  person,
  addresses,
  thirdparty,
  exportImport,
  mailship,
  ship,
  carrier,
  currencies,
  coins,
  others,
  review,
  receipt,
})