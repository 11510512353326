import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Grid,Typography } from '@material-ui/core';




const styles =  theme => ({
  root: {
    width: '100%',
    backgroundColor: '#cc1f36'
  }
});

class UnavailableBanner extends React.Component{
  // constructor(props) {
  //   super(props);
  // }
  render() {
    const {classes} = this.props;

    return(
    <Grid className={classes.root} container direction="column" justifyContent="center" alignItems="center">
        <Typography variant="h6" component="h6">
          <center style = {{color:'#F3F3F3'}}>Application Unavailable: Please refer to 
            <NavLink to="/help" target="help" rel='noopener noreferrer' style={{ textDecoration: 'none                                                                                              ',color:'#F3F3F3' }}> FAQ </NavLink>
             for how to submit paper form</center>
        </Typography>
    </Grid>
    );
  }
}
export default withStyles(styles)(UnavailableBanner);