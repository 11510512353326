import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { CircularProgress } from '@material-ui/core';
import { css } from '@emotion/core';
import { FadeLoader } from 'react-spinners';


const styles =  theme => ({
    overlay: {
      position: 'fixed',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(52, 52, 52, 0.2)',
      zIndex: 2,
      cursor: 'pointer',
    },
    
    text: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      fontSize: '50px',
      color: 'white',
    }
});

const override = css`
top: 50%;
left: 50%;
`;

class LoadingOverlay extends React.Component{
    // constructor(props) {
    //     super(props);
    //   }
    render(){
        const {classes} = this.props;
        return(
            <div className={classes.overlay}>
                {/* <CircularProgress className={classes.text} size={90}/> */}
                <FadeLoader 
                  css={override} 
                  color={'#ffffff'} 
                  height={30}
                  width={5}
                  radius={20}
                  margin={'20px'}/>
            </div>
        );
    }
}

export default withStyles(styles)(LoadingOverlay);