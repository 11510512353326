import { connect } from 'react-redux'

import { resetStart, resetStep, resetPerson, resetAddress, resetThirdParty, resetMailShip, resetExportImport, 
  resetCurrencyItem, resetCoinItem, resetOtherItem, resetReviewSign, resetReceipt, } from '../actions'
import Confirmation from '../component/Confirmation'

const mapStateToProps = state => ({
  activeStep: state.activeStep,
  start: state.start,
  receipt: state.receipt
})

const mapDispatchToProps = (dispatch) => ({
  resetStart: () => dispatch(resetStart()),
  resetStep: () => dispatch(resetStep()),
  resetPerson: () => dispatch(resetPerson()),
  resetAddress: () => dispatch(resetAddress()),
  resetExportImport: () => dispatch(resetExportImport()),
  resetMailShip: () => dispatch(resetMailShip()),
  resetThirdParty: () => dispatch(resetThirdParty()),
  resetCurrencyItem: () => dispatch(resetCurrencyItem()),
  resetCoinItem: () => dispatch(resetCoinItem()),
  resetOtherItem: () => dispatch(resetOtherItem()),
  resetReviewSign: () => dispatch(resetReviewSign()),
  resetReceipt: () => dispatch(resetReceipt()),
})

export default connect(mapStateToProps, mapDispatchToProps) (Confirmation);
