const carrier = (state = initialCarrier, action) => {
    switch (action.type) {
      case 'RESET_CARRIER':
        return initialCarrier
      case 'UPDATE_CARRIER':
        let newCarrier = {
          ...state,
          ...action.carrier,          
        }
        return newCarrier        
      default:
        return state    
    }
  }
  
  const initialCarrier = {
      first: '',
      last: '',
      middle: '',
      business: '',      
  }

  export default carrier