import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button, Divider, Checkbox, IconButton,Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import ReactTooltip from "react-tooltip";

// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';

// import { GetReferenceCurrencies } from '../service/ReferenceData';
import MDTextField from './custom/MDTextField';
import AsyncReactSelect from './custom/AsyncReactSelect';
import NavigationButtons from '../container/NavigationButtons';

// function createData(type, name, rate, amount, usdequivalent) {
//   return { type, name, rate, amount, usdequivalent };
// }

// const allrows = [
//   createData('Currency', 'United States', 0.11254, 16584, 45789),
//   createData('Coins', 'Belgium', 0.19954, 11254, 10089),
//   createData('Currency', 'Japan', 1.13354, 55854, 98589),
//   createData('Other', 'Germany', 1.55896, 99854, 112545),
  // createData('Cupcake', 305, 3.7, 67, 4.3),
  // createData('Donut', 452, 25.0, 51, 4.9),
  // createData('Eclair', 262, 16.0, 24, 6.0),
  // createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  // createData('Gingerbread', 356, 16.0, 49, 3.9),
  // createData('Honeycomb', 408, 3.2, 87, 6.5),
  // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  // createData('Jelly Bean', 375, 0.0, 94, 0.0),
  // createData('KitKat', 518, 26.0, 65, 7.0),
  // createData('Lollipop', 392, 0.2, 98, 0.0),
  // createData('Marshmallow', 318, 0, 81, 2.0),
  // createData('Nougat', 360, 19.0, 9, 37.0),
  // createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function desc(a, b, orderBy) {
  const valueA = a[orderBy]
  const valueB = b[orderBy]

  let realA = typeof valueA === 'object'? valueA['label'] : valueA
  let realB = typeof valueB === 'object'? valueB['label'] : valueB

  if (orderBy === 'amount') {
    realA = parseInt(valueA.replace(/,/g, ''))
    realB = parseInt(valueB.replace(/,/g, ''))
  }

  // if (b[orderBy] < a[orderBy]) {
  if (realB < realA) {
    return -1;
  }
  // if (b[orderBy] > a[orderBy]) {
  if (realB > realA) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// const headCells = [
//   { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
//   { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
//   { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
//   { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
//   { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
// ];

const emptyCurrency = {id: '', type: '', country: '', exchangeRate: '', amount: '', usdEquivalent: ''};

const headCells = [
  { id: 'type', numeric: false, disablePadding: false, label: 'Type', hide: 'true' },
  { id: 'country', numeric: false, disablePadding: true, label: 'Country/Currency' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount',  hide: 'true' },
  { id: 'exchangeRate', numeric: true, disablePadding: false, label: 'Exchange Rate',  hide: 'true' },
  { id: 'usdEquivalent', numeric: true, disablePadding: false, label: 'USD', fixed2: 'true' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, isEditing } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all Monetary Instruments' }}
            disabled={isEditing}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            className={headCell.hide === 'true'? classes.hide : headCell.fixed2 === 'true'? classes.fixed2 : classes.fixed}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={classes.header}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    // color: '#000000',
    // marginLeft: '270px'
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, onDeleteSelected, onEditSelected, isEditing } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} {isEditing? ' editing' : ' selected'}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Monetary Instruments List
        </Typography>
      )}

      {(numSelected === 1 && !isEditing) &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit">
            <EditIcon onClick={onEditSelected} />
          </IconButton>
        </Tooltip>
      }

      {(numSelected > 0 && !isEditing) &&
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon onClick={onDeleteSelected} />
          </IconButton>
        </Tooltip>
      }

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon onClick={onDeleteSelected} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // backgroundColor: 'white'
  },
  paper: {
    // width: '100%',
    // marginBottom: theme.spacing(2),
    // backgroundColor: 'white',
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    textAlign: 'left',
    justify: 'center',
    color: theme.palette.text.primary,    
    backgroundColor: '#ffffff',
  },
  table: {
    minWidth: 750,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  title: {
    flex: '1 1 100%',
    color: '#000000',
    // marginLeft: '270px'
  },
  header: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(10),      
    },
  },
  inrow: {    
    marginLeft: theme.spacing.unit * 0.5,
    [theme.breakpoints.down('xs')]: {
      display: 'block'    
    },    
  },
  addItem: {
    marginRight: theme.spacing.unit * 6.0,
    // marginBottom: theme.spacing.unit * 1.2,
    color: '#ffffff',
    fontSize: theme.typography.pxToRem(14),
    textTransform:'capitalize',
    backgroundColor: '#3939c8'
  },
  divider: {
    marginTop: '28px',
    backgroundColor: "#e2e2e2",
    height: '8px'
  },
  total: {
    marginRight: theme.spacing.unit * 1.1,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    paddingTop: '0px',
    MarginBottom: '0px',
    fontWeight: "680",
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
      marginRight: theme.spacing.unit * 0.2,
    },
  },
  infoIcon: {
    //     fontSize: theme.typography.pxToRem(18),
        verticalAlign: 'text-top',
        color: '#3939c8',
        paddingTop: '0px',
        paddingBottom: '12px',
      },

  tooltip: {
    width:300,
    maxWidth:'40%'
  },
  fixed: {
    [theme.breakpoints.down('xs')]: {
      width: 20,
    },
  },
  fixed2: {
    [theme.breakpoints.down('xs')]: {
      width: 8,
    },
  },
  hide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  
  infoText: {
    marginRight: theme.spacing.unit * 1.1,
    fontSize: theme.typography.pxToRem(12),
    color: 'red',
    paddingTop: '0px',
    MarginBottom: '0px',
    fontWeight: "500",
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
      marginRight: theme.spacing.unit * 0.2,
    },
  }
}));

export default function MonetaryInstrument(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('type');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const [rows, setRows] = React.useState(allrows);
  const [currency, setCurrency] = React.useState(emptyCurrency);
  const [currencies, setCurrencies] = React.useState([]);
  const [addDisabled, setAddDisabled] = React.useState(true)
  const [error, setError] = React.useState('');
  const [errorText, setErrorText] = React.useState('');
  const [summary] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [editing, setEditing] = React.useState(false);
  // const [testlabel, setTestLabel] = React.useState('Nothing here');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = currencies.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleEditSelected = event => {    
    const selectedIndex = currencies.map(function(e) { return e.id; }).indexOf(selected[0]);
    const newCurrency = currencies[selectedIndex];

    setCurrency(newCurrency);
    setEditing(true);
  }
  
  const handleDeleteSelected = event => {
    const newRows = currencies.filter( ( el ) => 
      !selected.includes( el.id )
    );

    let newTotal = 0;
    newRows.map(n => 
      newTotal += n.usdEquivalent
    )
    
    setTotal(newTotal);
    setCurrencies(newRows);
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectChange = (propertyName, selectedOption) => {
    setCurrency({...currency, 
           country: selectedOption, 
           exchangeRate: selectedOption.exchangeRate,
           currencyName: selectedOption.currencyName,
           usdEquivalent: currency.amount >= 1 ? 
                  Math.round(currency.amount * selectedOption.exchangeRate) : 0
    });
    
    if (currency.type.value === undefined || selectedOption.value === undefined 
      || currency.amount === '' || (parseInt(currency.amount)) <= 0) {
      setAddDisabled(true)
    } else {
      setAddDisabled(false)
    }

  }

  const handleTypeSelectChange = (propertyName, selectedOption) => {    
    setCurrency({...currency, 
           type: selectedOption,           
    });
    if (currency.country === "" || selectedOption.value === undefined 
      || currency.amount === '' || (parseInt(currency.amount)) <= 0) {
      setAddDisabled(true)
    } else {
      setAddDisabled(false)
    }

  }

  const handleChange = (propertyName, value) => { 
    setCurrency({...currency,
      amount: value,      
      usdEquivalent: value >= 1 ? Math.round(value * currency.exchangeRate) : 0
    });

    if (currency.type.value === undefined || currency.country.value === undefined 
      || value === '' || (parseInt(value)) <= 0) {
      setAddDisabled(true)
    } else {
      setAddDisabled(false)
    }

    if (propertyName === 'amount') {
      handleAmountError(value);
    }
   }
  
  const handleAmountError = (value) => { 
    let isnumberpattern = /^\d+$/
    
    if (value !== null && value !== undefined) {
      if (!(isnumberpattern.test(value))) {
        setError(true);
        setErrorText("Please enter a numeric value");
        setAddDisabled(true)
        setCurrency({...currency,
          amount: value,
          usdEquivalent: 0
        });
      }
      else if (value > 2147483647 || (Math.round(value * currency.exchangeRate) > 2147483647)) { //oracle maximum integer limit          
          setError(true);
          setErrorText("Maximum allowed amount exceeded");
          setAddDisabled(true)
          setCurrency({...currency,
            amount: value,
            usdEquivalent: 0
          });
        }
      else {
        //resetting error and errorText
        setError(false);
        setErrorText("");
        // setAddDisabled(false)
        setCurrency({...currency,
          amount: value,      
          usdEquivalent: value >= 1 ? Math.round(value * currency.exchangeRate) : 0
        });
      }
    }
  }

  const handleError = (p, e) => {

  }

  const addCurrencyItem = (event) => {
    currencies.push({
          ...currency, 
          country: currency.country, 
          type: currency.type,
          id: currency.type.value + currency.country.value + currency.amount
    });
    setCurrencies(currencies);
    setCurrency(emptyCurrency);
    setAddDisabled(true);
    setTotal(total + currency.usdEquivalent)
    // setTestLabel('Adding items ' + currencies.length)
   }

   const editCurrencyItem = event => {    
    const selectedIndex = currencies.map(function(e) { return e.id; }).indexOf(selected[0]);
    // const newCurrency = currencies[selectedIndex];

    currencies[selectedIndex] = {
      ...currency,
      country: currency.country, 
      type: currency.type,
      id: currency.type.value + currency.country.value + currency.amount
    }

    let newTotal = 0;
    currencies.map(n => 
      newTotal += n.usdEquivalent
    )

    setCurrencies(currencies);
    setCurrency(emptyCurrency);
    setAddDisabled(true);
    setEditing(false);
    setSelected([]);
    setTotal(newTotal);
  }

  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, currencies.length - page * rowsPerPage);


  return (
    <div className={classes.root}>

      <Paper className={classes.paper}>
      <Grid className={classes.inrow} container direction="row" justify="center" alignItems="center">
        <Grid item>
          <Typography className={classes.title} variant="h6" id="tableTitle">Add Monetary Instrument</Typography>
        </Grid>
      </Grid>
      
        <Grid className={classes.inrow} container direction="row" justify="space-between" alignItems="center">
          <Grid item>
          <AsyncReactSelect name='type' label='Monetary Instrument Type' value={currency.type} placeholder='Search for Type' errorText='Please select Type' 
              optionsType='miType' handleChange={handleTypeSelectChange} handleError={handleError} required={true} disabled={summary? true : false} uniqId={'currencytype'} summary={summary} />

          </Grid>
          <Grid item>
          <AsyncReactSelect name='currencycountry' label='Country of Origin' value={currency.country} placeholder='Search by Country or Currency' errorText='Please select Country of Origin' 
                      optionsType='currency' handleChange={handleSelectChange} handleError={handleError} required={true} disabled={summary? true : false} summary={summary} uniqId={'currencycountry'} />
          </Grid>
        </Grid>
        
        <Grid className={classes.inrow} container direction="row" justify="space-between" alignItems="center">  
          <Grid item>
          <MDTextField name='amount' label='Amount' value={(currency.amount).toLocaleString(undefined, { minimumfractiondigits: 2 })} 
              error = {error} errorText = {errorText} handleChange={handleChange} handleError={handleError}
              inputProps={{ maxLength: 10, }}
              required={true} disabled={summary? true : false} currency={true} summary={summary} />
          </Grid>
          <Grid item>
          <MDTextField name='exchangeRate' label='Exchange Rate' value={currency.exchangeRate} handleChange={handleChange} inputProps={{ tabindex: -1,}}
              required={false} disabled={true} currency={true} summary={summary} />          
          </Grid>
          <Grid item>
          <MDTextField name='usdEquivalent' label='USD Equivalent' 
              value={Math.round(currency.usdEquivalent).toLocaleString('en', { style: 'currency', currency: 'USD', useGrouping: true,maximumfractiondigits:0, minimumfractiondigits: 0 })} 
              inputProps={{ tabindex: -1,}} handleChange={handleChange} required={false} disabled={true} currency={true} summary={summary} />
          </Grid>
          <Grid item>
          <div>
          {!editing ? (
          <Button size="small" variant="contained" className={classes.addItem} id="addCurrency" disabled={addDisabled} onClick={event => addCurrencyItem(event)}>            
            <AddCircleIcon size="small" />
            Add
          </Button>
          ) : (
          <Button size="small" variant="contained" className={classes.addItem} id="updateAmount" disabled={addDisabled} onClick={event => editCurrencyItem(event)}>            
            <EditIcon size="small" />
            Update
          </Button>) 
          }
          </div>
          </Grid>
        </Grid>        
        
        <Divider className={classes.divider} variant="fullWidth" />

        <EnhancedTableToolbar 
          numSelected={selected.length}
          onDeleteSelected = {handleDeleteSelected}
          onEditSelected = {handleEditSelected}
          isEditing = {editing}
        />
        
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={currencies.length}
              isEditing = {editing}
            />
            {/* Currencies Length: {currencies.length + testlabel} */}
            <TableBody>
              {stableSort(currencies, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => !editing? handleClick(event, row.id) : undefined}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.type}
                      selected={isItemSelected}                     
                    >
                      <TableCell className={classes.fixed} padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          disabled={editing}
                        />
                      </TableCell>
                      <TableCell className={classes.hide} component="th" id={labelId} scope="row" padding="none">
                        {row.type.label}
                      </TableCell>
                      <TableCell className={classes.fixed} align="left">{row.country.label}</TableCell>
                      <TableCell className={classes.hide} align="right">
                        {Math.round(row.amount).toLocaleString('en', { style: 'decimal', useGrouping: true, maximumfractiondigits:0, minimumfractiondigits: 0 })}                        
                      </TableCell>
                      <TableCell className={classes.hide} align="right">{row.exchangeRate}</TableCell>
                      <TableCell className={classes.fixed2} align="right">
                      {(row.usdEquivalent).toLocaleString('en', { style: 'currency', currency: 'USD', useGrouping: true, maximumfractiondigits:0, minimumfractiondigits: 0 })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
 
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={currencies.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <Grid container direction="row" justify="flex-end">                
          <Typography className={classes.total} variant='subtitle2'>
              USD Equivalent Total: {(Math.round(total)).toLocaleString('en', { style: 'currency', currency: 'USD', useGrouping: true, maximumfractiondigits:0, minimumfractiondigits: 0 })} USD
              <span data-tip="" data-for='monetary'>                  
                  <IconButton aria-label="Info">
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton> </span>
                  <ReactTooltip id ='monetary' className={classes.tooltip}>
                    {"Important Note:The “Next” button will not be enabled until you have  $10,000 USD or more and less than  $2,147,483,647 USD in total currency or monetary instruments. If the total amount you are reporting is below $10,000 USD, you do not need to complete this form."}
                    </ReactTooltip>
                            {total> 2147483647?              
                   <Typography className={classes.infoText} variant='subtitle2'>Maximum allowed amount exceeded </Typography>        
          :undefined
              }                           
          </Typography>                
        </Grid>

       
             
        
        <NavigationButtons currencies={currencies} allReqDone={total >= 10000 && total< 2147483647} /> 
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      
    </div>
  );
}
