import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';

export default function ErrorDialog(props) {
  const [open, setOpen] = React.useState(true);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const errorMessage = props.errorMessage;
  var onDismiss = props.onDismiss;

  const handleClose = () => {
    onDismiss();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"We're having trouble processing your request"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There was an issue completing your form. The form was not submitted. Please try again later. If this issue continues, please check the FAQ section for how to submit a paper form.
          </DialogContentText>
          <DialogContentText>
            {errorMessage}
          </DialogContentText>
        </DialogContent> 
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}