import { connect } from 'react-redux'
import MailShip from '../component/MailShip'

const mapStateToProps = state => ({
  start: state.start,
  activeStep: state.activeStep,
  mailship: state.mailship,
  ship: state.ship,
  carrier: state.carrier,
  addresses: state.addresses
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps) (MailShip);
