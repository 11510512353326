import { connect } from 'react-redux'

import { updateStart  } from '../actions'
import Start from '../component/Start'

const mapStateToProps = state => ({
    start: state.start,
    activeStep: state.activeStep
})

const mapDispatchToProps = (dispatch) => ({
    updateStart: (start) => dispatch(updateStart(start)),
})

export default connect(mapStateToProps, mapDispatchToProps) (Start);
