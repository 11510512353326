const currencies = (state = [currency], action) => {
    switch (action.type) {
      case 'RESET_CURRENCY_ITEM':
        return [currency]
      case 'ADD_CURRENCY_ITEM':
        let newCurrencyItem = {...currency, id:state.length}       
        return [...state, newCurrencyItem]
      case 'REMOVE_CURRENCY_ITEM':
        let remainingCurrencies = [
          ...state.slice(0, action.id),
          ...state.slice(action.id + 1)
        ]        
        return remainingCurrencies
      case 'UPDATE_CURRENCIES':
        let newState = action.currencies;
        return newState        
      default:
        return state    
    }
  }
  
  const currency = {
      id: 0,
      type: '',
      country: '',
      currencyName: '',
      exchangeRate: '',
      amount: '',
      usdEquivalent: '',
  }

  export default currencies