import React, { Component } from 'react';
import { Grid, Typography} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MDTextField from './MDTextField';
import AsyncReactSelect from './AsyncReactSelect';
import { validate} from '../../service/AddressValidation'

const styles = theme => ({
  textField: {
    "& label": {
      color: "grey"
    },
    "& label span": {
      color: "red"
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  textFieldAddress: {
    width: theme.spacing(107),
  }, 
  subHead: {
    // marginLeft: '20px',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: theme.typography.pxToRem(12),
    textAlign:'center',
    color: '#ffffff',
    backgroundColor: '#6b6b6b',
    fontWeight: '550',
    height: '20px',
    borderRadius: theme.typography.pxToRem(16),
  },
  subHeadValid: {
    // marginLeft: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontSize: theme.typography.pxToRem(14),
    textAlign:'center',
    color: '#ffffff',
    backgroundColor: '#0f9c41',
    fontWeight: '550',
    height: '20px',
    borderRadius: theme.typography.pxToRem(16),
  },

  otherCity: {
    marginLeft:'417px'
  }
})

class Address extends Component {
  constructor(props) {
    super(props); 
    // console.log('cities: ' + ListOfUSCities);
    // alert(JSON.stringify(props.initialAddress))  
    this.state = {address: props.address, summary: props.summary, index: props.index, livinginUS: true, error: false, errorText: '', 
      inUnitedStates: props.inUnitedStates, filter: '', filterCountry: '', filterCity: '', sameAddress: props.sameAddress, uniqId: props.uniqId, 
      addressValid: false, addressValidation: false, addressValidationMessage: '',  reviewPage: props.reviewPage, thirdpartylocator:props.thirdpartylocator,
      requiredFields: {streetName: props.reviewPage? true : false, city: props.reviewPage? true : false, populateZip:'', othercity:props.reviewPage? true:false,
      state: true, zip: true, country: props.reviewPage? true : false}};
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // console.log(JSON.stringify(prevState.address) + ' address changed ' + JSON.stringify(nextProps.address))
  //   // console.log(' same address ' + prevState.sameAddress + " " + nextProps.sameAddress)
  //   if(nextProps.address !== prevState.address) {
  //     // console.log(JSON.stringify(prevState.address) + ' address changed ' + JSON.stringify(nextProps.address))
  //     return { address: nextProps.address, addressValid: false, addressValidation: false, addressValidationMessage: '',
  //               requiredFields: {streetName: false, city: false, state: true, zip: true, country: false} }
  //   } if(nextProps.summary !== prevState.summary) {      
  //     return { summary: nextProps.summary }
  //   } else return null;
  // }
  
  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const { address, summary } = this.state

      if (nextProps.address !== address) {
        // console.log('personin ' + JSON.stringify(person) + ' ' + JSON.stringify(nextProps.person))
        this.setState({ address: nextProps.address });
        // if (nextProps.address.country != address.country) {
        //   this.setState({ addressValid: false, addressValidation: false, addressValidationMessage: '',
        //                 requiredFields: {streetName: false, city: false, state: true, zip: true, country: false} });
        // }
      }
      if (nextProps.summary !== summary) {
        this.setState({ summary: nextProps.summary });
      }
    }
  }

  handleChange = (propertyName, value) => {
    const { address} = this.state;
    let newFilterCountry = address.country !== null ? address.country.value : ""
    let newAddress = {
      ...address,
      [propertyName]: value,         
    };
    
    let USZipcodes = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    let zipcode = newAddress.zip.match(USZipcodes)? newAddress.zip: this.setState({error: true, errorText: 'Incorrect US Zip Code Format' })   
    
    if (newAddress.country !== null && newAddress.country.value !== null && newAddress.country.value !== undefined && 
        newAddress.country.value === 'USA' && newAddress.zip!=='' && newAddress.zip !== zipcode) {    
      this.setState({error: true, errorText: 'Incorrect US Zip Code Format' })
    } else {
      // reset, the errorText
      this.setState({ error: false, errorText: '' }) 
    }

    if (propertyName === 'country' && value !== null) {
      newAddress = {
        ...newAddress,
        state: '',
        city: ''
      }
      this.setState({ filter: value.value })  
    }
    if (propertyName === 'state' && value !== null) {
      newAddress = {
        ...newAddress,        
        city: ''
      }
      this.setState({ filterCity: value.value })  
    }
   
    if(propertyName === 'city' && value !==null && newAddress.city!==undefined && newAddress.city.value !== 'OTHER') {        
             newAddress.othercity='' ;    
    } 
   
    this.setState({ address: newAddress, filterCountry: newFilterCountry })      
    this.props.updateAddress(newAddress, this.props.index)
}


  handleError = (propertyName, error) => {    
    const { index, requiredFields, address } = this.state;  
    var allReqDone = false;

    const newRequiredFields = {
      ...requiredFields,
      [propertyName]: !error,      
    };

    this.setState({ requiredFields: newRequiredFields });

    if (newRequiredFields.streetName && newRequiredFields.city && newRequiredFields.state &&
        // (this.props.inUnitedStates || filterCountry !== ''? true : newRequiredFields.country)) { 
        (this.props.inUnitedStates? true : newRequiredFields.country)) { 
          if(address.city.value!=='OTHER') {               
             allReqDone = true; 
          } else if(address.city.value==='OTHER' && newRequiredFields.othercity) {
            allReqDone = true;
          }  
            const parsedAddress = validate(this.state.address)         
            parsedAddress.then( result => {
              console.log('validresult ' + JSON.stringify(result))
              if (result !== undefined && result !== null && result.addresses !== undefined && result.addresses[0] !== null) {
                if (result.addresses[0].address_parser_match_level === '1') {
                  this.setState({ addressValid: false, addressValidation: true, addressValidationMessage: 'City/Province not valid'})                    
                } else if (result.addresses[0].address_parser_match_level === '2') {
                  this.setState({ addressValid: false, addressValidation: true, addressValidationMessage: 'Street Name not valid'})                    
                } else if (result.addresses[0].address_parser_match_level === '3') {
                  this.setState({ addressValid: false, addressValidation: true, addressValidationMessage: 'House Number Range  not valid'})                    
                } else if (result.addresses[0].address_parser_match_level === '4') {
                  this.setState({ addressValid: false, addressValidation: true, addressValidationMessage: 'Street Component not valid'})                    
                } else if (result.addresses[0].address_parser_match_level === '0' || result.addresses[0].address_parser_match_level === '5'
                              || result.addresses[0].address_parser_match_level === '6') {
                  const newAddress = {
                    ...address,
                    zip: result.addresses[0].zip,      
                  };
                  this.setState({ address: newAddress, addressValid: true, addressValidation: true, addressValidationMessage: 'Valid'}) 
                  // this.state.address.zip= result.addresses[0].zip;
                } else {
                  this.setState({ addressValidation: false, addressValidationMessage: ''})                
                }
              }
            })          
    }
  // console.log("add req " + filterCountry + ' ' + JSON.stringify(newRequiredFields) + ' address'+index + ' ' + allReqDone) 
    this.props.handleError('address'+index, allReqDone);
  }

  render = () => {
    const { address, summary, error, errorText, filter, filterCountry, filterCity, addressValid, addressValidation, addressValidationMessage, index} = this.state
    const { classes, inUnitedStates,locator, thirdpartylocator } = this.props
    return (
      <div>
          {addressValidation && 
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Typography className={addressValid? classes.subHeadValid : classes.subHead}>Address Check: {addressValidationMessage}</Typography>              
          </Grid>
          }
          <Grid container direction="row" alignItems="center">                  
            <MDTextField key='streetName' name='streetName'  label='Address' value={address.streetName} 
                  handleChange={this.handleChange} handleError={this.handleError} required={true}  wide={true} 
                    inputProps={{maxLength: 100, id:locator === 'Review'? 'streetname' + index+locator : 'streetname' + index}} summary={summary} />
          </Grid>
          <Grid container direction="row" alignItems="center">
            <AsyncReactSelect name='country' label='Country' value={address.country} placeholder='Search for Country' filterCountry={filterCountry} errorText='Please select country' 
                optionsType='country' handleChange={this.handleChange} handleError={this.handleError} required={true}  uniqId={locator === 'Review'? 'Country' + index + locator : 'Country' + index}
                disabled={summary? true : false} summary={inUnitedStates? true : summary} />                       
 
             {(address.country === null || (address.country.value !== 'USA' && address.country.value !== 'CAN' && address.country.value !== 'MEX')) &&
             <MDTextField name='state' label='State/Province' value={address.state} handleChange={this.handleChange} handleError={this.handleError}
              inputProps={{maxLength: 2, id:locator === 'Review'? 'state' + index+locator : 'state' + index}}
                required={false} disabled={summary? true : false} summary={summary} /> 
             }
             {address.country !== null && (address.country.value === 'USA' || address.country.value === 'CAN' || address.country.value === 'MEX') &&
             <AsyncReactSelect name='state' label='State/Province' value={address.state} inUnitedStates={inUnitedStates} filter={filter} placeholder='Search for State' errorText='Please select State' 
                 optionsType='state' handleChange={this.handleChange} handleError={this.handleError} required={false} uniqId={locator === 'Review'? 'State' + locator :thirdpartylocator ==='thirdparty'?'State' + thirdpartylocator: 'State'}
                 disabled={summary? true : false}  summary={summary} />
            } 

            {(address.country === null || address.country.value !== 'USA' || address.state.value === null) &&
            <MDTextField name='city' label='City' value={address.city} handleChange={this.handleChange} handleError={this.handleError} required={true} disabled={summary? true : false}
             inputProps={{maxLength: 60, id:locator === 'Review'? 'city' + index+locator: 'city' + index}} summary={summary} /> 
            }
            {address.country !== null && address.country.value === 'USA' && address.state.value !== null &&
            <AsyncReactSelect name='city' label='City' value={address.city} placeholder='Search for City' inUnitedStates={inUnitedStates} filterCity={filterCity} errorText='Please select city' 
                optionsType='city'  handleChange={this.handleChange} handleError={this.handleError} required={true} uniqId = {locator === 'Review'? 'City' + locator:'City'}  disabled={summary? true : false} />
            }        
            
            <MDTextField name='zip' label='Zip or Postal Code' value={address.zip} handleChange={this.handleChange} handleError={this.handleError} 
                error={error} errorText={errorText} required={false} disabled={summary? true : false} inputProps={{maxLength: 20,id:locator==='Review'?'zip'+index+locator:'zip'+index}} summary={summary} />            
          </Grid> 

          {address.city.value==="OTHER" && 
           <Grid container direction="row" alignItems="center" className={classes.otherCity}>
             <MDTextField name='othercity' label='Other City' value={address.othercity}    handleChange={this.handleChange} handleError={this.handleError} required={true} disabled={summary? true : false}
             inputProps={{maxLength: 60, id:locator === 'Review'? 'othercity' + index+locator: 'othercity' + index}} summary={summary} />  
            </Grid>
  }                  
      </div>
    );
  }
}

export default withStyles(styles) (Address);