import React, { Component } from 'react';
import { Paper, Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import NavigationButtons from '../container/NavigationButtons';
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
// import dateFormat from 'dateformat'
import MDTextField from './custom/MDTextField';
import Person from './custom/Person';
import Address from './custom/Address';

function TabContainer({ children, dir }) {
  return (
    <Grid>
    <Typography component="div" dir={dir} style={{ padding: 8 * 2 }}>
      {children}
    </Typography>
    </Grid>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({  
  paper: {
    // margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 1.5,
    // width: theme.spacing.unit * 170,
    // maxWidth: theme.spacing.unit * 160,
    textAlign: 'left',
    justify: 'center',
    color: theme.palette.text.primary,
    // backgroundColor: '#d3ebf9c4'
    backgroundColor: '#ffffff',
    borderRadius: '12px'
  },
  textField: {
    "& label": {
      color: "grey"
    },
    "& label span": {
      color: "red"
    },
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  // subHead: {
  //   paddingTop: '32px',
  //   MarginBottom: '0px'
  // },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tabColorDefault: {
    minHeight: '30px',
    backgroundColor: '#07444a63',    
  },
  tabColorSelected: {
    minHeight: '30px',
    backgroundColor: "#4D95A3",    
    color: "default",
    fontWeight: "760"
  },
  mainHead: {
    marginRight: theme.spacing.unit * 2,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    paddingTop: '32px',
    marginBottom: '0px',
    fontWeight: "700"
  },
  subHead: {
    marginRight: theme.spacing.unit * 2,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    paddingTop: '22px',
    marginBottom: '0px',
    fontWeight: "660"
  },
})

class MailShipCT extends Component {
  constructor(props) {
    super(props);
    this.state = {activeStep: props.activeStep, mailship: props.mailship, ship: props.ship, carrier: props.carrier, businessship: false, businesscarrier: false,
                    updatePerson: props.updatePerson, person: props.person, addresses: props.addresses,
                    summary: false, error: false, errorText: '', business: false,          
                    requiredFields: {shipDate: false, carrier: false, mailship: false, ship: false, address3: false, address4: false, mailshiplocator:''}};
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const { mailship, ship, carrier, addresses, person } = this.state
      // console.log(JSON.stringify(mailship) + ' mailshipchange ' + JSON.stringify(nextProps.mailship))
      if (nextProps.mailship !== mailship) {
        this.setState({ mailship: nextProps.mailship });
      }
      if (nextProps.ship !== ship) {
        this.setState({ ship: nextProps.ship });
      }
      if (nextProps.carrier !== carrier) {
        this.setState({ carrier: nextProps.carrier });
      }
      if (nextProps.addresses !== addresses) {
        this.setState({ addresses: nextProps.addresses });
      }
      // For currency transporter workflow, the person/business information is duplicated
      // to that of the carrier information
      if (nextProps.person !== person) {
        this.setState({ carrier: nextProps.person, businesscarrier: nextProps.person.business !== ''? true : false})
      }
    }
  }

  handleChange = (propertyName, value) => {  
    const { mailship } = this.state;
    const newMailShip = {
      ...mailship,
      sendOrReceive: 2,
      [propertyName]: value,
    };
    
    // if ((propertyName === 'shipDate' || propertyName === 'receiveDate') 
    //           && (Object.prototype.toString.call(value) === "[object Date]") && !isNaN(value.valueOf())
    //           && newMailShip.shipDate !== '' && newMailShip.receiveDate !== '') {
    //   const shipD = newMailShip.shipDate //dateFormat(newMailShip.shipDate, "UTC:mm/dd/yyyy")
    //   const receiveD = newMailShip.receiveDate //dateFormat(newMailShip.receiveDate, "UTC:mm/dd/yyyy")

    //   if (shipD > receiveD) {
    //     this.setState({ error: true, errorText: 'Date Received should be after Date Shipped' })
    //   } else {
    //     this.setState({ error: false, errorText: '' })
    //   }
    // }

    this.setState({ mailship: newMailShip });
  }

  // handleTabChange = (event, value) => {
  //   const { mailship, requiredFields } = this.state;
  //   const newMailShip = {
  //     ...mailship,
  //     sendOrReceive: value
  //   }

  //   let newRequiredFields;
  //   if (value === 1) {      
  //     if (mailship.receiveDate === '') {
  //       newRequiredFields = {
  //         ...requiredFields,
  //         receiveDate: false,
  //         shipDate: true,                
  //       };
  //     } else {
  //       newRequiredFields = {
  //         ...requiredFields,
  //         receiveDate: true,
  //         shipDate: true,                
  //       };
  //     }      
  //     this.setState({ requiredFields: newRequiredFields });  
  //   } else if (value === -1 || value === 0) {
  //     if (mailship.shipDate === '') {
  //       newRequiredFields = {
  //         ...requiredFields,          
  //         shipDate: false,
  //         receiveDate: true,      
  //       };
  //     } else {
  //       newRequiredFields = {
  //         ...requiredFields,          
  //         shipDate: true,
  //         receiveDate: true,      
  //       };
  //     }      
  //     this.setState({ requiredFields: newRequiredFields });  
  //   }

  //   this.setState({ value: value, mailship: newMailShip });
  // };

  updatePerson = (person) => {
    // console.log(JSON.stringify(mailship) + ' mailship ' + JSON.stringify(person))
    this.setState({      
      mailship: person
    });
  } 

  updatePersonShip = (person) => {
    // console.log(JSON.stringify(mailship) + ' mailship ' + JSON.stringify(person))
    this.setState({ 
      ship: person
    });
  }

  updatePersonCarrier = (person) => {
    // console.log(JSON.stringify(mailship) + ' mailship ' + JSON.stringify(person))
    this.setState({ 
      carrier: person
    });
  } 

  updateAddress = (address, index) =>  {
    const { addresses } = this.state;  
    const newAddresses = addresses

    newAddresses[index] = address      

    this.setState({ addresses: newAddresses });      
  }

  handleError = (propertyName, error) => {        
      const { requiredFields } = this.state;

      if (propertyName === 'shipDate') {
        error = !error
      } else  if (propertyName === 'person') {
        propertyName = 'mailship'
      }

      let newRequiredFields = {
        ...requiredFields,
        [propertyName]: error,      
      };

      // if (value === 1) {
      //   newRequiredFields = {
      //     ...newRequiredFields,
      //     shipDate: true,                
      //   };
      // } else if (value === -1 || value === 0) {
      //   newRequiredFields = {
      //     ...newRequiredFields,          
      //     receiveDate: true,      
      //   };
      // }
      // console.log('shprcv err ' + JSON.stringify(newRequiredFields))
      this.setState({ requiredFields: newRequiredFields });    
  }

  handleChangeChecked = (type) => event => {
    // console.log('ship buss ' + type + ' ' + event.target.checked)
    if (type === 'C') {
      this.setState({ businesscarrier: event.target.checked });
    } else
    if (type === 'M') {
      this.setState({ business: event.target.checked });
    } else {
    this.setState({ businessship: event.target.checked });
    }
  }

  render = () => {    
    const { classes } = this.props
    const { mailship, ship, carrier, requiredFields, person, addresses, summary, error, business, businessship, businesscarrier } = this.state
    return (
      <div>
        <Paper className={classes.paper}>
              <Grid>
                  {/* <AppBar position="relative" color="inherit" className={classes.paper} >
                    <Tabs
                      value={value}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      // textColor="secondary"
                      variant="fullWidth"
                    >
                      <Tab className={value === -1 || value === 0? classes.tabColorSelected : classes.tabColorDefault} label="Shipping" />
                      <Tab className={value === 1? classes.tabColorSelected : classes.tabColorDefault} label="Receiving" />
                    </Tabs>                  
                  
                  <TabContainer> */}
                    <Grid container direction="row" alignItems="center" justify="flex-start">
                      <MDTextField name='shipDate'  mailshiplocator='mailship' label='Date Shipped' value={mailship.shipDate} type='date' inputLabelProps={{ shrink: true }} handleChange={this.handleChange} 
                          handleError={this.handleError} required={true} summary={summary} />                  
                      <MDTextField name='methodOfShipment' label='Method of Shipment' mailshiplocator='mailship' value={mailship.methodOfShipment} handleChange={this.handleChange} 
                          handleError={this.handleError} required={false} inputProps={{ maxLength: 15, }} summary={summary} />
                    </Grid>  
                      {/* <MDTextField name='carrierName' label='Carrier Name' mailshiplocator='mailship' value={mailship.carrierName} handleChange={this.handleChange} 
                          handleError={this.handleError} required={false} inputProps={{ maxLength: 95, }} summary={summary} /> */}
                       {/* <MDTextField name='receiveDate'   mailshiplocator='mailship'label='Date Received' value={mailship.receiveDate} type='date' inputLabelProps={{ shrink: true }} handleChange={this.handleChange} 
                           handleError={this.handleError} required={true} summary={summary} error={error !== false? error : undefined} errorText={errorText !== ''? errorText : undefined} />
                     */}
                    <Typography className={classes.mainHead}>Carrier Information</Typography>
                    <FormControlLabel
                      className={classes.formMargin}
                      control={
                      <Checkbox
                        checked={businesscarrier ? true: false}
                        onChange={this.handleChangeChecked('C')}
                        value={businesscarrier}
                      /> }
                      label="Business"
                    />
                     <Person person={carrier} mailshiplocator='carrier' updatePerson={this.updatePersonCarrier} handleError={this.handleError} 
                              shiprecv={true} business={businesscarrier} inputLabelProps={{ shrink: true }} />
</Grid></Paper>

<Paper className={classes.paper}>
<Grid>
                    <Typography className={classes.mainHead}>Receiver Information</Typography>
                    <FormControlLabel
                      className={classes.formMargin}
                      control={
                      <Checkbox
                        checked={business ? true: false}
                        onChange={this.handleChangeChecked('M')}
                        value={business}
                      /> }
                      label="Business"
                    />
                     <Person person={mailship} mailshiplocator='mailship' updatePerson={this.updatePerson} handleError={this.handleError} 
                              shiprecv={true} business={business} inputLabelProps={{ shrink: true }} />
                     <Grid container direction="row" alignItems="center">
                      
                     </Grid>
                     <Grid>
                      <Typography className={classes.subHead}>Receiver's Address</Typography>
                    </Grid>
                      <Address address={addresses[4]} index='4' updateAddress={this.updateAddress}  handleError={this.handleError} /> 
</Grid></Paper>

<Paper className={classes.paper}>
<Grid>
                    <Typography className={classes.mainHead}>Shipper Information</Typography>
                    <FormControlLabel
                      className={classes.formMargin}
                      control={
                      <Checkbox
                        checked={businessship ? true: false}
                        onChange={this.handleChangeChecked('S')}
                        value={businessship}
                      /> }
                      label="Business"
                    />
                     <Person person={ship} mailshiplocator='ship' updatePerson={this.updatePersonShip} handleError={this.handleError} shiprecv={true} business={businessship} inputLabelProps={{ shrink: true }} />
                     <Grid container direction="row" alignItems="center">
                      
                     </Grid>
                     <Grid>
                      <Typography className={classes.subHead}>Shipper's Address</Typography>
                    </Grid>
                      <Address address={addresses[3]} index='3' updateAddress={this.updateAddress}  handleError={this.handleError} /> 

                  {/* </TabContainer>                
              </AppBar>                   */}
            </Grid>        

             <NavigationButtons mailship={mailship} mailshipPerson={person} addresses={addresses} carrier={carrier} ship={ship}
                   allReqDone={requiredFields.address3 && requiredFields.address4 && !error && 
                                requiredFields.shipDate && requiredFields.carrier && requiredFields.mailship && requiredFields.ship
            } />
           </Paper>   
       </div>
    );
  }
}

export default withStyles(styles) (MailShipCT);