const others = (state = [initialOthers], action) => {
    switch (action.type) {
      case 'RESET_OTHER_ITEM':
        return [initialOthers]
      case 'ADD_OTHER_ITEM':
        let newOtherItem = {...initialOthers, id:state.length}       
        return [...state, newOtherItem]
      case 'REMOVE_OTHER_ITEM':
        let remainingcoins = [
          ...state.slice(0, action.id),
          ...state.slice(action.id + 1)
        ]        
        return remainingcoins
      case 'UPDATE_OTHERS':
        let newState = action.others;
        return newState        
      default:
        return state    
    }
  }
  
  const initialOthers = {
      id: 0,
      otherType: '',
      country: '',
      currencyName: '',
      exchangeRate: '',
      amount: '',
      usdEquivalent: '',
  }

  export default others