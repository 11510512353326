import { connect } from 'react-redux'

import { addCurrencyItem, removeCurrencyItem, addCoinItem, removeCoinItem, addOtherItem, removeOtherItem } from '../actions'
import MonetaryInstrument from '../component/MonetaryInstrument'

const mapStateToProps = state => ({
  activeStep: state.activeStep,
  currencies: state.currencies,
  coins: state.coins,
  others: state.others
})

const mapDispatchToProps = (dispatch) => ({
  addCurrencyItem: () => dispatch(addCurrencyItem()),
  removeCurrencyItem: (id) => dispatch(removeCurrencyItem(id)),
  addCoinItem: () => dispatch(addCoinItem()),
  removeCoinItem: (id) => dispatch(removeCoinItem(id)),
  addOtherItem: () => dispatch(addOtherItem()),
  removeOtherItem: (id) => dispatch(removeOtherItem(id)),
})

export default connect(mapStateToProps, mapDispatchToProps) (MonetaryInstrument);
