import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Stepper, Step, StepLabel, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddPersonalInformation from '../container/AddPersonalInformation';
import AddThirdPartyInformation from '../container/AddThirdPartyInformation';
import AddExportImport from '../container/AddExportImport';
import AddStart from '../container/AddStart';
import AddMonetaryInstrument from '../container/AddMonetaryInstrument'
import AddReviewSign from '../container/AddReviewSign';
import NumberFormat from 'react-number-format';
import RequiredText from './custom/RequiredText';
import Flag from 'react-world-flags'
import Needhelp from '../images/needhelp.jpg'
import AddMailShip from '../container/AddMailShip';
import AddMailShipCT from '../container/AddMailShipCT';

const styles = theme => ({
  root: {
    marginBottom: '44px',
    marginLeft: '2px',
    marginRight: '2px',    
  },
  stepper: {
    backgroundColor: '#ffffff',
    // "& div div span": {
    //   color: "red"
    // },
  },
  mainTitle: {
    fontWeight: 'bold',
    margin: '8px 0 12px 0',
    [theme.breakpoints.down('xs')]: {      
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.up('sm')]: {      
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.up('md')]: {      
      fontSize: theme.typography.pxToRem(24),
    },
    [theme.breakpoints.up('lg')]: {      
      fontSize: theme.typography.pxToRem(26),
    },
  },
  subTitle: {
    // fontSize: theme.typography.pxToRem(17),
    fontWeight: 'bold',
    margin: '0px 0 32px 0',
    [theme.breakpoints.down('xs')]: {      
      fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.up('sm')]: {      
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {      
      fontSize: theme.typography.pxToRem(22),
    },
    [theme.breakpoints.up('lg')]: {      
      fontSize: theme.typography.pxToRem(24),
    },
  },
  stepLabel: {    
    // color: '#2c387e',
    // fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    // fontSize: '13px',
    [theme.breakpoints.down('xs')]: {      
      fontSize: '0',
    },
    [theme.breakpoints.up('sm')]: {      
      fontSize: '11px'
    },
    [theme.breakpoints.up('md')]: {      
      fontSize: '13px'
    },
    [theme.breakpoints.up('lg')]: {      
      fontSize: '14px'
    },
  },
  heading: {    
    color: '#2c387e',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),    
  },
  headingsmall: {    
    color: '#2c387e',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),    
  },
  headingline2: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
  },
  prompt: {
    fontWeight: 'bold',
    margin: '12px 0 2px 0',
    [theme.breakpoints.down('xs')]: {      
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up('sm')]: {      
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.up('md')]: {      
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('lg')]: {      
      fontSize: theme.typography.pxToRem(18),
    },    
  },
  secondaryHeading: {
    marginLeft: '20px',
    paddingLeft: '25px',
    paddingRight: '25px',
    fontSize: theme.typography.pxToRem(15),
    textAlign:'center',
    color: '#ffffff',
    backgroundColor: '#4f57ab',
    fontWeight: 'bold',
    height: '20px',
    borderRadius: theme.typography.pxToRem(18),
  },
  topheader: {
    padding: '0px 33px 3px 40px',
  },
  currencytext: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 15,
    },       
  },
  currencytext1: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 0,
    },       
  },
  needHelpImage: {    
    marginTop: '6px',    
  },
  needhelpText: {
    marginLeft: '0px',
    fontWeight: 'bold',
    color: '#3440E4',
    textDecoration: 'none',
    textTransform: 'none'
  },  
});

// function getSteps(start) {
//   if(start.myselfOrSomeone === 'S') {
//     return ['Get Started', 'Personal Information', 'Third Party', 'Origin and Destination', 'Currencies Transported', 'Review and Sign'];
//   } else {
//     return ['Get Started', 'Personal Information', 'Origin and Destination', 'Currencies Transported', 'Review and Sign'];
//   }
// }

class HorizontalStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeStep: this.props.activeStep, countThirdParty: 0, completedStep: 0 };    
  }

  componentDidMount() {
    this.resetAll();
    this.props.formCompleted(true)    
  }

  componentWillReceiveProps(nextProps) {    
    if (this.props !== nextProps) {
      const { completedStep} = this.state
      // console.log('in horsteppe ' + JSON.stringify(nextProps.activeStep) + ' ' + completedStep) 
      let newCompletedStep

      if (nextProps.activeStep === 0 && nextProps.start.myselfOrSomeone === '') {
        newCompletedStep = 0;
      } else if (completedStep < nextProps.activeStep) {
        newCompletedStep = nextProps.activeStep
      } else {
        newCompletedStep = completedStep
      }

      this.setState({
        completedStep: newCompletedStep,
        activeStep: nextProps.activeStep
      });
    }
  }

  resetAll() {
    this.props.resetStep();
    this.props.resetStart();
    this.props.resetPerson();
    this.props.resetAddress();
    this.props.resetExportImport();
    this.props.resetMailShip();
    this.props.resetThirdParty();
    this.props.resetCurrencyItem();
    this.props.resetCoinItem();
    this.props.resetOtherItem();
    this.props.resetReviewSign();
    this.props.resetReceipt();
  }

  getSteps(start) {
    const PerBusLabel = start.personalOrBusiness === 'B'? 'Business Information' : 'Personal Information';
    // const ImpExpShpRcvLabel = start.mailingOrPhysical === 'M'? 'Ship and Receive' : null

    if (start.pct === 'Y') {
      return ['Get Started', PerBusLabel, 'Import and Export', 'Ship and Receive', 'Third Party', 'Monetary Instruments', 'Review and Sign'];
    } else
    if (start.mailingOrPhysical === 'M' && start.myselfOrSomeone === 'S') {
      return ['Get Started', PerBusLabel, 'Ship and Receive', 'Third Party', 'Monetary Instruments', 'Review and Sign'];
    } else 
    if (start.myselfOrSomeone === 'S') {      
      return ['Get Started', PerBusLabel, 'Import and Export', 'Third Party', 'Monetary Instruments', 'Review and Sign'];
    } else 
    if (start.mailingOrPhysical === 'M') {      
      return ['Get Started', PerBusLabel, 'Ship and Receive', 'Monetary Instruments', 'Review and Sign'];
    } else {      
      return ['Get Started', PerBusLabel, 'Import and Export', 'Monetary Instruments', 'Review and Sign'];
    } 
  }

  handleExpansionChange = panel => (event) => {
    this.setState({
      activeStep: panel
    });
    this.props.updateStep(panel);
  };

  computeTotal = (currencies, coins, others) => {
    let total = 0;
    currencies.map(currency => {
      return total += Number(Math.round(currency.usdEquivalent))
    })
    coins.map(currency => {
      return total += Number(Math.round(currency.usdEquivalent))
    })
    others.map(currency => {
      return total += Number(Math.round(currency.usdEquivalent))
    })
    return total;
  };

  showHelp(type) {
    this.props.history.push('/help')
  }

  render() {
    const { classes } = this.props;
    const { start, exportImport, currencies, coins, others } = this.props;
    const { activeStep, completedStep } = this.state;
    const steps = this.getSteps(start);
    
    return (      
      <Grid container direction="row" justify="center" alignItems="flex-start">

          <Grid container className={classes.topheader} alignItems={"center"} direction={"row"} justify={"space-between"}>
          <Grid item>
            <Grid container alignItems={"flex-start"} direction={"row"} justify={"flex-start"}>
              <Grid className={classes.currencytext} >FinCEN Form 105</Grid>              
              <Grid className={classes.currencytext1} ><span>&nbsp;</span>| Currency and Monetary Instrument Report (CMIR)</Grid>
            </Grid>  
          </Grid>
          <Grid item>
            <Link to="/help" target="help" style={{ textDecoration: 'none' }}>
              <Grid container alignItems={"center"} direction={"row"} justify={"space-between"}>
                <img src={Needhelp} alt="needhelp" style={{cursor: 'pointer'}}/>
                <Button autoCapitalize="false" className={classes.needhelpText}  align="center">Need Help?</Button>
              </Grid>
            </Link>            
          </Grid>
        </Grid>

      <Grid className={classes.root} >
        
        <Typography className={classes.mainTitle} align='center' >Report of International Transportation of Currency or Monetary Instruments</Typography>        
        <Typography className={classes.prompt} align='center' >Please fill out the form below</Typography>
        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>
                <Typography className={classes.stepLabel} align='center'>{label}</Typography>
              </StepLabel>               
            </Step>
          ))}
        </Stepper>

         <ExpansionPanel expanded={activeStep === 0} onChange={this.handleExpansionChange(0)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={activeStep === 0? classes.heading : classes.headingsmall}>Get Started</Typography>
            
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
              <AddStart />
          </ExpansionPanelDetails>
        </ExpansionPanel>
 
          <ExpansionPanel expanded={activeStep === 1} disabled={completedStep < 1} onChange={this.handleExpansionChange(1)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid>
              <Typography className={activeStep === 1? classes.heading : classes.headingsmall}>{start.personalOrBusiness === 'B'? 'Business Information' : 'Personal Information'}</Typography>
              {activeStep === 1 && <Typography className={classes.headingline2}>Please enter the Information about the 
              {start.personalOrBusiness === 'B'? ' business ' : ' person '} departing or entering the United States with the currency.</Typography> }
              {activeStep === 1 && <RequiredText/>}
            </Grid>
           
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>           
              <AddPersonalInformation />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        { (start.pct === 'Y' || start.mailingOrPhysical === 'P' || start.mailingOrPhysical === '') &&
         <ExpansionPanel expanded={activeStep === 2} disabled={completedStep < 2} onChange={this.handleExpansionChange(2)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>            
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid>
                <Typography className={activeStep === 2? classes.heading : classes.headingsmall}>Import/Export Information</Typography>
                  {activeStep === 2 && <Typography className={classes.headingline2}>Enter details on how the currency or monetary instrument is being transported.</Typography> }
                  {activeStep === 2 && <RequiredText/>}                
              </Grid>
              {start.mailingOrPhysical === 'P' && exportImport.exportOrImport !== -1 &&
                <Typography className={classes.secondaryHeading}>
                    {exportImport.exportOrImport === 0? 
                    ['Imported from ', <Flag code={exportImport.departureCountry.value} height="10" />,  exportImport.departureCountry.countryDescription] :  
                    ['Exported to ', <Flag code={exportImport.arriveCountry.value} height="10" />,  exportImport.arriveCountry.countryDescription]}
                </Typography>
              } 
            </Grid>     
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <AddExportImport />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        }

       { start.mailingOrPhysical === 'M' &&
        <ExpansionPanel expanded={activeStep === (start.pct === 'N'? 2 : 3)} disabled={completedStep < (start.pct === 'N'? 2 : 3)} 
                        onChange={this.handleExpansionChange(start.pct === 'N'? 2 : 3)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>            
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid>
                <Typography className={activeStep === 3? classes.heading : classes.headingsmall}>Shipping/Receiving Information</Typography>
                  {activeStep === (start.pct === 'N'? 2 : 3) && <Typography className={classes.headingline2}>Enter details on how the currency or monetary instrument is being transported.</Typography> }
                  {activeStep === (start.pct === 'N'? 2 : 3) && <RequiredText/>}                
              </Grid>
            </Grid>     
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {/* {console.log("pct in stepper " + JSON.stringify(start.pct))} */}
            {start.pct === 'Y' && <AddMailShipCT /> } 
            {start.pct === 'N' && <AddMailShip /> }              
          </ExpansionPanelDetails>
        </ExpansionPanel>
       }

        { start.myselfOrSomeone === 'S' &&
         <ExpansionPanel expanded={activeStep === (start.pct === 'N'? 3 : 4)} disabled={completedStep < (start.pct === 'N'? 3 : 4)} 
                          onChange={this.handleExpansionChange((start.pct === 'N'? 3 : 4))}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid>
              <Typography className={activeStep === 3? classes.heading : classes.headingsmall}>Third Party Information</Typography>
              {activeStep === 4 && <Typography className={classes.headingline2}>Please enter the Information about the Third Party, on behalf of who the Monetary Instrument is transported.</Typography> }
              {activeStep === 4 && <RequiredText/>}
            </Grid>
           
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>           
              <AddThirdPartyInformation />
          </ExpansionPanelDetails>
        </ExpansionPanel>    
        }
  {/* pct ==== 'N' && myselfOrSomeone === 'M' 3
  pct ==== 'N' && myselfOrSomeone === 'S' 4
  pct ==== 'Y' 5 */}

       <ExpansionPanel expanded={activeStep === (start.pct === 'Y'? 5 : start.myselfOrSomeone === 'S'? 4 : 3)}
            disabled={completedStep < (start.pct === 'Y'? 5 : start.myselfOrSomeone === 'S'? 4 : 3)} 
            onChange={this.handleExpansionChange(start.pct === 'Y'? 5 : start.myselfOrSomeone === 'S'? 4 : 3)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid>
                <Typography className={activeStep === 3? classes.heading : classes.headingsmall}>Monetary Instrument Information</Typography>
                {activeStep === (start.pct === 'Y'? 5 : start.myselfOrSomeone === 'S'? 4 : 3) && <Typography className={classes.headingline2}>Type and Amount of Currency or Monetary Instrument being transported.</Typography> }
                {activeStep === (start.pct === 'Y'? 5 : start.myselfOrSomeone === 'S'? 4 : 3) && <RequiredText/>}
              </Grid>
              {completedStep > (start.pct === 'Y'? 5 : start.myselfOrSomeone === 'S'? 4 : 3) &&              
              <Typography className={classes.secondaryHeading}> 
                Total: <NumberFormat value={(this.computeTotal(currencies, coins, others))} isNumericString={true} displayType={'text'} 
                          decimalScale={0} maximumfractiondigits={0} minimumfractiondigits= {0} thousandSeparator={true} prefix={'$'} /> USD
              </Typography> 
              }
            </Grid>      
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
              {/* <AddCurrencies /> */}
              <AddMonetaryInstrument />
          </ExpansionPanelDetails>
        </ExpansionPanel>

       <ExpansionPanel expanded={activeStep === (start.pct === 'Y'? 6 : start.myselfOrSomeone === 'S'? 5 : 4)} 
              disabled={completedStep < (start.pct === 'Y'? 6 : start.myselfOrSomeone === 'S'? 5 : 4)} 
              onChange={this.handleExpansionChange(start.pct === 'Y'? 6 : start.myselfOrSomeone === 'S'? 5 : 4)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>            
            <Grid>
              <Typography className={activeStep === (start.pct === 'Y'? 6 : start.myselfOrSomeone === 'S'? 5 : 4)? classes.heading : classes.headingsmall}>Review and Signature</Typography>
              {activeStep === (start.pct === 'Y'? 6 : start.myselfOrSomeone === 'S'? 5 : 4) && <Typography variant='caption'>Review the Summary and complete Signature below.</Typography> }              
            </Grid>     
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
              <AddReviewSign formCompleted={this.props.formCompleted} />
          </ExpansionPanelDetails>
        </ExpansionPanel>     
      </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(HorizontalStepper);
