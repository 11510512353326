import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import sealdhsfooterUrl from '../images/seal-dhs-footer.png';

const styles = theme => ({
  root: {
    flexgrow: 1,
  },
  ecmirFooter: {    
    backgroundImage: "url(" + sealdhsfooterUrl + ")",
    backgroundColor: '#1c3764',
    backgroundRepeat: "no-repeat",    
    backgroundPositionX: 'center',
    backgroundPositionY: '15px',    
    padding: '10px 0 0 0',
    [theme.breakpoints.down('xs')]: {            
      height: '87px',
      // paddingLeft: '16px',
    },
  },
  container: {
    width: '100%',
    // backgroundColor: '#1c3764',
    display: 'flex',
    height: '86px',    
  },
  linkscontainer: {
    width: '100%',
    backgroundColor: '#1c3764',
    display: 'flex',
    height: '110px',
    // paddingTop: '10px',
    paddingBottom: '10px',
    [theme.breakpoints.down('xs')]: {            
      display: 'inline-table',
      height: '186px',      
    },
  },
  cbptext: {
    color: 'white',
    fontFamily: 'Open Sans, Helvetica Neue, Tahoma, Arial, sans-serif',
    fontSize: '15.3px',    
    marginBottom: '1px',
    // paddingRight: '120px',    
  },
  a: {
    color: '#fff',
	  fontWeight: '700',
	  textDecoration: 'none',
    backgroundColor: 'transparent',
    alignSelf: 'center'
  },

  b: {
    color: '#fff',
	  fontWeight: '700',
	  textDecoration: 'none',
    backgroundColor: 'transparent',
    alignSelf: 'center'
  },
  omb: {
    background: '#162e54',
    width: '100%',
    color: '#fff',
    padding: '8px 0 8px 10px'
  },
  paperworkreduction: {
      fontSize: '0.85em',
      background: '#142949',
      paddingTop: '4px',
      color: '#fff',
      padding: '0.5px 50px 0.5px 50px'
  }
});

function Footer(props) {
  const { classes } = props;
  return (    
    <Grid className={classes.ecmirFooter}>       
      <Grid className={classes.container}></Grid>
      <Grid container className={classes.linkscontainer} alignItems={"flex-start"} direction={"row"} justifyContent={"space-around"}>
      <Grid item className={classes.cbptext}>
        
        <Grid container className={classes.container} alignItems={"flex-start"} direction={"column"} justifyContent={"space-around"}>        
          <a className={classes.a} href="https://www.dhs.gov" title="Department of Homeland Security" target="_blank" rel="noopener noreferrer">
            DHS.gov         
          </a>    
          <a className={classes.a} href="https://www.cbp.gov/site-policy-notices/accessibility" title="Accessibility" target="_blank" rel="noopener noreferrer">
            Accessibility          
          </a>                   
          </Grid>
        </Grid>
        <Grid item className={classes.cbptext}>
          <Grid container className={classes.container} alignItems={"flex-start"} direction={"column"} justifyContent={"space-around"}>
          <a className={classes.a} href="https://www.cbp.gov" title="Customs and Border Protection" target="_blank" rel="noopener noreferrer">
              CBP.gov         
            </a>
            <a className={classes.b} href="https://www.cbp.gov/newsroom/publications/performance-accountability-financial" title="Accountabillity" target="_blank" rel="noopener noreferrer">
              Accountabillity
            </a>
          </Grid>                
        </Grid>

        <Grid item className={classes.cbptext}>
          <Grid container className={classes.container} alignItems={"flex-start"} direction={"column"} justifyContent={"space-around"}>         
          <a className={classes.a} href="https://www.cbp.gov/about" title="About CBP" target="_blank" rel="noopener noreferrer">
              About CBP         
            </a>
            <a className={classes.a} href="https://www.cbp.gov/site-policy-notices/privacy-policy" title="Privacy Policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy         
            </a>                
          </Grid>                
        </Grid>

        <Grid item className={classes.cbptext}>
          <Grid container className={classes.container} alignItems={"flex-start"} direction={"column"} justifyContent={"space-around"}>
          <a className={classes.a} href="https://www.cbp.gov/contact" title="Contact CBP" target="_blank" rel="noopener noreferrer">
            Contact CBP         
          </a>
          <a className={classes.a} href="https://www.cbp.gov/site-policy-notices/foia" title="FOIA" target="_blank" rel="noopener noreferrer">
              FOIA        
            </a>
          </Grid>
        </Grid>

        <Grid item className={classes.cbptext}>
          <Grid container className={classes.container} alignItems={"flex-start"} direction={"column"} justifyContent={"space-around"}>
            <a className={classes.a} href="https://www.oig.dhs.gov/" title="Inspector General" target="_blank" rel="noopener noreferrer">
              Inspector General         
            </a>
            <a className={classes.a} href="https://www.cbp.gov/about/eeo-diversity/no-fear-act" title="No Fear Act" target="_blank" rel="noopener noreferrer">
             No Fear Act        
            </a>    
          </Grid>
        </Grid>

        <Grid item className={classes.cbptext}>
          <Grid container className={classes.container} alignItems={"flex-start"} direction={"column"} justifyContent={"space-around"}>
            <a className={classes.a} href="/" title="Home" target="_blank" rel="noopener noreferrer">
              Home         
            </a>
            <a className={classes.a} href="https://www.cbp.gov/document/directives/vulnerability-disclosure-program-policy-and-rules-engagement" title="Vulnerability Disclosure" target="_blank" rel="noopener noreferrer">
          Vulnerability Disclosure          
          </a>    
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.container} alignItems={"center"} direction={"row"} justifyContent={"space-evenly"}>  
        <Grid className={classes.paperworkreduction}>             
          <p>
          Paperwork Reduction Act: An agency may not conduct or sponsor an information collection and a person is not required to 
          respond to this information unless it displays a current valid OMB control number. The control number for this 
          collection is 1506-0014. The estimated average time to complete this application is 11 minutes. If you have any 
          comments regarding the burden estimate you can write to U.S. Customs and Border Protection, Office of Regulations 
          and Rulings, 90 K Street, NE, Washington DC 20229.
          </p>
        </Grid>
      </Grid>
  </Grid>

  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);



