import React, { Component } from 'react';
import { Typography, Grid, Paper, FormControlLabel, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import NavigationButtons from '../container/NavigationButtons';
import Person from './custom/Person';
import Address from './custom/Address';
import Flag from 'react-world-flags';

const styles = theme => ({
// const styles = theme => console.log(theme) || ({  
  paper: {
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    // width: theme.spacing.unit * 170,
    // maxWidth: theme.spacing.unit * 160,
    textAlign: 'left',
    justify: 'center',
    color: theme.palette.text.primary,
    // backgroundColor: '#d3ebf9c4',
    backgroundColor: '#ffffff',
    borderRadius: '12px'
  },
  textField: {
    "& label": {
      color: "#525252"
    },
    "& label span": {
      color: "#9C2F36"
    },
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  textFieldAddress: {
    width: theme.spacing.unit * 107,
  },
  selectInput: {
    marginTop: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 2,
    width: theme.spacing.unit * 24,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 435,
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 200,
      borderColor: '#fb0606'
    }), 
  },
  reactSelect: {
    width: theme.spacing.unit * 24,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 435,
    borderColor: '#fb0606',
    control: {
      borderColor: '#fb0606'
    }
  },

  // subHead: {
  //   paddingTop: '32px',
  //   MarginBottom: '0px'
  // }
  subHead: {
    marginRight: theme.spacing.unit * 2,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    // backgroundColor: 'lightBlue',
    paddingTop: '32px',
    marginBottom: '0px',
    fontWeight: "660"
  },
  formMargin: {
    marginLeft: '0px'
  }
})

class PersonalInformation extends Component {
  constructor(props) {
    super(props);    
    this.state = {activeStep: props.activeStep, countries: [], inUnitedStates: false, start: props.start,
                    person: props.person, addresses: props.addresses, sameAddress: false, 
                    requiredFields: {person: false, address0: false, address1: false}};
  }

  // static getDerivedStateFromProps(nextProps, prevState) {   
  //   // console.log('persinfo ' + JSON.stringify(nextProps.activeStep + ' ' + JSON.stringify(prevState.activeStep))) 
  //   if (nextProps.activeStep === 0) {
  //     // console.log('pers ' + JSON.stringify(nextProps.person + ' ' + JSON.stringify(prevState.person))) 
  //     // console.log('addrs ' + JSON.stringify(nextProps.addresses[0].streetName + ' ' + JSON.stringify(prevState.addresses[0])))
  //     if(nextProps.start !== prevState.start) { 
  //       // return { activeStep: nextProps.activeStep, start: nextProps.start }
  //       return { activeStep: nextProps.activeStep, start: nextProps.start, person: nextProps.person, addresses: nextProps.addresses,
  //         requiredFields: {person: false, address0: false, address1: false} }
  //     } else if(nextProps.person !== prevState.person && nextProps.addresses !== prevState.addresses) {
  //       // console.log('pers addres inside ' + JSON.stringify(nextProps.addresses + ' ' + JSON.stringify(prevState.addresses)))          
  //       return { activeStep: nextProps.activeStep, person: nextProps.person, addresses: nextProps.addresses,
  //                 requiredFields: {person: false, address0: false, address1: false} }
  //     } else if(nextProps.person !== prevState.person) { 
  //       // console.log('pers inside ' + JSON.stringify(nextProps.person + ' ' + JSON.stringify(prevState.person)))          
  //       return { activeStep: nextProps.activeStep, person: nextProps.person }
  //     } else if(nextProps.addresses !== prevState.addresses) {  
  //       // console.log('addrs inside ' + JSON.stringify(nextProps.addresses + ' ' + JSON.stringify(prevState.addresses)))     
  //       return { activeStep: nextProps.activeStep, addresses: nextProps.addresses }
  //     }  return null;
  //   }    
  // }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const { activeStep, start, person, addresses } = this.state
      
      if (nextProps.activeStep !== activeStep) {
        // console.log('activestepin ' + JSON.stringify(activeStep) + ' ' + JSON.stringify(nextProps.activeStep))
        this.setState({ activeStep: nextProps.activeStep });
      }
      if (nextProps.start !== start) {
        // console.log('startpn ' + JSON.stringify(start) + ' ' + JSON.stringify(nextProps.start))
        this.setState({ start: nextProps.start, requiredFields: {person: false, address0: false, address1: false} });
      }
      if (nextProps.person !== person) {
        this.setState({ person: nextProps.person });
      }
      if (nextProps.addresses !== addresses) {
        this.setState({ addresses: nextProps.addresses });
      }
    }
  }

  updatePerson = (person) => {
    this.setState({ person: person });
  }

  handleChangeChecked = propertyName => event => {
    this.setState({ sameAddress: event.target.checked });
    if (event.target.checked) {
      const { addresses, sameAddress  } = this.state;
      const addressP = addresses[0]
      let newAddress
      var allReqDone = false;

      newAddress = {
        ...addressP,
        type: '1',
        country:{
          value: 'USA',
          label: [<Flag code='USA' height="12" />, ' ',  'UNITED STATES'],      
          countryDescription: 'UNITED STATES'
        } 
      };
      if (addressP.country !== undefined && addressP.country !== null && addressP.country.value !== null && 
            addressP.country.value !== undefined && addressP.country.value === 'USA') {
        addresses[1] = newAddress
      }
         
      this.setState({ addresses: addresses, sameAddress: sameAddress? false : true });

      if (addresses[1].streetName !== '' && addresses[1].city !== '' && addresses[1].country !== '') {
        allReqDone = true;
      }
      this.handleError('address1', allReqDone);
    } else {
      const { addresses, sameAddress  } = this.state;
      const addressU = addresses[1]

      const newAddressU = {
        ...addressU,
        streetName: '',
        state: '',
        city: '',
        othercity: '',
        zip: ''
      }
      
      addresses[1] = newAddressU
      // addresses.splice(1, 1);
      this.setState({ addresses: addresses, sameAddress: sameAddress? false : true });
    }
  }

  updateAddress = (address, index) =>  {
    const { addresses, sameAddress } = this.state;  
    const newAddresses = addresses

    newAddresses[index] = address      
        
    if (index === '0' && sameAddress && 
        (newAddresses[0].country === undefined || (newAddresses[0].country !== null && newAddresses[0].country.value === "USA"))) {      
      newAddresses[1] = {...address, type: '1'}
    } 
    this.setState({addresses: newAddresses, sameAddress: (index === '1'? false : sameAddress)});      
  }

  handleError = (propertyName, allReqDone) => {
    const { requiredFields, sameAddress, start } = this.state;
    let addr0 = requiredFields.address0

    let newRequiredFields = {
      ...requiredFields,
      [propertyName]: allReqDone,      
    };

    if ((sameAddress && propertyName === 'address0' && allReqDone === true) || (sameAddress && addr0) ||
          start.personalOrBusiness === 'B') {
      newRequiredFields = {...newRequiredFields, address1 : true}
    }

    // console.log("pers inf req " + JSON.stringify(newRequiredFields) + ' '+ propertyName + ' ' + allReqDone)    
    this.setState({ requiredFields: newRequiredFields });
  }

  render = () => {    
    const { classes } = this.props
    const { start, person, addresses, sameAddress, requiredFields } = this.state
    return (
      <div>       
            <Paper className={classes.paper}>
              <Person person={person} updatePerson={this.updatePerson} handleError={this.handleError} 
                        business={start.personalOrBusiness === 'B'? true : false} />
                             
                <Grid>
                  <Typography className={classes.subHead}>{start.personalOrBusiness === 'B'? 'Business ' : undefined}
                    Permanent Address in United States or Abroad</Typography>
                </Grid>

                <Address address={addresses[0]} index='0' updateAddress={this.updateAddress}  handleError={this.handleError} />                

                {start.personalOrBusiness !== 'B' &&
                <Grid>
                  <Typography className={classes.subHead}>Address while in the United States</Typography>
                  {(addresses[0].country === undefined || addresses[0].country === null || 
                              (addresses[0].country !== null  && addresses[0].country.value === "USA")) &&   
                  <FormControlLabel
                      className={classes.formMargin}
                      control={
                      <Checkbox
                        checked={addresses[0].country !== null && sameAddress ? true: false}
                        onChange={this.handleChangeChecked('sameAddress')}
                        value={sameAddress}
                      />
                    }
                    label="Same Address as Permanent Address"
                  /> }
                </Grid>
                }
                
                {start.personalOrBusiness !== 'B' &&
                <Address address={addresses[1]} index='1' updateAddress={this.updateAddress} 
                                          sameAddress={sameAddress} inUnitedStates={true} handleError={this.handleError} />
                }

                <NavigationButtons person={person} addresses={addresses} 
                          allReqDone={requiredFields.person && requiredFields.address0 && requiredFields.address1} />
          </Paper>
       </div>
    );
  }
}

export default withStyles(styles) (PersonalInformation);