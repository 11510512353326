import 'date-fns';
import React, { Component } from 'react';
import { TextField, Grid, InputAdornment } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  textField: {
    "& label": {
      color: "#525252"
    },
    "& label span": {
      color: "#9C2F36"
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(24),
    [theme.breakpoints.down('xs')]: {      
      width: theme.spacing(30),
    },    
  },
  textFieldCurrency: {
    "& label": {
      color: "#525252"
    },
    "& label span": {
      color: "#9C2F36"
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(16),    
  },
  textFieldAddress: {
    "& label": {
      color: "#525252"
    },
    "& label span": {
      color: "#9C2F36"
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(75.6),
    [theme.breakpoints.down('xs')]: {      
      width: theme.spacing(30),
    },
  },
  textFieldSummary: {
    "& input": {
      color: '#000000'
    },
    "& textarea": {
      color: '#000000'
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(24),
  },
  textFieldCurrencySummary: {
    "& input": {
      color: '#000000'
    },
    "& textarea": {
      color: '#000000'
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(16),
    [theme.breakpoints.down('xs')]: {      
      width: theme.spacing(8),
    },
  },
  textFieldAddressSummary: {
    "& input": {
      color: '#000000'      
    },
    "& textarea": {
      color: '#000000'
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(75.6),
    [theme.breakpoints.down('xs')]: {      
      width: theme.spacing(30),
    },
  },
  tooltips: {
    position: 'absolute',
    size: '0.5px',
    display: 'inlineblock',
    boxsizing: 'borderbox',
    color:'blue'
  },
  calToolBar: {
    left: '38'
    // position: 'absolute',
    // color: 'green'
  }
})

class MDTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {name: props.name, label: props.label, value: props.value, type: props.type, inputProps: props.inputProps,
           inputLabelProps: props.inputLabelProps, summary: props.summary, disabled: props.disabled, required: props.required,
           error: props.error, errorText: props.errorText, checkpattern: false, showId:false};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.value !== prevState.value) {
      return { value: nextProps.value }
    } if(nextProps.summary !== prevState.summary) {
      return { summary: nextProps.summary }
    } if(nextProps.disabled !== prevState.disabled) {
      return { disabled: nextProps.disabled }
    } if(nextProps.error !== undefined && (nextProps.error !== prevState.error)) {
      return { error: nextProps.error, errorText: nextProps.errorText }
    } if(nextProps.errorText !== undefined && (nextProps.errorText !== prevState.errorText)) {
      return { errorText: nextProps.errorText }
    } if(nextProps.required !== prevState.required) {
      return { required: nextProps.required, error: nextProps.required? undefined : false, errorText: nextProps.required? undefined : '' }
    } else return null;
  }

  validateInput = (propertyName) => (event) => {
    const { required } = this.state;
    if (required) {
      if (event.target.value === '') {
        this.setState({ error: true, errorText: 'Required Field. Please enter a value.' })
        this.props.handleError(propertyName, true)
      } else {      
        this.setState({ error: false, errorText: '' })
        this.props.handleError(propertyName, false)
      }
    }
  }

  // handleMouseDownIdentificationNumber = event => {
  //   event.preventDefault();
  // };

   handleClickShowIdentificationNumber = () => { 
    this.setState({showId:!this.state.showId})
   
    }

  validateDateInput = (propertyName) => (event) => {
    const { value } = this.state;
    if(value !== null && value.toString() === 'Invalid Date') {
        this.setState({ errorText: 'Required Field. Please enter a valid date.' });
    } else {
      this.props.handleError('datecomp', false)
    }
  }

  handleChange = (propertyName) => (event) => {
    // eslint-disable-next-line
    let checknamepattern = /[~@()+!#$%\^&*+=\\[\]\\;,/{}|\\":<>\?]/
    // eslint-disable-next-line
    let checkotherpattern = /[~`@()+!_#$%\^&*+=\\[\]\\';,/{}|\\":<>\?]/
    // eslint-disable-next-line
    let check_non_english_character = /[^\u0000-\u007F]/
    const { required } = this.state;
    
    if((propertyName==='first' || propertyName==='last' || propertyName==='middle' || propertyName==='business' || 
        propertyName==='signName' || propertyName==='signTitle' || propertyName==='carrierName')&& event.target.value!==undefined) {
        if((checknamepattern.test(event.target.value) || (check_non_english_character).test(event.target.value))) {
          this.setState({checkpattern:true})
        } else {      
          this.setState({ value: event.target.value, error: false, checkpattern:false });
          this.props.handleChange(propertyName, event.target.value);
        } 
      } else if ((propertyName==='passport'|| propertyName==='idnum' || propertyName==='alienNumber' || propertyName==='houseNumber' || 
          propertyName==='streetName' || propertyName==='city' || propertyName==='othercity'|| propertyName==='state' || propertyName==='country' || propertyName==='visaIssued' || 
          propertyName==='zip' || propertyName==='departurePort' || propertyName==='arrivePort' || propertyName==='methodOfShipment') && event.target.value!==undefined) {
        if((checkotherpattern.test(event.target.value) || (check_non_english_character).test(event.target.value) )) {
          this.setState({checkpattern:true})
        } else {      
          this.setState({ value: event.target.value, error: false, checkpattern:false });
          this.props.handleChange(propertyName, event.target.value);
        } 
    }  else {
      this.setState({ value: event.target.value, error: false });
      this.props.handleChange(propertyName, event.target.value);
    }

    if (required && propertyName !== 'dob' && propertyName !== 'visaDate') {
      if (event.target.value !== '') {   
        this.props.handleError(propertyName, false)
      }
    }
  }

  handleDateChange = (propertyName) => (date) => {
    const { required } = this.state;
    this.setState({ value: date });
    this.props.handleChange(propertyName, date);
    if (required) {
      if (date !== null && date !== '' && date.toString() !== 'Invalid Date') {
        if ((date < (new Date()).setFullYear((new Date()).getFullYear() - 150))
               || date > new Date()) {
          this.setState({ value: date, error: true, errorText: '' })
          this.props.handleError(propertyName, true)
        } else {
          this.setState({ value: date, error: false, errorText: '' })
          this.props.handleError(propertyName, false)
        }        
      } else if (date !== null && date.toString() === 'Invalid Date') {
        this.setState({ error: true, errorText: '' })
        this.props.handleError(propertyName, true)
      }
    }
  };

  render = () => {    
    const { classes, multiline, wide, currency,locator,thirdpartylocator,mailshiplocator} = this.props
    const { name, label, value, type, inputProps, inputLabelProps,
                  summary, required, error, errorText, checkpattern } = this.state              

    return (
      <div>
            {type !== 'date' && !checkpattern &&             
          <TextField 
              className={summary && wide? classes.textFieldAddressSummary : 
                     summary && currency? classes.textFieldCurrencySummary :
                                    wide? classes.textFieldAddress : 
                                currency? classes.textFieldCurrency :
                                 summary? classes.textFieldSummary : 
                                          classes.textField}
              name={name} 
              label={label}             
              value={value}
              onChange={this.handleChange(name)} 
              onBlur={this.validateInput(name)} 
              error={error} 
              helperText={errorText} 
              margin='normal'
              id={locator==='Review'?name+locator:thirdpartylocator==='thirdparty'?name+thirdpartylocator:mailshiplocator==='mailship'?name+mailshiplocator:name} 
              required={required} 
              multiline={multiline} 
              disabled={summary? true : false}
              type={name==='idnum'?this.state.showId?"text":"password":type} 
              inputProps={{...inputProps,'aria-label':label}}
              // eslint-disable-next-line
              InputProps = {name==='idnum'?{endAdornment: (<InputAdornment position="end"> <IconButton
              aria-label="Toggle password visibility"
              onClick={this.handleClickShowIdentificationNumber}
              onMouseDown={this.handleMouseDownPassword}
            >
              {this.state.showId ? <Visibility /> : <VisibilityOff />}
            </IconButton></InputAdornment>)}:""}
              InputLabelProps={inputLabelProps} 
          />                 
        }

      {type !== 'date' && checkpattern && 
          <Tooltip  title= 'Special characters are not allowed'  arrow aria-label="Special characters" >     
          <TextField 
              className={summary && wide? classes.textFieldAddressSummary : 
                     summary && currency? classes.textFieldCurrencySummary :
                                    wide? classes.textFieldAddress : 
                                currency? classes.textFieldCurrency :
                                 summary? classes.textFieldSummary : 
                                          classes.textField}
              name={name} 
              label={label}             
              value={value}
              onChange={this.handleChange(name)} 
              onBlur={this.validateInput(name)} 
              error={error}            
              helperText={errorText} 
              margin='normal' 
              id={locator==='Review'?name+locator:thirdpartylocator==='thirdparty'?name+thirdpartylocator:mailshiplocator==='mailship'?name+mailshiplocator:name} 
              required={required} 
              multiline={multiline} 
              disabled={summary? true : false}
              type={name==='idnum'?this.state.showId?"text":"password":type}  
              inputProps={{...inputProps,'aria-label':label}}
              // eslint-disable-next-line
              InputProps = {name==='idnum'?{endAdornment: (<InputAdornment position="end"> <IconButton
              aria-label="Toggle password visibility"
              onClick={this.handleClickShowIdentificationNumber}
              onMouseDown={this.handleMouseDownPassword}
            >
              {this.state.showId ? <Visibility /> : <VisibilityOff />}
            </IconButton></InputAdornment>)}:""}
              InputLabelProps={inputLabelProps} 
          /> 
          </Tooltip> 
        
        }
        
        {type === 'date' &&                
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="space-around">
          <KeyboardDatePicker
            className={summary && wide? classes.textFieldAddressSummary : 
              summary && currency? classes.textFieldCurrencySummary :
                             wide? classes.textFieldAddress : 
                         currency? classes.textFieldCurrency :
                          summary? classes.textFieldSummary : 
                                   classes.textField}
            // disableToolbar
            name={name}
            autoOk={true}
            disableFuture={true}
            minDate={(new Date()).setFullYear((new Date()).getFullYear() - 150)}
            views={["year", "month", "date"]}
            variant="inline"
            format="MM/dd/yyyy"
            label={label}
            error={error}
            {...(errorText !== '' ? {helperText : errorText} : {})}
            value={value === ''? null : value}
            onBlur={this.validateDateInput(name)} 
            onChange={this.handleDateChange(name)}
            required={required} 
            disabled={summary? true : false}
            KeyboardButtonProps={{
              'aria-label': {label},
            }}
            id={locator==='Review'?name+locator:thirdpartylocator==='thirdparty'?name+thirdpartylocator:mailshiplocator==='mailship'?name+mailshiplocator:name} 
            inputProps={{...inputProps,'aria-label':label + "  mm/dd/yyyy "}}
            InputLabelProps={inputLabelProps} 
          />        
          </Grid>
          </MuiPickersUtilsProvider>
        }                     
       </div>
    );
  }
}

export default withStyles(styles) (MDTextField);