import React, { Component } from 'react';
import { Typography, Grid, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import Currency from '../images/Foreign-Currency_35.jpg';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';


const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '16px',    
  },
  mainTitle: {
    color: '#003366',
    fontWeight: '654',
    margin: '26px 0 2px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(24),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(26),
    },
  },
  landingHeader: {
    display: 'flex',
    width: '100%',
    background: 'transparent',    
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  landing: {    
    backgroundRepeat: "no-repeat",
		backgroundPosition: "top center",
		backgroundSize: "cover",		
    float: 'left',
    width: '100%',
  },
  paper: {
    backgroundColor: '#1c3764',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
    backgroundSize: "cover",
  },
  currencyimg: { 
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    backgroundSize: "cover",
  },
  subpaper: {
    width: '93.5%',
    paddingTop: '20px',
    paddingBottom: '20px',
    marginLeft: '32px',
    marginRight: '16px',    
    color: theme.palette.text.primary,
    position:'relative',
    overflow:'hidden'
  },
  topheader: {
    padding: '0px 33px 3px 40px',
  },
  currencytext: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 15,
    },     
  },
  currencytext1: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 0,
    },
  },  
  searchLabel: {
    color: '#1E1E1E',
    fontWeight: '654',
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: '140px',
    height: '45px',
    borderRadius: '16px',
    backgroundColor: '#324bb5',
    color:'white',
    fontFamily: 'Roboto Medium, Roboto Regular, Roboto',
    fontSize: '19px',
    fontWeight: '600',
    textTransform: 'none'
  },
  iconBar: {
    marginTop: theme.spacing(1),
    height: 100,
  },

  formfill: {    
    marginLeft: '24px',
    paddingBottom: '24px',
    color: '#996515',
    fontWeight: 'bold'
  },
  linefill: {    
    color: '#CD853F',
    fontWeight: 'bold',
  },
  needhelpText: {    
    fontWeight: '500',
    color: '#87CEFA',
  },
  stepsline: {
    height: 30,
    color: '#996515',
    marginRight: '30px',
    fontWeight: 'bold',
  },
  formcard: {
    marginTop: theme.spacing(8),
    marginBottom: '92px',
    marginRight: theme.spacing(6),
    backgroundColor: '#ffffff',
    borderRadius: '8px'
  },
  formtext1: {
    color: '#1E1E1E',
    fontWeight: '600',
    fontSize: '18px',
    paddingTop: '20px',
    lineHeight: '1.2'
  },
  formtext2: {
    color: '#1E1E1E',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '1.2'
  }, 
  reportcard1: {
    paddingTop: '12px',
    fontFamily: 'Roboto Medium, Roboto Regular, Roboto',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '1.2'
  },
  reportcard2: {
    paddingBottom: theme.spacing(1),
    fontFamily: 'Roboto Medium, Roboto Regular, Roboto',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '1.2'
  },
  reportcard3: {
    borderTop: '64px solid #996515',
    borderRightWidth: '41px',
    borderRightStyle: 'solid',
    borderRightColor: '#1c3764',
  },
  needHelpImage: {    
    paddingRight: '6px',    
  },
  reportext: {
    color: 'white',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '22px',
    fontWeight: 'bold',
    width: '450px',
    marginLeft: '50px',
    marginTop: '-15px',
    marginBottom: '8px',
  },
  getStarted: {
    height: 1,
    color: '#483D8B',
    fontWeight: '500',
  },
  personal: {
    height: '20px',
    color: '#483D8B',
    fontWeight: '500',
    paddingLeft: '4px',    
  },  
  review: {
    height: '20px',
    color: '#483D8B',
    fontWeight: '500',
    paddingLeft: '4px',    
  },
  numberFont: {
    height: '31px',
    color: '#996515',
    fontWeight: '300',
    fontSize: 37,
  },
  fillervertical: {
    marginTop: '52px',
    marginBottom: '16px'
  },
  fillerright: {
    marginRight: '60px'
  },
  gridPanel: {
    marginBottom: '6px', 
    [theme.breakpoints.down('xs')]: { 
      paddingRight: '8px', 
      marginRight: '8px', 
    },  
  },
  expansionPanel: {  
    backgroundColor: '#f1f1f1',
    // borderRadius: 'px',    
    [theme.breakpoints.down('xs')]: { 
      minWidth: '420px',
    },
    [theme.breakpoints.up('sm')]: {      
      minWidth: '580px',
    },
    [theme.breakpoints.up('md')]: {      
      minWidth: '842px',
    },
    [theme.breakpoints.up('lg')]: {      
      minWidth: '842px',
    },
  },
  expansionPanelSummary: {    
    backgroundColor: '#bdbdbd',
    border: '1px',
    borderColor: '#ddd',
    borderRadius: '5px',
    color: '#333',
    minHeight: '30px',
    maxHeight: '43px',  

  },  
  faqTitle: {
    paddingLeft: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#000000'
  },
  expansiondetail: {
    display:'grid',
    backgroundColor: '#f1f1f1',
    borderRadius: '12px',
  },
  buttonExpandCollapse:{
    paddingBottom:'10px',
    marginLeft: theme.spacing(3) 
  },
  buttonstyleExpandCollapse:{
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100, 
    color: '#3c27ad',
   },
  scrollToTop:{  
    zindex: '999',
    position: 'fixed',
    margin: 'auto',
    right: '-7px',
    bottom: '300px',
    width: '60px',
    height: '60px',
    background: '#3c27ad',
    color: '#fff',
    padding: '12px 15px',
    fontsize: '20px'  
  },
  scrollToTopText:{
    paddingTop:'5px',
    align:'center',
    fontWeight:'bold',  
  }   
});

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = { expansionStatus: this.initializeExpansionStatus(), scrollPosition:0, displayUpButton:false, search:'' };
    this.searchHandler = this.searchHandler.bind(this);       
  }

  componentDidMount() {
    // const { expansionStatus } = this.state;
    window.addEventListener('scroll',this.handleScroll);
  }

  initializeExpansionStatus() {
    const status = Array.apply(null, Array(24)).map(function () { return false; })
    return status;
  }

  handleExpansionChange = panel => (event) => {
    const { expansionStatus } = this.state;
    const newExpansionStatus = expansionStatus
    const currStatus = expansionStatus[panel];
 
    newExpansionStatus[panel] = currStatus? false : true;
 
    this.setState({
      expansionStatus: newExpansionStatus
    });
  };

  expandAllCollapseAll = expandAll => (event) => {
    const newExpansionStatus = Array.apply(null, Array(24)).map(function () { return expandAll; })
 
    this.setState({
      expansionStatus: newExpansionStatus
    });
  }

  handleClick= e =>{
    window.scrollTo({top: 0, left: 120, behavior: 'smooth' });
  }
  
  handleScroll = (event) => {
    this.setState({
      scrollPosition:window.pageYOffset
    },this.checkScroll)
  }

  checkScroll =() => {
    if(this.state.scrollPosition>15){
      this.setState({
        displayUpButton:true
      })
    }else {
      this.setState({
        displayUpButton:false
      })
    }
  }

  searchHandler(event) {
    const newExpansionStatus = Array.apply(null, Array(24)).map(function () { return true; })
    this.setState({search: event.target.value, expansionStatus: newExpansionStatus});
  }

  handleScrollToTop= () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  render = () => {
    const { classes } = this.props
    const { expansionStatus, search} = this.state
    var Highlight = require('react-highlighter');

    return (
   <div className={classes.landing}>    
    <div className={classes.landingHeader}>            
      <Grid className={classes.root} >     
          <Grid container className={classes.topheader} alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
          <Grid item>
          <Grid container alignItems={"flex-start"} direction={"row"} justify={"flex-start"}>
              <Grid className={classes.currencytext} >FinCEN Form 105</Grid>              
              <Grid className={classes.currencytext1} ><span>&nbsp;</span>| Currency and Monetary Instrument Report (CMIR)</Grid>
              </Grid>  
          </Grid>
            <Typography className={classes.searchLabel} align='right'>
              <TextField 
                label="Search FAQ"
                InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }} onChange={this.searchHandler}
               value={search}
           />
            </Typography>
          </Grid>
         
          <Typography className={classes.mainTitle} align='center'>Frequently Asked Questions</Typography>
          <Grid container className={classes.subpaper} alignItems={"center"} direction={"column"} flexgrow={"column no-wrap"} justifyContent={"space-between"}>
          <Grid className={classes.buttonExpandCollapse} align='right'>
          <Button className={classes.button} onClick={this.expandAllCollapseAll(true)}>Expand All</Button>
          <Button className={classes.button} onClick={this.expandAllCollapseAll(false)}>Collapse All</Button>
          {/* <Button className={classes.button} variant="contained" color="primary" onClick={() => this.selectPersonalOrBusiness('P')} >Personal</Button> */}
          </Grid> 
            <Grid className={classes.gridPanel} item>
            <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[0]} onChange={this.handleExpansionChange(0)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[0]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>1.What is the purpose of this form?</Highlight></Typography>      
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
                        <Typography><Highlight search={this.state.search}>The Financial Crimes Enforcement Network (FinCEN) Form 105 (also called Currency and Monetary Instruments Report) is required for persons who are transporting or mailing currency or monetary instruments over $10,000 USD into or out of the United States, as required under the </Highlight> <a rel="noopener noreferrer" target="_blank" href={'https://en.wikipedia.org/wiki/Bank_Secrecy_Act'}> Currency and Foreign Transaction Reporting Act (or Bank Secrecy Act)</a>. <Highlight search={this.state.search}>The mission of FinCEN is to safeguard the financial system from illicit use, combat money laundering, and promote national security through the strategic use of financial authorities and the collection, analysis, and dissemination of financial intelligence.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} align={"left"} expanded={expansionStatus[1]} onChange={this.handleExpansionChange(1)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[1]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search= {this.state.search}>2.	Why do I have to provide this information?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search={this.state.search}>This report is required by 31 U.S.C. 5316, 31 CFR 1010.306, and Treasury Department regulations (31 CFR Chapter X).  Pursuant to the requirements of Public law 93-579 (Privacy Act of 1974), the authority to collect information on Form 105 in accordance with 5 U.S.C. 552a(e)(3) is Public law 91-508; 31 U.S.C. 5316; 5 U.S.C. 301; Reorganization Plan No.1 of 1950; Treasury Department Order No. 165, revised, as amended; 31 CFR Chapter X; and 44 U.S.C. 3501.
                        The principal purpose for collecting the information is to assure maintenance of reports or records where such reports or records have a high degree of usefulness in criminal, tax, or regulatory investigations or proceedings. The information collected may be provided to those officers and employees of the U.S. Customs and Border Protection (CBP) and any other constituent unit of the Department of the Treasury who have a need for the records in the performance of their duties. The records may be referred to any other department or agency of the Federal Government upon the request of the head of such department or agency. The information collected may also be provided to appropriate state, local, and foreign criminal law enforcement and regulatory personnel in the performance of their official duties.
                        Disclosure of this information is mandatory pursuant to 31 U.S.C. 5316 and 31 CFR Chapter X. Failure to provide all or any part of the requested information may subject the currency or monetary instruments to seizure and forfeiture, as well as subject the individual to civil and criminal liabilities. Disclosure of the Social Security Number is mandatory. The authority to collect this number is 31 U.S.C. 5316(b) and 31 CFR 1010.306(d). The Social Security Number will be used as a means to identify the individual who files the record.
                        The collection of this information is mandatory pursuant to 31 U.S.C. 5316, of Title II of the Bank Secrecy Act, which is administered by Treasury’s Financial Crimes Enforcement Network (FinCEN).
                      </Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[2]} onChange={this.handleExpansionChange(2)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[2]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search = {this.state.search}>3. How long will it take me to complete this form?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
                        <Typography><Highlight search ={this.state.search}>The estimated average burden associated with this collection of information is 11 minutes per respondent or record keeper depending on individual circumstances. Comments concerning the accuracy of this burden estimate and suggestions for reducing this burden should be directed to the Department of the Treasury, Financial Crimes Enforcement Network, P.O. Box 39 Vienna, Virginia 22183.</Highlight></Typography>
          </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[3]} onChange={this.handleExpansionChange(3)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[3]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search = {this.state.search}>4. Can I save the form to complete it at a later date or time?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
                        <Typography><Highlight search = {this.state.search}>The estimated average burden associated with this collection of information is 11 minutes per respondent or record keeper depending on individual circumstances. Comments concerning the accuracy of this burden estimate and suggestions for reducing this burden should be directed to the Department of the Treasury, Financial Crimes Enforcement Network, P.O. Box 39 Vienna, Virginia 22183.</Highlight></Typography>
          </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>       
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[4]} onChange={this.handleExpansionChange(4)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[4]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>5. Who must file?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search = {this.state.search}>a) Each person who physically transports, mails, or ships, or causes to be physically transported, mailed, or shipped currency or other monetary instruments in an aggregate amount exceeding $10,000 at one time from the United States to any place outside the United States or into the United States from any place outside the United States, </Highlight><br/> <br/>
            <Highlight search = {this.state.search}>b) Each person who receives in the United States currency or other monetary instruments in an aggregate amount exceeding $10,000 at one time which have been transported, mailed, or shipped to the person from any place outside the United States, </Highlight> <br/> <br/>
            <Highlight search = {this.state.search}>c) A common carrier of passengers in respect to currency or other monetary instruments in the possession of its passengers,and </Highlight> <br/> <br/>
            <Highlight search = {this.state.search}>d) A common carrier of goods in respect to shipments of currency or monetary instruments not declared to be such by the shipper</Highlight> <br/> <br/>
            </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>

          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[5]} onChange={this.handleExpansionChange(5)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[5]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>6. Who does not have to file?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
                     <Typography> <Highlight search = {this.state.search}> Reports are not required to be filed by </Highlight><br/> <br/>
                      <Highlight search = {this.state.search}>a)	a Federal Reserve bank,</Highlight>  <br/> <br/>
                      <Highlight search = {this.state.search}>b)	a bank, a foreign bank, or a broker or dealer in securities in respect to currency or other monetary instruments mailed or shipped through the postal service or by common carrier,</Highlight> <br/> <br/>
                      <Highlight search = {this.state.search}>c)	a commercial bank or trust company organized under the laws of any State or of the United States with respect to overland shipments of currency or monetary instruments shipped to or received from an established customer maintaining a deposit relationship with the bank, in amounts which the bank may reasonably conclude do not exceed amounts commensurate with the customary conduct of the business, industry, or profession of the customer concerned, </Highlight> <br/>  <br/>
                      <Highlight search = {this.state.search}>d)	a person who is not a citizen or resident of the United States in respect to currency or other monetary instruments or shipped from abroad to a bank or broker or dealer in securities through the post al service or by common carrier,</Highlight> <br/> <br/>
                      <Highlight search = {this.state.search}>e)	a travelers’ check issuer or its agent in respect to the transportation of travelers’ checks prior to their delivery to selling agents for eventual sale to the public, </Highlight>  <br/> <br/>
                      <Highlight search = {this.state.search}>f)	a person with a restrictively endorsed traveler’s check that is in the collection and reconciliation process after the traveler’s check has been negotiated, nor by </Highlight> <br/> <br/>
                      <Highlight search = {this.state.search}>g)	a person engaged as a business in the transportation of currency, monetary instruments and other commercial papers with respect to the transportation of currency or other monetary instruments overland between established offices of banks or brokers or dealers in securities and foreign persons. </Highlight> <br/> <br/>
                      <Highlight search = {this.state.search}> A TRANSFER OF FUNDS THROUGH NORMAL BANKING PROCEDURES, WHICH DOES NOT INVOLVE THE PHYSICAL TRANSPORTATION OF CURRENCY OR MONETARY INSTRUMENTS, IS NOT REQUIRED TO BE REPORTED </Highlight> <br/> <br/></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[6]} onChange={this.handleExpansionChange(6)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[6]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>7.	When do I need to submit the form?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography> <Highlight search = {this.state.search}>a. Travelers planning to carry currency or other monetary instruments with them must submit the form within three days (72 hours) prior to the planned travel date in or out of the United States.</Highlight><br/><br/>
            <Highlight search = {this.state.search}>b. Recipients—Unless filed by the sender, each person who receives currency or other monetary instruments in the United States must submit the form within 15 days after receipt of the currency or monetary instruments.</Highlight> <br/><br/>
            <Highlight search = {this.state.search}>c. Shippers or Mailers— lf the currency or other monetary instrument will not accompany the person entering or departing the United States, the form should be submitted on or before the date of mailing or shipping.</Highlight></Typography><br/><br/>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[7]} onChange={this.handleExpansionChange(7)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[7]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>8. What if my travel plans change?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search = {this.state.search}>Any change in travel plans may require you to fill out a new FinCEN 105 Form. Upon submission of the form, you have three days (72 hours) to arrive or depart the United States via a port of entry.  If you travel after the three days (72 hours), or to a different location than planned, you will be required to complete a new FinCEN Form 105.  If you decide to cancel your plans, no additional action is required.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[8]} onChange={this.handleExpansionChange(8)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[8]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>9.	What if the amount I need to report changes after I submit the form?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search ={this.state.search}>If the amount of currency or monetary instruments that you need to report changes after you submit the form, you will be required to submit a new form with the correct amount.  The previous form you submitted will expire with no action required.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[9]} onChange={this.handleExpansionChange(9)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[9]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>10. What exchange rate do I use?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search = {this.state.search}>The exchange rate is calculated automatically. You do not need to figure out the exchange rate.  Note that if the total calculation does not amount to $10,000 USD or more, you do not have to file the form.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>             
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[10]} onChange={this.handleExpansionChange(10)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[10]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>11. Do I need to fill out the paper form in addition to electronic version?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search = {this.state.search}>No.  If you have completed and submitted the form on the website, you do not need to complete a paper version of FinCEN Form 105</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[11]} onChange={this.handleExpansionChange(11)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[11]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>12. Can I fill out the form at a port of entry?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search ={this.state.search}>Yes. The website is mobile-friendly and is accessible on smart phones, tablets and laptop computers.  A connection to the Internet is required.  Once you submit the form at a port of entry, provide your confirmation number to the officer for processing.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[12]} onChange={this.handleExpansionChange(12)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[12]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>13. Does the form expire?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail} >
            <Typography><Highlight search ={this.state.search}>Yes. Upon submission, the form is valid for three days (72 hours).  You must travel to a port of entry within three days (72 hours) and present your valid receipt with confirmation number.  Travel after the three days (72 hours) will require a new FinCEN Form 105.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
      
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[13]} onChange={this.handleExpansionChange(13)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[13]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>14. What do I do after I submit the form?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail} >
            <Typography><Highlight search = {this.state.search}>Once you complete the form and endorse it online, you may print or save the confirmation receipt, or email it.  When you travel, present the receipt or provide your confirmation number to the Customs and Border Protection (CBP) officer at the port of entry.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
         
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[14]} onChange={this.handleExpansionChange(14)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[14]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>15. Why is the ‘Next’ button greyed out?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail} >
            <Typography><Highlight search ={this.state.search}>The ‘Next’ button will remain greyed out if required fields are not filled out, or if incorrect information is entered (for example, if a future date is selected for the Date of Birth). Once the fields are complete or the errors are corrected, the ‘Next’ button will be activated.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[15]} onChange={this.handleExpansionChange(15)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[15]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search ={this.state.search}>16. Do I have to write my name in the signature box?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail} >
            <Typography><Highlight search ={this.state.search}>The signature box is an optional field. You may use your computer's mouse or your finger(if you have touch screen device) to draw a signature.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>        
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[16]} onChange={this.handleExpansionChange(16)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[16]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>17. What business information do I need to provide?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail} >
            <Typography><Highlight search={this.state.search}>If you are transporting or mailing/shipping monetary instrument for a business, you will need to provide the business name, business identification number, and business address.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[17]} onChange={this.handleExpansionChange(17)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[17]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>18. What if I don’t file?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search={this.state.search}>No person otherwise required to file a report shall be excused from liability for failure to do so if, in fact, a complete and truthful report has not been filed. Civil and criminal penalties, including under certain circumstances a fine of not more than $500,000 and imprisonment of not more than ten years, are provided for failure to file a report, filing a report containing a material omission or misstatement, or filing a false or fraudulent report. In addition, the currency or monetary instrument may be subject to seizure and forfeiture. See 31 U.S.C.5321 and 31 CFR 1010.820; 31 U.S.C. 5322 and 31 CFR 1010.840; 31 U.S.C. 5317 and 31 CFR 1010.830, and U.S.C. 5332.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[18]} onChange={this.handleExpansionChange(18)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[18]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>19. How will my information be used?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography><Highlight search={this.state.search}>The information collected on the web-based form is the same that is collected today on the paper form.  The information on the form is reviewed by CBP officers and stored in a CBP database pursuant to  the same authorities as above (FAQ “Why do I have to provide this information?”). The principal purpose for collecting the information is to assure maintenance of reports or records where such reports or records have a high degree of usefulness in criminal, tax, or regulatory investigations or proceedings.  Information collected is also used to analyze foreign currency transactions to identify potential fraudulent or illegal activities and identify trends.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[19]} onChange={this.handleExpansionChange(19)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[19]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>20. Can I still use the paper form?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
                <Typography><Highlight search={this.state.search}>The paper form is still available for use. You can find it</Highlight> <a rel="noopener noreferrer" target="_blank" href={'https://www.fincen.gov/sites/default/files/shared/fin105_cmir.pdf'}>here</a>.<Highlight search={this.state.search}> Please complete the form prior to your planned travel date and present it to the Customs and Border Protection (CBP) officer at the port of entry. You can also ask for a copy of the form at the port of entry and complete it there.</Highlight></Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>  
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[20]} onChange={this.handleExpansionChange(20)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[20]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>21. What is a Currency Transporter (Carrier)?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
                <Typography><Highlight search={this.state.search}>A Currency Transporter is a common carrier of currency that transports currency or other monetary instruments 
                as a business, for a person that engages the carrier for a fee (the “shipper”), from one place to another, to be delivered to the person appointed by the shipper 
                to receive the currency or other monetary instruments (the “consignee”). The shipper may be acting of its own accord or on instructions from a different person 
                (the “currency originator”), and the consignee may be instructed to deliver the currency or other monetary instruments to the account of a final beneficiary 
                (the “currency recipient”). The same person may fulfill more than one role in the same shipment. </Highlight> 
                <a rel="noopener noreferrer" target="_blank" href={'https://www.fincen.gov/sites/default/files/shared/fin105_cmir.pdf'}>here</a>.
                <Highlight search={this.state.search}> Please complete the form prior to your planned travel date and present it to the Customs and Border Protection (CBP) officer 
                at the port of entry. You can also ask for a copy of the form at the port of entry and complete it there.</Highlight> <br/><br/>
                <Highlight search={this.state.search}> 
                **<a rel="noopener noreferrer" target="_blank" href={'https://www.fincen.gov/resources/statutes-regulations/administrative-rulings/administrative-ruling-application-fincen'}>FIN-2014-R010 </a>
                 Administrative Ruling on the Application of FinCEN Regulations to Currency Transporters, 
                Including Armored Car Services, and Exceptive Relief -- Issued Date: September 24, 2014.</Highlight> <br/>
                </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid> 
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[21]} onChange={this.handleExpansionChange(21)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[21]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>22. What is the limited exemption from filing for Common Carriers of Currency?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
            <Typography>       <Highlight search={this.state.search}> A.  The transportation consists of currency or other monetary instruments imported into the United States or exported out of the United States </Highlight><br/> <br/>
                               <Highlight search={this.state.search}> B.	The transportation takes place overland. </Highlight> <br/> <br/>          
                               <Highlight search={this.state.search}> C.	The transportation takes place between a bank or a broker or dealer in securities, on the U.S. side, and a non-U.S. person, on the foreign side </Highlight> <br/> <br/> 
                               <Highlight search={this.state.search}> D.	The shipment is picked up or delivered at the established office of the bank or a broker or dealer in securities on the U.S. side</Highlight> <br/> <br/>
                               <Highlight search={this.state.search}> ** All of the above four conditions have to be met</Highlight> <br/>
                               ** <a rel="noopener noreferrer" target="_blank" href={'https://www.govinfo.gov/app/details/CFR-2016-title31-vol3/CFR-2016-title31-vol3-sec1010-340'}>31 CFR §1010.340 (c)(9)</a>
                               <Highlight search={this.state.search}> (common carrier of currency exemption).</Highlight> <br/>
                               
                               </Typography> <br/>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>  
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[22]} onChange={this.handleExpansionChange(22)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[22]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>23. What are the other exemptions that are not applicable to Common Carriers of Currency?</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
                <Typography>
                  <Highlight search={this.state.search}>Although the CMIR regulations include a number of exemptions that apply to other parties, a common carrier of currency may not claim for itself any exemption for filing a CMIR that might be 
                  applicable to other parties. For example, banks, foreign banks or brokers or dealers in securities are themselves exempted from filing a CMIR with respect to currency or other monetary instruments that they mail or ship via 
                  the postal service or a common carrier. A common carrier of currency cannot apply this exemption to itself (although, as described above, the limited exemption may apply). For example, if a common carrier of currency picks up at 
                  an airport a cargo of currency air-shipped to a U.S. bank from abroad, the common carrier has an obligation to file a CMIR even though the bank does not
                  </Highlight> <a rel="noopener noreferrer" target="_blank" href={'https://www.fincen.gov/sites/default/files/shared/fin105_cmir.pdf'}>here</a>.
                  <Highlight search={this.state.search}> Please complete the form prior to your planned travel date and present it to the Customs and Border Protection (CBP) officer at the port of entry. You can also ask for a copy of the form at the
                   port of entry and complete it there.</Highlight> <br/> <br/>                  
                  **<a rel="noopener noreferrer" target="_blank" href={'https://www.govinfo.gov/app/details/CFR-2016-title31-vol3/CFR-2016-title31-vol3-sec1010-340'}>31 CFR §1010.340 (c)</a>
                  <Highlight search={this.state.search}> (all exemptions).</Highlight>
                   <br/>
                </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>          
          <Grid className={classes.gridPanel} item>
          <ExpansionPanel className={classes.expansionPanel} expanded={expansionStatus[23]} onChange={this.handleExpansionChange(23)}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
              {expansionStatus[23]? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
              <Typography className={classes.faqTitle}><Highlight search={this.state.search}>24. Terms/Defintions</Highlight></Typography>              
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansiondetail}>
                  <Typography> <Highlight search={this.state.search}> A.	Bank—Each agent, agency, branch or office within the United States of any person doing business in one or more of the capacities listed: (1) a commercial bank or trust company organized under the laws of any State or of the United States; (2) a private bank; (3) a savings association, savings and loan association, and building and loan association organized under the laws of any State or of the United States; (4) an insured institution as defined in section 401 of the National Housing Act; (5) a savings bank, industrial bank or other thrift institution; (6) a credit union organized under the laws of any State or of the United States; (7) any other organization chartered under the banking laws of any State and subject to the supervision of the bank supervisory authorities of a State other than a money service business; (8) a bank organized under foreign law; and (9) any national banking association or corporation acting under the provisions of section 25A of the Federal Reserve Act (12 U.S.C. Sections 611-632).</Highlight><br/> <br/>
                               <Highlight search={this.state.search}> B.	Foreign Bank—A bank organized under foreign law, or an agency, branch or office located outside the United States of a bank. The term does not include an agent, agency, branch or office within the United States of a bank organized under foreign law. </Highlight> <br/> <br/>          
                               <Highlight search={this.state.search}> C.	Broker or Dealer in Securities—A broker or dealer in securities, registered or required to be registered with the Securities and Exchange Commission under the Securities Exchange Act of 1934. </Highlight> <br/> <br/> 
                               <Highlight search={this.state.search}> D.	Currency: The coin and paper money of the United States or any other country that is (1) designated as legal tender and that (2) circulates and (3) is customarily accepted as a medium of exchange in the country of issuance.</Highlight> <br/> <br/>
                               <Highlight search={this.state.search}> E.	Identification Number—Individuals must enter their social security number, if any. However, aliens who do not have a social security number should enter passport or alien registration number. All others should enter their employer identification number.</Highlight><br/> <br/>
                               <Highlight search={this.state.search}> F.	Monetary Instruments— (1) Coin or currency of the United States or of any other country, (2) traveler’s checks in any form, (3) negotiable instruments (including checks, promissory notes, and money orders) in bearer form, endorsed without restriction, made out to a fictitious payee, or otherwise in such form that title thereto p asses upon delivery, (4) incomplete instruments (including checks, promissory notes, and money orders) that are signed but on which the name of the payee has been omitted, and (5) securities or stock in bearer form or otherwise in such form that title thereto passes upon delivery. Monetary instruments do not include (i) checks or money orders made payable to the order of a named person which have not been endorsed or which bear restrictive endorsements, (ii) warehouse receipts, or (iii) bills of lading.</Highlight><br/> <br/>
                               <Highlight search={this.state.search}> G.	Person—An individual, a corporation, partnership, a trust or estate, a joint stock company, an association, a syndicate, joint venture or other unincorporated organization or group, an Indian Tribe (as that term is defined in the Indian Gaming Regulatory Act), and all entities cognizable as legal personalities.</Highlight> <br/> <br/>
                               <Highlight search={this.state.search}> H.	Confirmation Number – A number generated automatically when you complete the form. It is a twelve-digit alpha-numeric sequence (for example, CM0000000001).</Highlight> <br/> <br/>
                               <Highlight search={this.state.search}> I.	Common Carrier – Any person engaged in the business of transporting individuals or goods for a fee who holds itself out as ready to engage in such transportation for hire and who undertakes to do so indiscriminately for all persons who are prepared to pay the fee for the particular service offered.</Highlight>
                               **<a rel="noopener noreferrer" target="_blank" href={'https://www.govinfo.gov/app/details/CFR-2011-title31-vol3/CFR-2011-title31-vol3-sec1010-100'}>31 CFR §1010.100 (k)</a> <Highlight search={this.state.search}> (definition of ‘common carrier’).</Highlight> <br/><br/>
                               <Highlight search={this.state.search}> J.	Armored Car Service – A subgroup of common carriers, those persons engaged as a business in the transportation of currency, other monetary instruments, or commercial papers, referred to herein as “common carriers of currency.”</Highlight></Typography> <br/>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </Grid>         
        </Grid>        
      </Grid> 
      {this.state.displayUpButton?
         <div> <button className= {classes.scrollToTop} onClick={this.handleScrollToTop}><ArrowUpwardIcon/><text className={classes.scrollToTopText}>TOP</text></button></div>:null}           
      </div>
</div> 
 
    );
  }
}

export default withStyles(styles)(Help);