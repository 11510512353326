import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core';
// import AsyncSelect from 'react-select/lib/Async';
import AsyncSelect from 'react-select/async'
import { GetReferenceCountries, GetReferenceCurrencies, GetReferenceStates, GetReferenceFilteredStates, 
      LoadCitiesReference, } from '../../service/ReferenceData';

const styles = theme => ({
  countryInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(24),
    height: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif;',
    [theme.breakpoints.down('xs')]: {      
      width: theme.spacing(30),
      marginTop: '22px',
      marginBottom: '26px'
    }, 
  },
  countryInputSelected: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(24),
    height: '42px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif;',
    [theme.breakpoints.down('xs')]: {      
      width: theme.spacing(30),
      marginBottom: '8px',
    }, 
  },
  currencyInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(50),
    height: '42px',
    [theme.breakpoints.down('xs')]: {      
      width: theme.spacing(30),
      marginTop: '22px',
      marginBottom: '26px'
    }, 
  },
  currencyInputSelected: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(50),
    height: '42px',
    [theme.breakpoints.down('xs')]: {      
      width: theme.spacing(30),
      marginBottom: '8px',
    }, 
  },
  selectLable: {
    fontSize: theme.typography.pxToRem(12),    
    color: '#525252',
    marginLeft: '8px',     
  },
  selectLableerror: {
    fontSize: theme.typography.pxToRem(12),    
    color: '#9C2F36',
    marginLeft: '8px',    
  },
  redstar: {
    marginLeft: '3px',
    color: '#9C2F36',    
  },
  labelRow: {
    [theme.breakpoints.down('xs')]: {      
      marginTop: '10px',      
    },    
  },
  noOptions: {    
    textAlign: 'center',
    boxSizing: 'border-box',
    padding: '8px 12px'
  }
})

class AsyncReactSelect extends Component {
  constructor(props) {
    super(props);    
    this.state = {optionsType: props.optionsType, countries: [], currencies: [], states: [], cities: [], name: props.name, label: props.label, value: props.value, error: false, 
      inUnitedStates: props.inUnitedStates, placeholder: props.placeholder, errorText: props.errorText, required: props.required, summary: props.summary, uniqId:props.uniqId,
      selected: false, onUSA: props.onUSA, filter: props.filter, filterCountry: props.filterCountry, filterCity: props.filterCity, isLoading: false, otherCities:'OTHER',
      miTypes:    [
                   {value: 'cash', otherTypeDescription: 'Cash/Currency', label: 'Cash/Currency'},
                   {value: 'coin', otherTypeDescription: 'Coin', label: 'Coin'},
                   {value: 'stock', otherTypeDescription: 'Securities/Stocks', label: 'Securities/Stocks'},
                   {value: 'tvlch', otherTypeDescription: 'Travelers Check', label: 'Travelers Check'},
                   {value: 'ncheck', otherTypeDescription: '*NI: Check', label: 'Negotiable Instruments: Check'}, 
                   {value: 'npnote', otherTypeDescription: '*NI: Promissory Note', label: 'Negotiable Instruments: Promissory Note'},
                   {value: 'nmnord', otherTypeDescription: '*NI: Money Order', label: 'Negotiable Instruments: Money Order'},
                   {value: 'icheck', otherTypeDescription: '*II: Check', label: 'Incomplete Instruments: Check'}, 
                   {value: 'ipnote', otherTypeDescription: '*II: Promissory Note', label: 'Incomplete Instruments: Promissory Note'},
                   {value: 'imnord', otherTypeDescription: '*II: Money Order', label: 'Incomplete Instruments: Money Order'},                   
                  ]
      };   
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(JSON.stringify(nextProps.filter) + " static out " + JSON.stringify(prevState.filter))    
    // if ((nextProps.value !== "") && (nextProps.value !== prevState.value)) {
    if (nextProps.value !== prevState.value) {
      // console.log(JSON.stringify(nextProps.value) + " static v " + JSON.stringify(prevState.value))
      return { value: nextProps.value }
    } if ((nextProps.label !== "") && (nextProps.label !== prevState.label)) {
      // console.log(JSON.stringify(nextProps.label) + " static l " + JSON.stringify(prevState.label))
      return { label: nextProps.label }
    } else if(nextProps.summary !== prevState.summary) {      
      return { summary: nextProps.summary }   
    } if(nextProps.required !== prevState.required) {
      return { required: nextProps.required, error: nextProps.required? undefined : false, errorText: nextProps.required? undefined : '' }
    } else if(nextProps.filterCountry !== prevState.filterCountry && prevState.filterCountry !== '') {
      // console.log(JSON.stringify(nextProps.filterCountry) + " static fc " + JSON.stringify(prevState.filterCountry))
      return { filterCountry: nextProps.filterCountry, value: nextProps.filterCountry }
    // } else if (nextProps.filter !== prevState.filter) {      
    } else if (nextProps.filter !== undefined && nextProps.filter !== "" &&
              (nextProps.filter === 'USA' || nextProps.filter === 'MEX' || nextProps.filter === 'CAN')) {
        // console.log(JSON.stringify(nextProps.filter) + " static " + JSON.stringify(prevState.filter))     
        const newStates = GetReferenceFilteredStates(nextProps.filter)        
        return { filter: nextProps.filter, states: newStates}
    // }  else if (nextProps.optionsType === 'city' && nextProps.filterCity !== undefined && nextProps.filterCity !== "") {
    //     console.log(JSON.stringify(nextProps.filterCity) + " statechanged " + JSON.stringify(prevState.filterCity))     
    //     const newCities = LoadCitiesReference(nextProps.filterCity)
    //     console.log(newCities)
    //     newCities.then(result => {          
    //       const parsedresult = result.map(city => ({
    //                 value: city.cityCode,            
    //                 label: city.cityName,
    //                 stateDescription: city.cityName,
    //                 stateCode: city.stateCode,
    //                 stateName: city.stateName,
    //                 countryCode: city.countryCode
    //               }))
    //       console.log(JSON.stringify(parsedresult));        
    //       return { filterCity: nextProps.filterCity, cities: [{"value":"411","label":"AMCHITKA","stateDescription":"AMCHITKA","stateCode":"AK","stateName":"ALASKA"}]}
    //     })
    //     // console.log('rest cities ' + JSON.stringify(newCities.then()))
    //     // return { filterCity: nextProps.filterCity, cities: [{"value":"411","label":"AMCHITKA","stateDescription":"AMCHITKA","stateCode":"AK","stateName":"ALASKA"}]}
      } else {
      return null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.filterCity + ' comp update out ' + prevProps.filterCity)
    if (this.props.filterCity !== prevProps.filterCity) {
      // console.log(this.props.filterCity + ' comp update in ' + prevProps.filterCity)
      // console.log('cities ' + this.state.cities )
      this.setState({ cities: '', isLoading: true })
      const newCities = LoadCitiesReference(this.props.filterCity)
      newCities.then(result => {
        if(result !== undefined)  {
          const parsedresult = result.map(city => ({
                    value: city.cityCode,            
                    label: city.cityName,
                    stateDescription: city.cityName,
                    stateCode: city.stateCode,
                    stateName: city.stateName,
                    countryCode: city.countryCode
                  }))
          parsedresult.push({
            value: 'OTHER',
            label: 'OTHER', 
            stateDescription: 'XX',
            stateName: 'OTHER',
            stateCode: 'XX',
            countryCode: undefined
          })        
          // console.log(JSON.stringify(parsedresult));
          this.setState({ filterCity: this.props.filterCity, cities: parsedresult, isLoading: false })
          // console.log('cities updated for state ' + this.props.filterCity)
      } else {
        this.setState({ filterCity: this.props.filterCity, isLoading: false })
        console.log('cities update failed. No cities returned from the service call for ' + this.props.filterCity)
      }
     })
    }
  }

  componentDidMount() {
    if (this.props.inUnitedStates === true) {      
      this.setState({ states: GetReferenceFilteredStates('USA') })
    } else {
      this.setState({ states: GetReferenceStates() })
    }   
  }

  validateInput = (propertyName, value) => (event) => {    
    // alert(propertyName + ' validate ' + JSON.stringify(value) + ' value event ')    
    // console.log(JSON.stringify(value) + ' event ' + event.currentTarget + ' ' + this.state.value)
    if (value === null || value === "") {
      this.setState({ error: true })
      this.props.handleError(propertyName, true)
    } else {      
      this.setState({ error: false })
      this.props.handleError(propertyName, false)
    }
  }

  resetInputValidation = (propertyName, value) => {    
    // alert(propertyName + ' validate ' + JSON.stringify(value) + ' value event ')    
    // console.log(JSON.stringify(value) + ' reset ' + this.state.value)
    if (value === null || value === "") {
      this.setState({ error: true })
      this.props.handleError(propertyName, true)
    } else {      
      this.setState({ error: false })
      this.props.handleError(propertyName, false)
    }
  }

  // validateInputSample = (name, value, type) => (event) => {
  //   console.log(name + ' sample ' + JSON.stringify(value) + ' type ' + type + ' ' + event.target.value)
  // }

  handleClick = (propertyName) => (event) => {
    this.setState({ selected: true });
    
  }

  handleSelectChange = (name, selectedOption) => (event) => {
    // console.log(name + " async " + JSON.stringify(event) + " " + JSON.stringify(this.state.value))
    // alert('handlechange ' + JSON.stringify(event))
    const { required } = this.state;
    // this.setState({ value: event, selected: true });
    if (event === null || event === "") {
      event = '';
    } else {
      this.setState({ value: event, selected: true });
    }
    // if (event !== null && event !== "") {
    //   this.setState({ value: event, selected: true });
    // }

    this.props.handleChange(name, event )
    if (required) {      
      if (event !== '') {
        this.setState({ error: false })
        this.props.handleError(name, false)
      }
    }
    //Ipad blur event does not pass the value, which makes the component to be in error even when a value is selected
    //The following statement will reset the error state of the component
    setTimeout(() => { this.resetInputValidation(name, event); }, 150)
  }

  // handleInputSelectChange = (name, selectedOption) => (event) => {
  //   const { required, cities } = this.state;
  //   this.setState({ value: event, selected: true });

  //   if (this.props.handleInputChange !== undefined ){
  //     this.props.handleInputChange(name, event )
  //     if(name === 'city' && cities !== undefined && cities[cities.length-1]!==undefined){
  //       let updatedCity = cities;
  //       let otherCity = updatedCity[cities.length-1]
  //       otherCity.label = event
  //       updatedCity[cities.length-1] = otherCity
  //       this.setState({ cities: updatedCity })
  //     }
  //   }
  // }
 
  filterCountries = (inputValue, onUSA) => {
    let { countries } = this.state;
   if (countries.length === 0) {
      const newCountries = GetReferenceCountries()
      this.setState({ countries: newCountries });
      countries = newCountries
    }    
    let findUSA = countries.find(o=>o.value === 'USA');
    let indexUSA = countries.indexOf(findUSA);
    let findUMI = countries.find(o=>o.value === 'UMI');
    let indexUMI = countries.indexOf(findUMI);
    if(this.props.onUSA === true){
    countries.splice(indexUSA,1);
    countries.splice(indexUMI,1);
    } else {
      this.setState({onUSA:false})
    }
    // console.log('countgries length ' + countries.length)
    
    return countries.filter(i => 
        i.value.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.countryDescription.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  
  countryOptions = (inputValue, onUSA) =>      
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterCountries(inputValue,onUSA));
    }, 1000);
  });

  filterCurrencies = (inputValue) => {
    let { currencies } = this.state

    if (currencies.length === 0) {
      const newCurrencies = GetReferenceCurrencies()
      this.setState({ currencies: newCurrencies });
      currencies = newCurrencies
    }    
    return currencies.filter(i =>        
        i.countryDescription.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.currencyName.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.value.toLowerCase().includes(inputValue.toLowerCase()) ||
        (i.currencyCode !== undefined && i.currencyCode.toLowerCase().includes(inputValue.toLowerCase()))        
    );
  };
  
  currencyOptions = inputValue =>      
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterCurrencies(inputValue));
      }, 1000);
  });
  
  filterStates = (inputValue) => {
    let { states } = this.state
    let newStates;
    // console.log("in filterstates " + inputValue)
    if (states.length === 0) {
      if (this.props.inUnitedStates === true) {
        newStates = GetReferenceFilteredStates('USA')  
        // this.setState({ states: GetReferenceFilteredStates('USA') })
      } else {
        newStates = GetReferenceStates()  
        // this.setState({ states: GetReferenceStates() })
      } 
      //  const newStates = GetReferenceStates()
       this.setState({ states: newStates });
       states = newStates
     } 

    // console.log("in filterstates " + this.props.inUnitedStates)
    // if (this.props.inUnitedStates === true) {    
    //  return states.filter(i =>        
    //     (i.stateDescription !== undefined && i.stateDescription.toLowerCase().includes(inputValue.toLowerCase())) &&      
    //     (i.value.toLowerCase().includes(inputValue.toLowerCase()) && (i.countryCode.toLowerCase() === this.props.filter.toLowerCase()))       
    //   ); 
    //  } else {
       return states.filter(i =>        
        ((i.stateDescription !== undefined && i.stateDescription.toLowerCase().includes(inputValue.toLowerCase())) ||     
        (i.value.toLowerCase().includes(inputValue.toLowerCase()))) );
    //  }      
  };
  
  stateOptions = inputValue =>      
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterStates(inputValue));
      }, 1000);
  });

  filterCities = (inputValue) => {
    const { cities } = this.state
    // console.log('cities length ' + cities.length)
    if(cities !==undefined && cities.length >0) {
    return cities.filter(i =>        
      (i.label.toLowerCase().includes(inputValue.toLowerCase())));
    }
  };
  
  cityOptions = inputValue =>      
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterCities(inputValue));
      }, 2000);
  });

  filterOtherTypes = (inputValue) => {
    let { miTypes } = this.state
    
    return miTypes.filter(i =>        
      (i.otherTypeDescription.toLowerCase().includes(inputValue.toLowerCase())) ||     
      (i.value.toLowerCase().includes(inputValue.toLowerCase())));           
  };
  
  otherTypeOptions = inputValue =>      
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterOtherTypes(inputValue));
      }, 1000);
  });

  noOptionsMessage = (inputValue) => {
    return (
     <Typography>some text</Typography>
    );
  }

  render = () => {    
    const { classes, required } = this.props
    const { optionsType, name, label, value, error, errorText, summary, selected, uniqId } = this.state

    const redstar = <label className={classes.redstar} data-shrink='true'>*</label>
    const reqlabel = [label, redstar]
    
    return (
      <Grid>
          <Grid className={classes.labelRow}>
            {selected && <label className={error? classes.selectLableerror : classes.selectLable} data-shrink='true'>{label} </label>}
            {selected && required && <label className={classes.redstar} data-shrink='true'>*</label>}
          </Grid>
          <AsyncSelect
            multiline            
            className={(optionsType === 'country' || optionsType === 'state' || optionsType === 'city') && selected? classes.countryInputSelected : 
                       ((optionsType === 'currency' || optionsType === 'miType') && selected)? classes.currencyInputSelected :
                        optionsType === 'country' || optionsType === 'state' || optionsType === 'city'? classes.countryInput : classes.currencyInput}
            cacheOptions
            loadOptions={optionsType === 'country'? this.countryOptions : optionsType === 'currency'? this.currencyOptions  : 
                                          optionsType === 'miType'? this.otherTypeOptions : optionsType === 'state'? this.stateOptions : this.cityOptions}
            defaultOptions={optionsType === 'state'? this.state.states : optionsType === 'city'? this.state.cities : true}
            onFocus={this.handleClick(name)}         
            onChange={this.handleSelectChange(name, value)}
            // onInputChange={this.handleInputSelectChange(name, value)}
            onBlur={this.validateInput(name, value)}
            isDisabled={summary}
            isLoading={this.state.isLoading || this.props.isLoading}                    
            isClearable={summary? false : true}
            isRtl={false}
            isSearchable={true}
            hideSelectedOptions={true}
            inputId ={uniqId}
            name={name}
            placeholder={error? errorText : required? reqlabel : label}
            aria-label = {label}
            value={value}
            components={{
              IndicatorSeparator: () => null,          
              ClearIndicator: () => null,
              NoOptionsMessage: () => <Typography className={classes.noOptions}>{this.state.isLoading? 'Please wait. Loading....' : 'No Options'}</Typography>
              // DropdownIndicator: () => null
            }}
            styles={{ control: base => ({ ...base, borderWidth: '0 0 1px 0', borderRadius: '0', backgroundColor: 'inherit', 
                                                   borderColor: error? '#9C2F36' : '#0000006b' }),
                      placeholder: base => ({ ...base, color: error? '#9C2F36' : '#525252', }),
                      singleValue: base => ({ ...base, color: summary? '#000000' : '#333333', paddingLeft: '0px' }),
                      valueContainer: base => ({ ...base, paddingLeft: '0px', }), 
                    }}
          />
      </Grid> 
    );
  }
}
export default withStyles(styles) (AsyncReactSelect);