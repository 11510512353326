import React, { Component } from 'react';
import { Route, Prompt } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
// import { loadReCaptcha } from 'react-recaptcha-v3'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
// import reCAPTCHA from 'react-google-recaptcha'
import Header from './container/Header';
import Footer from './container/Footer';
import ProgressBar from './container/ProgressBar';
import ShowConfirmation from './container/ShowConfirmation';
import Landing from './component/Landing';
import Help from './component/Help'
import ReactPolling from './component/errorHandling/ReactPolling';
import UnavailableBanner from './component/errorHandling/UnavailableBanner';
import ErrorBoundary from './component/errorHandling/ErrorBoundary';

// const styles = theme => console.log(theme) || ({
const styles = theme => ({  
  root: {
    flexgrow: 1,
    maxWidth: "100%",
    // overflowX: "hidden",
  },
})

class App extends Component {
  constructor(props) {
    super(props);    
    this.state = {isStartDisabled: false, formIsHalfFilledOut: false};    
  }

  // componentDidMount() {
  //   let recaptchasitekey;
  //   if (process.env.NODE_ENV === 'development') {
  //     recaptchasitekey = process.env.REACT_APP_RECAP_SITE_KEY;
  //   } else {    
  //     var req = new XMLHttpRequest();
  //     req.open('GET', document.location, false);
  //     req.send(null);
  //     var headers = req.getAllResponseHeaders();
  //     var splitted = headers.split('\r\n');      
  //     let obj = splitted.find(x => x.startsWith('referer_first_let'));
  //     recaptchasitekey = obj.substring(19);
  //   }
  //   console.log('recaptcah site key ' + recaptchasitekey)
  //   loadReCaptcha(recaptchasitekey, this.loadCallback);
  //   this.setState({ recaptchasitekey: recaptchasitekey })
  // }

  // loadCallback() {
  //   console.log("ReCaptcha loaded")
  // }

  getRecapthcaSiteKeyFromProxy = () => {    
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      return process.env.REACT_APP_RECAP_SITE_KEY;
    } else {           
      var req = new XMLHttpRequest();
      req.open('GET', document.location, false);
      req.send(null);
      var headers = req.getAllResponseHeaders();
      var splitted = headers.split('\r\n');  
      // console.log('split ' + JSON.stringify(splitted));      
      let obj = splitted.find(x => x.startsWith('referer_first_let'));
      let token = obj.substring(19);
      return token;
    }
  }
  getRecapthcaApiKeyFromProxy = () => {    
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      return process.env.REACT_APP_RECAP_API_KEY;
    } else {           
      var req = new XMLHttpRequest();
      req.open('GET', document.location, false);
      req.send(null);
      var headers = req.getAllResponseHeaders();
      var splitted = headers.split('\r\n');  
      // console.log('splitapikey ' + JSON.stringify(splitted));      
      let obj = splitted.find(x => x.startsWith('referer_sunk_let'));
      let token = obj.substring(18);
      console.log('splitapikey ' + JSON.stringify(token));
      return token;
    }
  }
  getRecapthcaProjectIdFromProxy = () => {    
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      return process.env.REACT_APP_RECAP_PROJECT_ID;
    } else {           
      var req = new XMLHttpRequest();
      req.open('GET', document.location, false);
      req.send(null);
      var headers = req.getAllResponseHeaders();
      var splitted = headers.split('\r\n');  
      // console.log('splitprojid ' + JSON.stringify(splitted));      
      let obj = splitted.find(x => x.startsWith('referer_pair_let'));
      let token = obj.substring(18);
      console.log('splitprojid ' + JSON.stringify(token));
      return token;
    }
  }

  reCaptchaCallback = (token) => {
    // Here you will get the final recaptchaToken!!!  
    console.log(JSON.stringify(token), "<= your recaptcha token")
    this.setState({ recaptchaToken: token })

    const PROJECT_ID = this.getRecapthcaProjectIdFromProxy()
    const API_KEY = this.getRecapthcaApiKeyFromProxy()
    const request = {
      "event": {
        "token": token,
        "siteKey": this.getRecapthcaSiteKeyFromProxy(),
        "expectedAction": "ecmir_verify"
      }
    }

    // fetch('/siteverify?secret='+this.getRecapthcaSecretKeyFromProxy()+'&response='+token, { 
    fetch('/assessments/v1/projects/'+PROJECT_ID+'/assessments?key='+API_KEY, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'        
      },
      body: JSON.stringify(request)
    })
    .then(response => response.json())
    .then(result => {
        let jsonresult = JSON.stringify(result)
        console.log('verify response ' + jsonresult)

        if (result.success) {          
          this.setState({ recaptchaTokenVerify: true })
        }
    })
  }

  formCompleted = (status) => {
    this.setState({ formIsHalfFilledOut: status })
  }

  onSuccess(){
    this.setState({isStartDisabled: false}); 
    // console.log("App - Success");
  }
  onFailure(){
    this.setState({isStartDisabled: true});
    console.log("Application - Failure in connection with service") 
  }
  
  render() {    
    const { classes } = this.props
    const { isStartDisabled, formIsHalfFilledOut } = this.state    

    return (     
      <Grid container className={classes.root} spacing={0} direction="column" justify="center" alignItems="inherit">
        
        <Header />
        
        <ReactPolling url= {'/provisionalCMIR/echo'} interval= {60000} retryCount={1} method={'GET'}
            onSuccess={() => { this.onSuccess(); return true;}} onFailure={() => {this.onFailure();}} 
            render={() => { return null; }}
        />
        
        {/* {console.log("App.js - isStartDisabled value: "+ this.state.isStartDisabled)} */}
        {this.state.isStartDisabled && <UnavailableBanner/> }
        
        <ErrorBoundary>
          <switch>
            <Prompt
              when={formIsHalfFilledOut}              
              message="Are you sure you want to leave? All of the entered data will be lost."              
            />
            <Route exact path="/status" render={() => {window.location.href="status.html"}} />
            <Route exact path="/help" component={Help} />
            <Route exact path='/' render={props => <Landing {...props} formCompleted={this.formCompleted} isStartDisabled={isStartDisabled} recaptchaToken={this.recaptchaToken} />}/>            
            <Route exact path='/form' render={props => <ProgressBar {...props} unMount={this.unMount} formCompleted={this.formCompleted}/>} />
            <Route exact path='/confirm' component={ShowConfirmation} />
          </switch>
        </ErrorBoundary>
        
        <Footer />
        <GoogleReCaptchaProvider
            reCaptchaKey={this.getRecapthcaSiteKeyFromProxy()}
            useEnterprise="true">
            <GoogleReCaptcha
              action="ecmir_verify"
              onVerify={this.reCaptchaCallback}
            />
        </GoogleReCaptchaProvider>
        {/* <ReCaptcha
            ref={ref => this.recaptcha = ref}
            sitekey={this.getRecapthcaSiteKeyFromProxy()}
            action='create_ecmir'
            verifyCallback={this.reCaptchaCallback}
            // render='explicit' 
            // size= 'compact'
        /> */}

      </Grid>
    );
  }
}

export default withStyles(styles)(App);