import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { TextField, Typography, Grid, Button, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Popup from 'reactjs-popup';
import logo from '../images/check.png';
import Needhelp from '../images/needhelp.jpg'
import NumberFormat from 'react-number-format';
import { SendEmail, downloadReceipt } from '../service/Receipt';
// import {QRCodeCanvas} from "qrcode.react";




const styles = theme => ({
  root: {
    marginBottom: '16px',
    minWidth: '300px'
  },
  paper: {
    textAlign: 'left',
    justify: 'center',
    color: theme.palette.text.primary,
    backgroundColor: '#cfcfcf',
    borderRadius: '12px',
    [theme.breakpoints.down('xs')]: {
      width: 412,
    },
    [theme.breakpoints.up('sm')]: {
      width: 616,
    },
    [theme.breakpoints.up('md')]: {
      width: 616,
    },
    [theme.breakpoints.up('lg')]: {
      width: 796,
    },
  },
  subPaper: {
    width: 462,
    textAlign: 'left',
    justify: 'center',
    color: theme.palette.text.secondaryHeading,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    overflowWrap: 'break-word',
    [theme.breakpoints.down('xs')]: { 
      width: 336,
    },  
  },
  mainTitle: {
    color: '#003366',
    fontWeight: 'bold',
    margin: '16px 0 2px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(24),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  subTitle: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    margin: '0px 0 32px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(22),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  stepLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
  },
  heading: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: theme.typography.pxToRem(20),
    }, 
  },
  subheading: {
    fontSize: theme.typography.pxToRem(16),
    paddingTop: 8,
    [theme.breakpoints.down('xs')]: { 
      fontSize: theme.typography.pxToRem(14),
      paddingLeft: 8,
    },
  },
  link: {
    margin: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),    
    width: 200,
    [theme.breakpoints.down('xs')]: { 
      marginBottom: theme.spacing(1),
    },  
  },
  buttonBar: {
    height: 100,
    [theme.breakpoints.down('xs')]: { 
      height: 166,
    },  
  },
  modal: {
     fontSize: theme.typography.pxToRem(16),
     backgroundColor: 'white',
  },
  modalHeader: {
    fontSize: theme.typography.pxToRem(24),
    textAlign: 'center',
  },
  modalContent: {
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
  },
  modalClose: {
    cursor: 'pointer',
    display: 'block',
    lineHeight: theme.typography.pxToRem(20),
    fontSize: theme.typography.pxToRem(22),
    backgroundColor: '#ffffff00',
    // borderRadius: theme.typography.pxToRem(18),    
    position: 'absolute',    
    padding: '2px 5px',    
    right: '0px',
    top: '-1px',        
    // border: '1px solid #cfcece',
  },
  emailField: {
    width: theme.spacing(51),
  },
  topheader: {
    padding: '6px 33px 7px 40px',
  },
  currencytext: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 15,
    },     
  },
  currencytext1: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 0,
    },
  },   
  needHelpImage: {    
    marginTop: '6px',    
  },  
  needhelpText: {
    marginLeft: '0px',
    fontWeight: 'bold',
    color: '#3440E4',
    textDecoration: 'none',
    textTransform: 'none'
  }, 
  topPadding: {    
    paddingTop: '20px',    
  },
  imageCheck: {
    height: '36px',
    width: '36px',
    marginRight: '0.5em',
    [theme.breakpoints.down('xs')]: { 
      height: '20px',
      width: '20px',
    },  
  },
  info: {
    width: 456, 
    marginLeft: 12,
    marginTop: 8,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: { 
      width: 336,
      marginRight: 8,
      fontSize: 12,
    },  
  },
  info2: {
    fontWeight: 'bold',
    color: '#7c0223',
    [theme.breakpoints.down('xs')]: { 
      width: 336,
      marginRight: 8,
      fontSize: 12,
    }, 
  },
  

  dateTime: {
    [theme.breakpoints.down('xs')]: {       
      fontSize: 13,
    },  
  }
});

class Confirmation extends Component {
  constructor(props) {
    super(props);    
    this.state = {receipt: props.receipt, email: '', modalopen: true , errorText: '', isValidEmail: false};
  }

  static getDerivedStateFromProps(nextProps, prevState) {  
    if(nextProps.receipt !== prevState.receipt) {
      return { receipt: nextProps.receipt }
    } return null;        
  }

  

  handleChange = (name) => (event) => {  
    this.setState({ [name]: event.target.value }) 
    let emailpattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/  
      if (emailpattern.test((event.target.value))===false){
        this.setState({isValidEmail:false,errorText:"Please enter a valid email Address"})
      } else {
        this.setState({errorText:''})
        this.setState({isValidEmail:true})
      }
              
  };

  startOver(event) {
    this.props.resetStep();
    this.props.resetStart();
    this.props.resetPerson();
    this.props.resetAddress();
    this.props.resetExportImport();
    this.props.resetMailShip();
    this.props.resetThirdParty();
    this.props.resetCurrencyItem();
    this.props.resetCoinItem();
    this.props.resetOtherItem();
    this.props.resetReviewSign();
    this.props.resetReceipt();
    
    this.props.history.push('/');
  }

  modalOpen() {
    this.setState({ modalopen: true })
  }
  
  
  emailConfirmation() {
    const { receipt, email } = this.state;
    //const qrCodeCanvas = document.querySelector('canvas');
    //const qrCodeDataUri = qrCodeCanvas.toDataURL('image/png');
    const newReceipt = {
      ...receipt,
      //sigQrBase64:qrCodeDataUri
    };
    SendEmail(newReceipt, email);
    
    this.setState({ modalopen: false })
  }

  printReceipt() {
    var css = '@page { size: landscape; } @media print {body {-webkit-print-color-adjust: exact;}}',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet){
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);

    window.print();
  }

  downloadPDF() {
    const { receipt } = this.state;
    //const qrCodeCanvas = document.querySelector('canvas');
    //const qrCodeDataUri = qrCodeCanvas.toDataURL('image/png');
    const newReceipt = {
      ...receipt,
      //sigQrBase64:qrCodeDataUri
    };
    this.setState ({receipt:newReceipt });
    downloadReceipt(newReceipt);
    
  }


  render = () => {
    const { classes } = this.props
    const { receipt, email, modalopen, errorText,isValidEmail} = this.state
   
    
    return (
    	<Grid className={classes.root} >
        <Grid container className={classes.topheader} alignItems="center" direction="row" justifyContent="space-between">
        <Grid item>
            <Grid container alignItems={"flex-start"} direction={"row"} justifyContent={"flex-start"}>
              <Grid className={classes.currencytext} >FinCEN Form 105</Grid>              
              <Grid className={classes.currencytext1} ><span>&nbsp;</span>| Currency and Monetary Instrument Report (CMIR)</Grid>
            </Grid>  
          </Grid>
          <Grid item>
            <NavLink to="/help" target="help" rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
              <Grid container alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                <img src={Needhelp} alt="needhelp" style={{cursor: 'pointer'}}/>
                <Button autoCapitalize="false" className={classes.needhelpText}  align="center">Need Help?</Button>
              </Grid>
            </NavLink>
          </Grid>
        </Grid>

        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography className={classes.mainTitle} >Report of International Transportation of Currency or Monetary Instruments</Typography>
          <Typography className={classes.subTitle} gutterBottom>Department of Treasury Financial Crimes Enforcement Network</Typography>
        </Grid>

        <Grid id="receiptPage" container direction="row" justifyContent="center" alignItems="center">


          <Grid className={classes.paper} >
            <Grid className={classes.topPadding} container direction="row" justifyContent="center" alignItems="center">
              <img className={classes.imageCheck} src={logo} alt="Logo"/>
              <Typography className={classes.heading} >Confirmation Receipt</Typography>
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Typography className={classes.subheading}>Financial Crimes Enforcement Network (FinCEN) - Form 105</Typography>
            </Grid>

            <br/>

            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Grid container direction="column" justifyContent="center" alignItems="center">                  

                  {[1].map(value => (
                      <Grid className={classes.subPaper}>
                        {receipt.currencyTransporter === 'Y' &&
                        <Typography align='left' style={{marginLeft: 86 + 'px', marginTop: 12 + 'px'}}>Professional Currency Transporter</Typography>
                        }
                        {receipt.currencyTransporter === 'Y' &&
                        <Typography align='left' style={{marginLeft: 114 + 'px'}}>(or) Armored Car Company</Typography>
                        }
                        {(receipt.workflow === 'M' && !receipt.currencyTransporter === 'Y') &&
                        <Typography align='left' style={{marginLeft: 160 + 'px', marginTop: 12 + 'px'}}>Mailed (or) Shipped</Typography>
                        }
                        
                        {receipt.business === '' &&
                        <Typography align='left' style={{marginLeft: 14 + 'px', marginTop: 12 + 'px', marginRight: 14 + 'px'}}>
                          {receipt.currencyTransporter === 'Y'? 'Personal Currency Carrier: ' : "Traveler's Name: "}<b>{receipt.firstName} {receipt.lastName}</b> 
                        </Typography>
                        }
                        {receipt.business !== '' &&
                        <Typography align='left' style={{marginLeft: 14 + 'px', marginTop: 12 + 'px'}}>
                          {receipt.currencyTransporter === 'Y'? 'Business Currency Carrier: ' : "Business Name: "}<b>{receipt.business}</b> 
                        </Typography>
                        }
                        <Typography align='left' style={{marginLeft: 14 + 'px'}}>Confirmation Number: <b>{receipt.confirmationNumber}</b> </Typography>
                        
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{marginLeft: 14 + 'px'}}>
                          <Typography>Total Amount (USD): 
                            <b><NumberFormat value={Math.round(receipt.totalUSD)} isNumericString={false} displayType={'text'} 
                                decimalScale={0} maximumfractiondigits={0} minimumfractiondigits= {0}  thousandSeparator={true} prefix={'$'} /></b>
                          </Typography>   
                        </Grid> 
                                                                    
                        <Typography className={classes.dateTime} align='left' style={{marginLeft: 14 + 'px'}} >Application Date and Time: <b>{receipt.submissionDate}</b></Typography>
                        <Typography className={classes.dateTime} align='left' style={{marginLeft: 14 + 'px', marginBottom: 12 + 'px'}} >Expiration Date and Time: <b>{receipt.expireDate}</b></Typography> 
                                                
                        {/* { <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{marginLeft: 14 + 'px', marginBottom: 14 + 'px'}}> */}
                          {/* <Typography style={{marginRight: 14 + 'px'}}>Please present this QR Code: </Typography> */}
                          {/* <QRCodeCanvas size='50' style={{height: 50 + 'px', width: 50 + 'px'}} value={receipt.confirmationNumber} /> */}
                        {/* </Grid>  } */}
                     
                      </Grid>
                  ))}

                  {[0].map(value => (
                      <Grid align='center' className={classes.info}>
                       <Typography><span style={{fontWeight: 'bold'}}>Save your Confirmation Receipt</span> and present</Typography>
                       <Typography>it to a CBP Officer at the Port of Entry upon</Typography>
                       <Typography>arrival or departure.</Typography>
                       <Typography> You can also <span style={{fontWeight: 'bold'}}>Email, Print, or Download</span> the confirmation receipt by clicking the buttons below.</Typography>  
                       <Typography  className={classes.info2}>Submission of this provisional CMIR form does not</Typography>
                        <Typography className={classes.info2}>end your responsibility. You MUST present yourself</Typography>
                        <Typography className={classes.info2}>to a CBP Officer at a Port of Entry and tell them that</Typography>
                        <Typography className={classes.info2}> you submitted a provisional CMIR in order to </Typography>
                        <Typography className={classes.info2}> complete the process.</Typography>                               
                        <Typography>Check locations here:</Typography>
                        <Typography> <a target='ports' href={'https://www.cbp.gov/contact/ports'} className={classes.link} underline="always" style={{color: '#1E1E1E'}}>
                          https://www.cbp.gov/contact/ports
                        </a></Typography>
                        <br/> <br/>
                       <Typography>Contact the Port of Entry for additional guidance and instructions if you are unsure where to go.</Typography> 
                      </Grid>
                  ))}

            

                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

        <Grid className={classes.buttonBar} container direction="row" justify="center" alignItems="center">
          <Popup trigger={<Button variant="outlined" color="secondary" onClick={() => this.startOver()} className={classes.button}>New Application</Button>}
            modal closeOnDocumentClick>
            {close => (
              <Grid className={classes.modal}>
                 {/* eslint-disable-next-line */}
                <a className={classes.modalClose} onClick={close}>
                  &times;
                </a>
                <Grid className={classes.modalHeader}>Are you sure you want to leave the receipt page?</Grid>
                <hr/>
                <Grid className={classes.modalContent}>Your receipt will not be saved</Grid>
                <br/>
                <Grid>
                  <Button variant="outlined" color="secondary" onClick={() => {close()}} className={classes.button}>Back to Receipt</Button>
                  <Button variant="contained" color="primary" onClick={() => this.startOver()} className={classes.button}>New Application</Button>
                </Grid>
              </Grid>
            )}
          </Popup>

          <Popup trigger = {<Button id="emailBtn" variant="contained" color="primary" className={classes.button}>Email</Button>} 
            modal closeOnDocumentClick >
            {close => ( 
              <Grid className={classes.modal}>
                {/* eslint-disable-next-line */}
                <a className={classes.modalClose} onClick={close}>
                  &times;
                </a>
                <Grid className={classes.modalHeader}>Email the Confirmation Receipt</Grid>
                <hr/>
                {modalopen === true && <Grid className={classes.modalContent} container direction="row" justify="center" alignItems="center">
                    <TextField
                        autoFocus
                        id="email"
                        label="Email Address"
                      className={classes.emailField}
                        placeholder="youremail@domain.com"
                        helperText={errorText}
                        value={email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                </Grid>
                }
                {modalopen === false &&
                  <Grid  container direction="column" justify="center" alignItems="center"> 
                    <Typography>Confirmation Email send to</Typography>
                    <Typography>{email}</Typography>
                  </Grid>
                }
               
                <Grid  container direction="row" justify="flex-end" alignItems="center">
                  <Button variant="outlined" color="secondary" onClick={() => { this.modalOpen(); close(); }} 
                                className={classes.button}>{modalopen === true? 'Cancel' : 'Close'}</Button>
                  {modalopen === true && <Button variant="contained" color="primary"  disabled= {isValidEmail?false:true} onClick={() => {this.emailConfirmation()} } className={classes.button}>Send</Button>}
                </Grid>
              </Grid>
            )}
          </Popup>

          <Button variant="contained" color="primary" onClick={() => this.printReceipt()} className={classes.button}>
            Print
          </Button>

          <Button variant="contained" color="primary" onClick={() => this.downloadPDF()} className={classes.button}>
            Download
          </Button>
        </Grid>

      </Grid>
    );
  }
}

export default withStyles(styles) (Confirmation);
