import React, { Component } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { GetReferenceCurrencies } from '../service/ReferenceData';
import MDTextField from './custom/MDTextField';

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(0.5),
  },    
  textField: {
    '& label': {
      color: 'grey'
    },
    "& label span": {
      color: "red"
    },    
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(14.5)
  },
  textFieldReadOnly: {
    "& label": {
      color: "grey"
    },
    "& label span": {
      color: "red"
    },  
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(14.5),
    textAlign: "center",
  },
  selectInput: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
    width: theme.spacing(27),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 435
  },
  input: {
    display: 'flex',
    padding: 0,
    "& label": {
      color: "grey"
    },
    "& label span": {
      color: "red"
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '10px',
    width: theme.spacing(36)
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 12,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  iconButton: {
    color: '#d24214'
  },
  staticText: {
    fontSize: 16,
  },
  largeWide: {
    width: 300,
    marginLeft: '8px',
    [theme.breakpoints.down('xs')]: {
      width: 92,
    },    
  },
  mediumWide: {
    width: 148,
    marginLeft: '8px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },  
  },
  mediumWide2: {
    width: 148,
    marginLeft: '8px',
    [theme.breakpoints.down('xs')]: {
      width: 64
    },  
  },
  smallWide: {
    width: 114,
    marginLeft: '4px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },  
  },
  smallWide2: {
    width: 114,
    marginLeft: '4px',
    [theme.breakpoints.down('xs')]: {
      width: 42
    },  
  },
  divider: {
    marginTop: '28px',
    backgroundColor: "#e2e2e2",
    height: '2px'
  },
})

class CurrencyItem extends Component {
  constructor(props) {
    super(props);
    this.state = { activeStep: props.activeStep, type: props.type, currency: props.currency, summary: props.summary, countries: [], error:false, errorText:'' };
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('in curitem ' + JSON.stringify(nextProps.activeStep + ' ' + JSON.stringify(prevState.activeStep)))
    if(nextProps.activeStep === 0 && nextProps.type === prevState.type && nextProps.currency !== prevState.currency) {
      // console.log('in curitem1 ' + JSON.stringify(nextProps.type + ' ' + JSON.stringify(prevState.type)))
      return { activeStep: nextProps.activeStep, summary: nextProps.summary, currency: nextProps.currency }
    } else   
    if(nextProps.summary !== prevState.summary) {
      // console.log('in curitem2 ' + JSON.stringify(nextProps.activeStep + ' ' + JSON.stringify(prevState.activeStep)))
      return { activeStep: nextProps.activeStep, summary: nextProps.summary }
    } else 
    if(nextProps.currency.currencyName !== '' && nextProps.currency !== prevState.currency && nextProps.activeStep !== prevState.activeStep) {      
        return { activeStep: nextProps.activeStep, currency: nextProps.currency }      
    } else 
    // console.log('in curitem3 ' + JSON.stringify(nextProps.activeStep + ' ' + JSON.stringify(prevState.activeStep)))
      return { activeStep: nextProps.activeStep }
  }

  handleSelectChange = (name, selectedOption) => {
    // console.log(name + " handleSelectChange " + JSON.stringify(selectedOption))    
    const { currency } = this.state;
    let newCurrency;
    
    if (name === 'otherType') {
      newCurrency = {
        ...currency,
        otherType: selectedOption
      }
    } else {
      newCurrency = {
        ...currency,
        country: selectedOption,
        // otherType: selectedOption === null? '' : selectedOption.type,
        exchangeRate: selectedOption === null? '' : selectedOption.exchangeRate,
        currencyName: selectedOption === null? '' : selectedOption.currencyName,
        currencySymbol: selectedOption === null? '' : selectedOption.currencySymbol,
        currencyCode: selectedOption === null? '' : selectedOption.currencyCode,
        usdEquivalent: selectedOption === null? '' : currency.amount * selectedOption.exchangeRate
      };      
    }
    this.setState({ currency: newCurrency });
    this.props.updateCurrency({...newCurrency, country: newCurrency.country}, this.props.index, this.props.type)
  }
  
  handleChange = (propertyName, value) => {    
    if (propertyName === 'amount') {
      const { currency } = this.state;
      let currencyerror = true;
      let newCurrency = {
        ...currency,      
        amount: value,
        usdEquivalent: value >= 1? value * currency.exchangeRate : 0
      };

      let isnumberpattern = /^\d+$/
      if(newCurrency.amount !== null && newCurrency.amount !== undefined) {
        if (!(isnumberpattern.test(newCurrency.amount))) {
          this.setState({error: true, errorText:"Please enter a numeric value"})
          this.setState({usdEquivalent: 0})
        } else if ((newCurrency.amount > 2147483647) || ((value * newCurrency.exchangeRate) > 2147483647)) {
          this.setState({error: true, errorText:"Maximum allowed amount exceeded"})
          this.setState({usdEquivalent: 0})
          newCurrency = { ...newCurrency, usdEquivalent: 0}
          currencyerror = false
        } else {
          //resetting error and errorText
          this.setState({error:false, errorText:''})
          this.setState({usdEquivalent: value * currency.exchangeRate})
          currencyerror = true
          
        }
    
      }

      this.setState({ currency: newCurrency });    
      this.props.updateCurrency({...newCurrency, country: newCurrency.country}, currencyerror, this.props.type)
    }
  }

  handleRemove = (id) => {
    this.props.updateCurrency('', this.props.index, this.props.type, 'DEL')
    this.props.onClick(id)
  }

  filterCountries = (inputValue) => {
    let { countries } = this.state

    if (countries.length === 0) {
      const newCountries = GetReferenceCurrencies()
      this.setState({ countries: newCountries });
      countries = newCountries
    }    
    return countries.filter(i =>        
        i.countryDescription.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.currencyName.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.value.toLowerCase().includes(inputValue.toLowerCase()) ||
        (i.currencyCode !== undefined && i.currencyCode.toLowerCase().includes(inputValue.toLowerCase()))        
    );
  };
  
  promiseOptions = inputValue =>      
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterCountries(inputValue));
      }, 1000);
  });

  handleError = (propertyName, error) => {
    // No logic needed here.  The required check
    // is done in the Currencies class
  }
  
  render = () => {    
    const { classes, locator } = this.props
    const { currency, summary, error, errorText } = this.state

    return (   
      <Grid>        
        <Grid className={classes.root} container direction="row" justifyContent="flex-start" alignItems="flex-end">        

            <Grid item className={classes.mediumWide}>{currency.type !== undefined && <Typography className={classes.staticText} id="curtype">{currency.type.otherTypeDescription}</Typography>}</Grid>
            <Grid item className={classes.largeWide}>{currency.country !== undefined && <Typography className={classes.staticText} id="curcountry">{currency.country.label}</Typography>}</Grid>
                      
            <Grid item className={classes.mediumWide2}>
            <MDTextField name='amount'  value={(currency.amount).toLocaleString(undefined, { minimumfractiondigits: 2 })} 
                error = {error} errorText = {errorText} handleChange={this.handleChange} handleError={this.handleError}
                required={true} disabled={summary? true : false} currency={true} summary={summary} inputProps={{maxLength: 10, id:locator==='Review'?'amount'+locator:'amount'}} />
            </Grid>    

            <Grid item className={classes.smallWide}><Typography className={classes.staticText} id="curexrate" align="center">{currency.exchangeRate}</Typography></Grid>
            <Grid item className={classes.smallWide2}>
              <Typography className={classes.staticText} id="curusdeqiv" align="right">
              {(currency.usdEquivalent).toLocaleString('en', { style: 'currency', currency: 'USD', useGrouping: true, maximumfractiondigits:0, minimumfractiondigits: 0 })}
              </Typography>
            </Grid>
           
            
        </Grid>
        <Divider className={classes.divider} variant="fullWidth" />
      
      </Grid>   
    );
  }
}

export default withStyles(styles) (CurrencyItem);