const person = (state = initialPerson, action) => {
    switch (action.type) {
      case 'RESET_PERSON':
        return initialPerson
      case 'UPDATE_PERSON':
        // state = action.person
        // let newState = { ...state, ...action.person };
        return action.person
      case 'GET_PERSON':
        return state
      default:
        return state    
    }
  }
  
  const initialPerson = {
      first: '',
      last: '',
      middle: '',
      business: '',
      dob: '',
      coc: '',
      passport: '',
      passportCountry: '',
      idnum: '',
      visaDate: '',
      visaIssued: '',
      alienNumber: '',
      email: '',
      passportreq: true,
      idreq: true,
      alienreq: true
  }

  export default person