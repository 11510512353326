import React from 'react';
import Flag from 'react-world-flags'

const exportImport = (state = initialExportImport, action) => {
    switch (action.type) {
      case 'RESET_EXPORTIMPORT':
        return initialExportImport
      case 'UPDATE_EXPORTIMPORT':        
        let newState = { ...state, ...action.exportImport };
        if (action.exportImport.exportOrImport === -1) {
          newState.exportOrImport = 0;
        }
        return newState
      default:
        return state    
    }
  }
  
  const initialExportImport = {
      exportOrImport: -1,
      arriveCountry: {
        value: 'USA',
        label: [<Flag code='USA' height="12" />, ' ',  'UNITED STATES'],      
        countryDescription: 'UNITED STATES'
      },
      departureCountry: '',
      departurePort: '',      
      arrivePort: '',      
  }

  export default exportImport