// Progress Bar
export const resetStep = () =>  ({
  type: 'RESET_STEP'
})
export const nextStep = () =>  ({
  type: 'NEXT_STEP'
})
export const previousStep = () =>  ({
  type: 'PREVIOUS_STEP'
})
export const updateStep = step =>  ({
  type: 'UPDATE_STEP',
  step
})

// Start
export const resetStart = () => ({
  type: 'RESET_START'
})
export const updateStart = start => ({
  type: 'UPDATE_START',
  start
})

// Personal Information
export const resetPerson = () => ({
  type: 'RESET_PERSON'
})
export const updatePerson = person => ({
  type: 'UPDATE_PERSON',
  person
})
export const resetAddress = () => ({
  type: 'RESET_ADDRESS'
})
export const updateAddress = addresses => ({
  type: 'UPDATE_ADDRESS',
  addresses
})

// Third Party Information
export const resetThirdParty = () => ({
  type: 'RESET_THIRDPARTY'
})
export const updateThirdParty = thirdparty => ({
  type: 'UPDATE_THIRDPARTY',
  thirdparty
})

// Export Import
export const resetExportImport = () => ({
  type: 'RESET_EXPORTIMPORT'
})
export const updateExportImport = exportImport => ({
  type: 'UPDATE_EXPORTIMPORT',
  exportImport
})

// Mail Ship
export const resetMailShip = () => ({
  type: 'RESET_MAILSHIP'
})
export const updateMailShip = (mailship, mailshipPerson) => ({
  type: 'UPDATE_MAILSHIP',
  mailship,
  mailshipPerson
})
export const resetShip = () => ({
  type: 'RESET_SHIP'
})
export const updateShip = (ship) => ({
  type: 'UPDATE_SHIP',
  ship
})
export const resetCarrier = () => ({
  type: 'RESET_CARRIER'
})
export const updateCarrier = (carrier) => ({
  type: 'UPDATE_CARRIER',
  carrier
})

// Currencies
export const resetCurrencyItem = () =>  ({
  type: 'RESET_CURRENCY_ITEM'
})
export const addCurrencyItem = () =>  ({
  type: 'ADD_CURRENCY_ITEM'
})
export const removeCurrencyItem = (id) =>  ({
  type: 'REMOVE_CURRENCY_ITEM',
  id
})
export const resetCoinItem = () =>  ({
  type: 'RESET_COIN_ITEM'
})
export const addCoinItem = () =>  ({
  type: 'ADD_COIN_ITEM'
})
export const removeCoinItem = (id) =>  ({
  type: 'REMOVE_COIN_ITEM',
  id
})
export const resetOtherItem = () =>  ({
  type: 'RESET_OTHER_ITEM'
})
export const addOtherItem = () =>  ({
  type: 'ADD_OTHER_ITEM'
})
export const removeOtherItem = (id) =>  ({
  type: 'REMOVE_OTHER_ITEM',
  id
})
export const updateCurrencies = currencies => ({
  type: 'UPDATE_CURRENCIES',
  currencies
})
export const updateCoins = coins => ({
  type: 'UPDATE_COINS',
  coins
})
export const updateOthers = others => ({
  type: 'UPDATE_OTHERS',
  others
})

// Review and Signature
export const resetReviewSign = () => ({
  type: 'RESET_REVIEW'
})
export const updateReviewSign = review => ({
  type: 'UPDATE_REVIEW',
  review
})

//Receipt
export const resetReceipt = () => ({
  type: 'RESET_RECEIPT'
})
export const updateReceipt = receipt => ({
  type: 'UPDATE_RECEIPT',
  receipt
})