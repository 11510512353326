const thirdparty = (state = initialThirdParty, action) => {
    switch (action.type) {
      case 'RESET_THIRDPARTY':
        return initialThirdParty
      case 'UPDATE_THIRDPARTY':
        return action.thirdparty
      case 'GET_PERSON':
        return state
      default:
        return state    
    }
  }
  
  const initialThirdParty = {
      first: '',
      last: '',
      middle: '',
      business: '',
      businessTypeOrProfession: '',
      isBank: '',      
  }

  export default thirdparty