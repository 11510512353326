const activeStep = (state = 0, action) => {
    switch (action.type) {
      case 'RESET_STEP':
        return 0
      case 'NEXT_STEP':
        return state + 1
      case 'PREVIOUS_STEP':
        return state - 1
        case 'UPDATE_STEP':
        return action.step  
      default:
        return state    
    }
  }

  export default activeStep