import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducers'

import './index.css';
import App from './App';

// import * as serviceWorker from './serviceWorker';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { lightBlue, blue } from '@material-ui/core/colors'

const store = createStore(rootReducer)

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
      },
    palette: {
      primary: blue,
      secondary: lightBlue
    },
    overrides: {
        MuiPaper: {
            root: {
                // width: '98%',
                // minWidth: '484px',
                maxWidth: '950px',
                backgroundColor: '#e2e2e2',                
                borderRadius: '12px',
            }
        },

        MuiFormControlLabel: {
            root: {
                paddingLeft: '24px',
                PaddingRight: '18px'
            }
        },

        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: '#3440E4'
                },
                "&$error": {
                    color: '#9C2F36'
                },
                '&$disabled': {
                    color:'#525252'
                },            
            },
            asterisk: {
                "&$error": {
                    color: '#9C2F36'
            },
            focused: {},
            error: {},
            disabled: {},
            asterisk:{}
        },  
        },

        MuiInput: {
            underline: {
               
                "&$error": {
                    '&:after': {
                   borderBottomColor: '#9C2F36'
                }
            }  
            },
            after: {},
            error: {}          
        },

        MuiFormHelperText: {
            root: {  
                color: '#9C2F36',   
                "&$error": {
                    color: '#9C2F36'
                }
            },         
            error: {}
        },


        MuiButton: {
            outlinedSecondary:{
                color: '#3939C8',
                borderColor: '#3939C8'
            },
            
            containedPrimary: {
                backgroundColor: '#3939C8'
            },

            textSecondary: {
                color: '#0028FF'
            }
            
        },

        MuiCheckbox: {
            colorSecondary: {
              "&$checked": {
                color: '#0040FF'
            }
        }
    },


        MuiExpansionPanel: {
            root: {
                margin: '8px 0',
                '&:before': {
                    left: 'auto'
                },
                '&$disabled': {
                    backgroundColor: '#ababab'      
                }    
            },
            rounded: {
                borderRadius: '12px',
                '&:last-child': {
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                },
            },
            // disabled: {
            //     backgroundColor: '#ababab'
            // }
        },
        MuiExpansionPanelDetails: {
            root: {
                display: 'block',
                justifyContent: 'center'
            }
        },
        MuiExpansionPanelSummary: {
            root: {               
                '&$disabled': {
                    opacity: '1.00'       
                }               
            },
        },
        MuiPrivateTabIndicator: {
            colorPrimary: {
                backgroundColor: '#ffffff00'
            }
        },
        MuiStepper: {
            root: {
                padding: '12px'
            }
        },
        MuiIconButton: {
            root: {
                padding: '1px'
            }            
        },
        MuiStepIcon: {
          root: {
            color: 'lightBlue',
            '&$active': {
                color: '#1769aa',
            },
            '&$completed': {
                color: 'green',
            },
          },          
        },

        MuiStepLabel: {
            iconContainer: {
                transform: 'scale(1.15)',
            },
            label: {
                color: '#3440E4',
                fontSize: '0.65rem',
                fontWeight: '550',
                '&$active': {
                    color: '#ffffff',
                    backgroundColor: '#1769aa',
                    fontSize: '0.75rem',
                    fontWeight: '580',
                    borderRadius: '100px',
                },
                '&$completed': {
                    color: 'green',
                    fontSize: '0.65rem',
                    fontWeight: '550',
                },               
            }
        },
        MuiStepConnector: {            
            horizontal: {
                // left: 'calc(-50% + 20px)'
            },
            lineHorizontal: {
                // width: '100%',
                borderTopWidth: '4px',
                // left: 'calc(-50% + 0px)'
            },
            alternativeLabel: {
                left: 'calc(-50% + 13px)',
                right: 'calc(50% + 13px)',
                // '&:first-child': {
                //     left: 'calc(-50% + 9px)',
                //     right: 'calc(50% + 9px)',
                // },
                '&:nth-child(5)': {
                    left: 'calc(-50% + 7px)',
                    right: 'calc(50% + 7px)',
                  }
            },
            line: {
                borderColor: 'lightBlue',
            },     
            active: {
                '& span': {
                    borderColor: '#1769aa',
                }
            },
            completed: {                
                '& span': {
                    borderColor: 'green',
                }
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#3939C8',               
            },
        },
        MuiPopover: {
            paper: {
                marginLeft: '20px',
                maxWidth: 'calc(100% - 70px)',
                minWidth: '16px'
            }
        }
      }
})

ReactDOM.render(
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <HashRouter>
                    <App />
                </HashRouter>
            </MuiThemeProvider>
        </Provider>,
document.getElementById('root'));

// module.hot.accept();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
