const review = (state=initialReview, action) => {
    switch (action.type) {
      case 'RESET_REVIEW':
        return initialReview
      case 'UPDATE_REVIEW':        
        let newState = { ...state, ...action.review };
        // console.log('newstate ' + JSON.stringify(newState))
        return newState
      default:
        return state    
    }
  }

  const initialReview = {
    sigImageBase64: '',
    signName: '',
    signTitle: '',
    signDate: '',
    declaration: false,
    acknowledgement: false
}

  export default review