import { connect } from 'react-redux'
import ReviewSign from '../component/ReviewSign'

import { updatePerson, updateAddress, updateExportImport, updateMailShip,
  updateCurrencies, updateCoins, updateOthers, updateThirdParty } from '../actions'

const mapStateToProps = state => ({
  activeStep: state.activeStep,
  start: state.start,
  person: state.person,
  thirdParty: state.thirdparty,
  addresses: state.addresses,
  exportImport: state.exportImport,
  carrier: state.carrier,
  mailship: state.mailship,
  ship: state.ship,
  currencies: state.currencies,
  coins: state.coins,
  others: state.others,
  review: state.review
})

const mapDispatchToProps = (dispatch) => ({
  updatePerson: (person) => dispatch(updatePerson(person)),
  updateThirdParty: (thirdParty) => dispatch(updateThirdParty(thirdParty)),
  updateAddress: (addresses) => dispatch(updateAddress(addresses)),
  updateExportImport: (exportImport) => dispatch(updateExportImport(exportImport)),
  updateMailShip: (mailship) => dispatch(updateMailShip(mailship)),
  updateCurrencies: (currencies) => dispatch(updateCurrencies(currencies)),
  updateCoins: (coins) => dispatch(updateCoins(coins)),
  updateOthers: (others) => dispatch(updateOthers(others)),
})

export default connect(mapStateToProps, mapDispatchToProps) (ReviewSign);
