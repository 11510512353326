import React from 'react';
import Flag from 'react-world-flags'
import getSymbolFromCurrency from 'currency-symbol-map'

export const getJWTTokenFromProxy = () => {
  // console.log('process ' + process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    return process.env.REACT_APP_JWT_TOKEN;
  } else {    
    var req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);
    var headers = req.getAllResponseHeaders();
    // console.log('headers ' + JSON.stringify(headers));      
    var splitted = headers.split('\r\n');  
    // console.log('split ' + JSON.stringify(splitted));      
    let obj = splitted.find(x => x.startsWith('referer_intro_let'));
    let jwttoken = obj.substring(18);
    return jwttoken;
  }
}

export const LoadCombinedReference = () => {
  const cachedReference = sessionStorage.getItem('referenceData');
  // console.log('Cached Reference  ' + JSON.stringify(cachedReference))
  if (cachedReference === undefined || cachedReference === null) {
    console.log('Reference Data - calling REST service')
    fetch('/provisionalCMIR/getAllReferenceData_v1', { 
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getJWTTokenFromProxy(),
      }})        
    .then(response => response.json())
    .then(result => {
        if (result !== null) {          
          console.log('Reference Data Fetch successful')
          sessionStorage.setItem('referenceData', JSON.stringify(result))
        } else {
          console.log('Reference Data Fetch not successful. Status code - ' + JSON.stringify(result))
        }
      })
    .catch((error) => {
      console.log('Error fetching reference data ' + error)
    });
  } else {
    console.log('Reference Data - Using cached Data')
  }  
}

export const LoadCitiesReference = (stateCode) => {
    const url = '/provisionalCMIR/getUSCityByStateReferenceData_v1?stateCode=' + stateCode
    console.log('Reference City Data - calling REST service for state ' + stateCode)
    return fetch(url, { 
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getJWTTokenFromProxy(),
      }
    })        
    .then(response => response.json())

    .catch((error) => {
      console.log('Error fetching reference data for city ' + error)
    });
}

export const GetReferenceCurrencies = () => {
  let cachedReference = sessionStorage.getItem('referenceData');
  let parsedReference = []

  if (cachedReference !== null && cachedReference !== undefined) {
      parsedReference = JSON.parse(cachedReference).exchangeRates   
  } else {
    LoadCombinedReference()
    cachedReference = sessionStorage.getItem('referenceData');
    parsedReference = cachedReference? JSON.parse(cachedReference).exchangeRates : parsedReference
  }
  // console.log('parsed reference ' + JSON.stringify(parsedReference))
  if (parsedReference !==  null) {
    return parsedReference.map(country => ({
      value: country.code,
      label: [<Flag code={country.code} height="12" />, ' ',  country.description, ' - ', 
          country.currencyName, ' - ', country.currencyCode + '(' + getSymbolFromCurrency(country.currencyCode) + ')' ],
      exchangeRate: (1/country.foreignCurrencyToDollarRate).toString().substring(0, 12),
      countryDescription: country.description,
      currencyName: country.currencyName,
      currencySymbol: getSymbolFromCurrency(country.currencyCode),
      currencyCode: country.currencyCode
    }))
  }
  return parsedReference
}

export const GetReferenceCountries = () => {
  let cachedReference = sessionStorage.getItem('referenceData');
  let parsedReference = []

  if (cachedReference !== null && cachedReference !== undefined) {
      parsedReference = JSON.parse(cachedReference).countryCodes   
  } else {
    LoadCombinedReference()
    cachedReference = sessionStorage.getItem('referenceData');
    parsedReference = cachedReference? JSON.parse(cachedReference).countryCodes : parsedReference
  }
  // console.log('parsed reference ' + JSON.stringify(parsedReference))
  if (parsedReference !==  null) {
    return parsedReference.map(country => ({
      value: country.code,
      label: [<Flag code={country.code} height="12" />, ' ',  country.description],      
      countryDescription: country.description,
      visaWaiver: country.isVisaWaverCountry     
    }))
  }
  return parsedReference
}

export const GetReferenceStates = () => {
  let cachedReference = sessionStorage.getItem('referenceData');
  let parsedReference = []

  if (cachedReference !== null && cachedReference !== undefined) {
      parsedReference = JSON.parse(cachedReference).allStates   
  } else {
    LoadCombinedReference()
    cachedReference = sessionStorage.getItem('referenceData');
    parsedReference = cachedReference? JSON.parse(cachedReference).allStates : parsedReference
  }
  // console.log('parsed reference states: ' + JSON.stringify(parsedReference))
  if (parsedReference !==  null) {
    return parsedReference.map(state => ({
      value: state.code,            
      label: state.description,
      stateDescription: state.description,
      countryCode: state.countryCode     
    }))
  }
  return parsedReference
}

export const GetReferenceFilteredStates = (filter) => {
  let cachedReference = sessionStorage.getItem('referenceData');
  let parsedReference = []

  if (cachedReference !== null && cachedReference !== undefined) {
      parsedReference = JSON.parse(cachedReference).allStates   
  } else {
    LoadCombinedReference()
    cachedReference = sessionStorage.getItem('referenceData');
    parsedReference = cachedReference? JSON.parse(cachedReference).allStates : parsedReference
  }
  // console.log('parsed reference states: ' + JSON.stringify(parsedReference))
  if (parsedReference !==  null) {
    var filteredStateReference = parsedReference.filter(state => state.countryCode === filter)
    // console.log('filtered reference states: ' + JSON.stringify(filteredStateReference))
    return filteredStateReference.map(state => ({
      value: state.code,            
      label: state.description,
      stateDescription: state.description,
      countryCode: state.countryCode     
    }))
  }
  return filteredStateReference
}

export const GetReferenceFilteredCities = (stateCode) => {
  // let cachedReference = sessionStorage.getItem(stateCode + 'referenceData');
  // let parsedReference = []

  // if (cachedReference !== null && cachedReference !== undefined) {
  //     parsedReference = JSON.parse(cachedReference).cityStateNames   
  // } else {
    const citiesReference = LoadCitiesReference(stateCode)
    // cachedReference = sessionStorage.getItem(stateCode + 'referenceData');
    // parsedReference = cachedReference? JSON.parse(cachedReference).cityStateNames : parsedReference
  // }
  // console.log('parsed reference cities for ' + stateCode + ' : ' + JSON.stringify(citiesReference))
  if (citiesReference !==  undefined && citiesReference !==  null) {    
    // console.log('filtered reference states: ' + JSON.stringify(filteredStateReference))
    return citiesReference.map(city => ({
      value: city.cityCode,            
      label: city.cityName,
      stateDescription: city.cityName,
      stateCode: city.stateCode,
      stateName: city.stateName,
      countryCode: city.countryCode
    }))
  }
  return citiesReference
}