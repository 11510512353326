const start = (state=initiaStart, action) => {
    switch (action.type) {
      case 'UPDATE_START':        
        return action.start
      case 'RESET_START':
        return initiaStart
      default:
        return state    
    }
  }

  const initiaStart = {
    pct: '',
    mailingOrPhysical: '',
    personalOrBusiness: '',
    myselfOrSomeone: ''
}

  export default start