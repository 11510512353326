import { connect } from 'react-redux'
import PersonalInformation from '../component/PersonalInformation'

const mapStateToProps = state => ({
  activeStep: state.activeStep,
  start: state.start,
  person: state.person,
  addresses: state.addresses
})

const mapDispatchToProps = (dispatch) => ({
  
})

export default connect(mapStateToProps, mapDispatchToProps) (PersonalInformation);
