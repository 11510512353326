const mailship = (state = initialMailShip, action) => {
    switch (action.type) {
      case 'RESET_MAILSHIP':
        return initialMailShip
      case 'UPDATE_MAILSHIP':
        let newMailShip = {
          ...state,
          ...action.mailship,
          // ...action.mailshipPerson
        }
        return newMailShip        
      default:
        return state    
    }
  }
  
  const initialMailShip = {
      sendOrReceive: -1,
      first: '',
      last: '',
      middle: '',
      business: '',
      methodOfShipment: '',
      carrierName: '',
      shipDate: '',
      receiveDate: '',      
  }

  export default mailship