import React, { Component } from 'react';
import { Grid, Typography, } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MDTextField from './MDTextField';
import AsyncReactSelect from './AsyncReactSelect';

const styles = theme => ({
  textField: {
    "& label": {
      color: "#525252"
    },
    "& label span": {
      color: "#9C2F36"
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),    
    width: theme.spacing(24),
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'blue',
    borderRadius: '8px',
  },
  textFieldAddress: {
    width: theme.spacing(107),
  },
  // subHead: {
  //   paddingTop: '32px',
  //   MarginBottom: '0px'
  // }
  subHead: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    // backgroundColor: 'lightBlue',
    paddingTop: '32px',
    MarginBottom: '0px',
    fontWeight: "660"
  },
  infoBox: {
    // "& label": {
    //   color: "#525252"
    // },
    // "& label span": {
    //   color: "#9C2F36"
    // },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(48),
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'blue',
    borderRadius: '8px',
  },
  info: {
    fontSize: '10px',
    paddingLeft: '4px'

  }
})

class Person extends Component {
  constructor(props) {
    super(props);
    // alert(JSON.stringify(props.person))     
    this.state = {
      person: props.person, business: props.business, summary: props.summary, 
      thirdParty: props.thirdParty, shiprecv: props.shiprecv, passportreq: props.person.passportreq, idreq: props.person.idreq, reviewPage: props.reviewPage,
      alienreq: props.person.alienreq, error: false, errorText: '', visaFilled: false, uniqId:'',
      requiredFields: {first: props.reviewPage? true : false, last: props.reviewPage? true : false, business: props.reviewPage? true : false, 
        dob: props.reviewPage? true : false, coc: props.reviewPage? true : false, idnum: props.reviewPage? true : false, 
        passport: props.reviewPage? true : false, passportCountry: props.reviewPage? true : false, visaDate: props.reviewPage? true : false, 
        visaIssued: props.reviewPage? true : false, alienNumber: props.reviewPage? true : false}
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {uniqId
  //   // alert(JSON.stringify(prevState.person) + ' person changed ' + JSON.stringify(nextProps.person))
  //   if (nextProps.person !== prevState.person) {
  //     return { person: nextProps.person }
  //   } if (nextProps.summary !== prevState.summary) {
  //     return { summary: nextProps.summary }
  //   } else return null;
  // }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const { person, business, summary } = this.state

      if (nextProps.person !== person) {
        // console.log('personin ' + JSON.stringify(person) + ' ' + JSON.stringify(nextProps.person))
        this.setState({ person: nextProps.person });
      }
      if (nextProps.business !== business) {
        // console.log('businessin ' + JSON.stringify(business) + ' ' + JSON.stringify(nextProps.business))
        this.setState({ business: nextProps.business });
      }
      if (nextProps.summary !== summary) {
        this.setState({ summary: nextProps.summary });
      } if(nextProps.reviewPage===true ) {
      if(nextProps.person.passportreq===false && nextProps.person.passportreq !== person.passportreq){
        this.setState({passportreq:false})
      } 
      if(nextProps.person.idreq===false && nextProps.person.idreq !== person.idreq){
        this.setState({idreq:false})
      } 
      if(nextProps.person.alienreq===false && nextProps.person.alienreq !== person.alienreq){
        this.setState({alienreq:false})
      } 
    }
  }
  }


  handleChange = (propertyName, value) => {
    // console.log(JSON.stringify(propertyName) + ' ' + JSON.stringify(value))
    const { person, thirdParty, requiredFields } = this.state;
    const newPerson = {
      ...person,
      [propertyName]: value,
    };
    // console.log(JSON.stringify(requiredFields) + ' -- ' + JSON.stringify(person))
    // if ((propertyName === 'dob' || propertyName === 'visaDate') 
    //     && (Object.prototype.toString.call(value) === "[object Date]") && !isNaN(value.valueOf())
    //     && newPerson.dob !== null && newPerson.visaDate !== null && newPerson.dob !== '' && newPerson.visaDate !== ''
    //     && requiredFields.dob) {
    //   const dobD = newPerson.dob
    //   const visaDateD = newPerson.visaDate

    //   if (dobD > visaDateD) {
    //     this.setState({ error: true, errorText: 'U.S. Visa Date should be after Date of Birth' });
    //     this.handleError('visaDate', true)
    //   } else {
    //     this.setState({ error: false, errorText: '' });
    //     this.handleError('visaDate', false)
    //   }
    // }

    if (propertyName === 'visaDate') {
      this.setState({ error: false, errorText: '' });
    }

    if (newPerson.visaDate !== '') {
      this.setState({ visaFilled: true })
    } else {
      this.setState({ visaFilled: false })
    }

    if (propertyName === "first" && value !== undefined) {
      newPerson.first = value.charAt(0).toUpperCase() + value.slice(1);
    } 
    if (propertyName === "last" && value !== undefined) {
      newPerson.last = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (propertyName === "middle" && value !== undefined) {
      newPerson.middle = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if(propertyName === "businessTypeOrProfession" && value !== undefined) {
      newPerson.businessTypeOrProfession = value.charAt(0).toUpperCase() + value.slice(1);
    }

    if (!thirdParty && person.coc !== undefined && person.coc.value === 'USA') {
      if (propertyName === 'passport') {        
        const newRequiredFields = {...requiredFields, idnum: value !== ''? true : false, passportCountry: value !== ''? true : false};        
        this.setState({ idreq: value !== ''? false : true, requiredFields: newRequiredFields})
        if(newPerson.idreq===true && value!==''){
          newPerson.idreq= false 
      }
      }      
      if (propertyName === 'idnum') {
        const newRequiredFields = {...requiredFields, passport: value !== ''? true : false, passportCountry: value !== ''? true : false};
        this.setState({ passportreq: value !== ''? false : true, requiredFields: newRequiredFields })
        if(newPerson.passportreq===true && value!==''){
          newPerson.passportreq= false
        }
      }
    } else if (!thirdParty && person.coc !== '') {
      if (propertyName === 'passport') {
        const newRequiredFields = {...requiredFields, idnum: value !== ''? true : false, passportCountry: value !== ''? true : false, alienNumber: value !== ''? true : false};   
        this.setState({ idreq: value !== ''?false : true, alienreq: value !== ''? false : true, requiredFields: newRequiredFields })
        if(newPerson.idreq===true && value!==''){
          newPerson.idreq= false
        }if((newPerson.alienreq===true && value!=='')){
          newPerson.alienreq=false
        }
      }
      if (propertyName === 'idnum') {        
        const newRequiredFields = {...requiredFields, passport: value !== ''? true : false, passportCountry: value !== ''? true : false, alienNumber: value !== ''? true : false};
        this.setState({ passportreq: value !== ''? false : true, alienreq: value !== ''? false : true, requiredFields: newRequiredFields })
        if(newPerson.passportreq===true && value!==''){
          newPerson.passportreq= false
        }if((newPerson.alienreq===true && value!=='')){
          newPerson.alienreq=false
        }
      }
      if (propertyName === 'alienNumber') {
        const newRequiredFields = {...requiredFields, idnum: value !== ''? true : false, passport: value !== ''? true : false, passportCountry: value !== ''? true : false}; 
        this.setState({ idreq: value !== ''? false : true, passportreq: value !== ''? false : true, requiredFields: newRequiredFields })
        if(newPerson.idreq===true && value!==''){
          newPerson.idreq= false
        }if((newPerson.passportreq===true && value!=='')){
          newPerson.passportreq=false
        }
      }
    }

    this.setState({ person: newPerson });
    this.props.updatePerson(newPerson);
  }

  handleError = (propertyName, inerror) => {
    const { requiredFields, person, error } = this.state;
    var allReqDone = false;

    const newRequiredFields = {
      ...requiredFields,
      [propertyName]: !inerror,
    };

    // console.log(JSON.stringify(newRequiredFields) + ' /bef--/ ' + JSON.stringify(propertyName))

    // if ((propertyName === 'dob' || propertyName === 'visaDate')
    if ((propertyName === 'datecomp')    
          && newRequiredFields.dob && newRequiredFields.visaDate) {

      if (person.dob > person.visaDate) {
        this.setState({ error: true, errorText: 'U.S. Visa Date should be after Date of Birth' });
        newRequiredFields.visaDate = false;
        allReqDone = false;
      } else {
        allReqDone = this.checkAllReqDone(newRequiredFields);
      }
    } else {
      if (propertyName !== 'dob' && propertyName !== 'visaDate') {
        allReqDone = this.checkAllReqDone(newRequiredFields);
      }      
    }

    // console.log('mailshiplocator ' + this.props.mailshiplocator);
    if (this.props.mailshiplocator !== undefined && this.props.mailshiplocator  !== 'mailship') {
      this.props.handleError(this.props.mailshiplocator, allReqDone && !error);
    } else {
      this.props.handleError('person', allReqDone && !error);
    }

    // console.log(JSON.stringify(newRequiredFields) + ' /--/ ' + JSON.stringify(propertyName))
    this.setState({ requiredFields: newRequiredFields });
    
    // console.log('allReqDone ' + allReqDone);
  }

  checkAllReqDone(newRequiredFields) {
    const { thirdParty, person, shiprecv } = this.state;
    var allReqDone = false;
    if (thirdParty) {
      if (this.props.business) {
        if (newRequiredFields.business) {
          allReqDone = true;
        }
      } else {
        if (newRequiredFields.first && newRequiredFields.last) {
          allReqDone = true;
        }
      }
    } else if (shiprecv) {
      if (this.props.business) {
        if (newRequiredFields.business) {
          allReqDone = true;
        }
      } else {
        if (newRequiredFields.first && newRequiredFields.last) {
          allReqDone = true;
        }
      }      
    } else if (this.props.business) {
      if (newRequiredFields.business && newRequiredFields.idnum) {
        allReqDone = true;
      }
    } else if (person.coc !== null && (person.coc.value === 'USA' || person.coc.visaWaiver === 'Y')) {
      if (newRequiredFields.first && newRequiredFields.last && newRequiredFields.dob && newRequiredFields.coc 
        && (newRequiredFields.idnum || (newRequiredFields.passport && newRequiredFields.passportCountry))) {
        allReqDone = true;
      }
    } else if (newRequiredFields.first && newRequiredFields.last && newRequiredFields.dob && newRequiredFields.coc
       && (newRequiredFields.idnum || (newRequiredFields.passport && newRequiredFields.passportCountry) || newRequiredFields.alienNumber) 
       && newRequiredFields.visaDate && newRequiredFields.visaIssued) {
      allReqDone = true;
    }
    return allReqDone;
  }

  render = () => {
    const { classes,locator,thirdpartylocator,mailshiplocator } = this.props
    const { person, business, summary, thirdParty, shiprecv, passportreq, idreq, alienreq, visaFilled ,error, errorText } = this.state
    return (
      <div>
        <Grid container direction="row" alignItems="center">
          {(business) &&
            <MDTextField name='business' label='Business Name' value={person.business} handleChange={this.handleChange}
                handleError={this.handleError} required={true} wide={true} 
                inputProps={{ maxLength: 95, id: locator === 'Review'? 'business' + locator : 'business' }} summary={summary} />
          }
           {(business && !thirdParty && !shiprecv) &&
          <Grid container direction="row" alignItems="center">             
             <MDTextField name='idnum' label='Identification Number' value={person.idnum} handleChange={this.handleChange} 
                handleError={this.handleError} required={true} inputProps={{maxLength: 20, id: locator === 'Review'? 'idnum' + locator : 'idnum'}} summary={summary} /> 
           </Grid>
          }
          
          {(!business) &&
          <MDTextField name='first' label='First Name' value={person.first} handleChange={this.handleChange} 
              handleError={this.handleError} required={true} 
              inputProps={{ maxLength: 44, id: locator === 'Review'? 'firstName' + locator: thirdpartylocator === 'thirdparty'? 
              'firstName' + thirdpartylocator : mailshiplocator  === 'mailship'? 'firstName' + mailshiplocator : 'firstname' }} summary={summary} />
          }
          {(!business) &&
          <MDTextField name='last' label='Last Name' value={person.last} handleChange={this.handleChange} 
              handleError={this.handleError} required={true}  inputProps={{ maxLength: 95, 
                id:locator === 'Review'? 'lastName' + locator : thirdpartylocator === 'thirdparty'? 'lastName' + thirdpartylocator : mailshiplocator === 'mailship'? 
                'lastName' + mailshiplocator : 'lastName' }} summary={summary} />
          }
          {(!business) &&
          <MDTextField name='middle' label='Middle Name' value={person.middle} handleChange={this.handleChange} 
              handleError={this.handleError} required={false} 
              inputProps={{ maxLength: 40, id: locator === 'Review'? 'MiddleName' + locator : thirdpartylocator === 'thirdparty'?
               'MiddleName' + thirdpartylocator : mailshiplocator === 'mailship'? 'MiddleName' + mailshiplocator : 'MiddleName'}} summary={summary} />
          }

          {(!thirdParty && !shiprecv && !business) &&
            <MDTextField name='dob' id='date' label='Date of Birth' value={person.dob} type='date' inputLabelProps={{ shrink: true }}
                handleChange={this.handleChange} handleError={this.handleError} required={true} inputProps={{ id:locator === 'Review'? 'date' + locator : 'date'}} summary={summary} />
          }
          {(thirdParty) &&
            <MDTextField name='businessTypeOrProfession' label='Profession/Type' value={person.businessTypeOrProfession} handleChange={this.handleChange} 
                handleError={this.handleError} required={false}  inputProps={{ maxLength: 30, id: locator === 'Review'?
                 'businessTypeOrProfession' + locator : 'businessTypeOrProfession'}} summary={summary} />
          }
        </Grid>
         
        {(!thirdParty && !shiprecv && !business) &&
          <Grid container direction="row" alignItems="center">
            <AsyncReactSelect name='coc' label='Citizenship' value={person.coc} placeholder='Search for Citizenship' errorText='Please select Citizenship'
              optionsType='country' handleChange={this.handleChange} handleError={this.handleError} required={true} 
              uniqId={locator === 'Review'? 'Citizenship' + locator : 'Citizenship'} disabled={summary ? true : false}  summary={summary} />
            {!thirdParty && !shiprecv && person.coc !== null && person.coc.value !== undefined && person.coc.value !== 'USA' &&
              <Grid className={classes.textField}>
              <Typography align="left" className={classes.info}>
                    *Non-US Citizens need to provide<br></br> 
                      - Identification Number (or)<br></br>
                      - Alien Number (or)<br></br>
                      - Passport Number & Passport Country
              </Typography>
              </Grid>
            }            
            <MDTextField name='idnum' label='Identification Number' value={person.idnum} handleChange={this.handleChange} 
                handleError={this.handleError} required={idreq}  inputProps={{maxLength: 20, id: locator === 'Review'? 'idnum' + locator : 'idnum'}} summary={summary} />
            {(person.coc !== null && person.coc.value !== 'USA') &&
              <MDTextField name='alienNumber' label='Alien Number' value={person.alienNumber} handleChange={this.handleChange} 
                  handleError={this.handleError} required={alienreq} inputProps={{maxLength: 30, id: locator === 'Review'? 'alienNumber' + locator : 'alienNumber'}} summary={summary} />
            }

          </Grid>
        }

        {(!thirdParty && !shiprecv && !business) &&
          <Grid container direction="row" alignItems="center">
            <MDTextField name='passport' label='Passport Number' value={person.passport} handleChange={this.handleChange} 
                handleError={this.handleError} required={passportreq} inputProps={{maxLength: 20, id:locator === 'Review'? 'passport' + locator : 'passport'}}  summary={summary} />
            <AsyncReactSelect name='passportCountry' label='Passport Country' value={person.passportCountry} placeholder='Search for Issue Country' errorText='Please select Issue Country'
              optionsType='country' handleChange={this.handleChange} handleError={this.handleError} required={passportreq} disabled={summary ? true : false} 
              uniqId={locator === 'Review'? 'passportCountry' + locator : 'passportCountry'} summary={summary} />
            {person.coc !== null && person.coc.value !== 'USA' && person.coc.visaWaiver === 'N' &&
              <MDTextField name='visaDate' label='U.S. Visa Date' value={person.visaDate} handleChange={this.handleChange} 
                  handleError={this.handleError} type='date' inputLabelProps={{ shrink: true }} required={true} inputProps={{maxLength: 20, id: locator === 'Review'? 'visaDate' + locator : 'visaDate'}}  summary={summary}
                  error={!visaFilled? undefined : error} errorText={!visaFilled? undefined : errorText } />
            }
            {person.coc !== null && person.coc.value !== 'USA' && person.coc.visaWaiver === 'N' && 
            <MDTextField name='visaIssued' label='U.S. Visa Issued City' value={person.visaIssued} handleChange={this.handleChange} 
                handleError={this.handleError} required={true} inputProps={{maxLength: 50, id:locator === 'Review'? 'visaIssued' + locator : 'visaIssued'}} summary={summary} />
            }
            </Grid>
        }
      </div>
    );
  }
}

export default withStyles(styles)(Person);