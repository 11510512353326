const receipt = (state=initialReceipt, action) => {
    switch (action.type) {
      case 'RESET_RECEIPT':
        return initialReceipt
      case 'UPDATE_RECEIPT':        
        let newState = { ...state, ...action.receipt }; 
        // console.log('new conf state ' + JSON.stringify(newState))
        return newState
      default:
        return state    
    }
  }

  const initialReceipt = {
    confirmationNumber: '',
    statusCode: '',
    firstName: '',
    lastName: '',
    submissionDate: '',
    expireDate: '',
    totalUSD: 0.00,
    sigQrBase64: ''
}

  export default receipt