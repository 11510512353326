const ship = (state = initialShip, action) => {
    switch (action.type) {
      case 'RESET_SHIP':
        return initialShip
      case 'UPDATE_SHIP':
        let newShip = {
          ...state,
          ...action.ship,          
        }
        return newShip        
      default:
        return state    
    }
  }
  
  const initialShip = {
      first: '',
      last: '',
      middle: '',
      business: '',      
  }

  export default ship