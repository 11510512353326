import React, { Component } from 'react';
import { Typography, Grid, Paper, FormControlLabel, Checkbox, Button, IconButton } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles'
import NavigationButtons from '../container/NavigationButtons';
import Person from './custom/Person';
import Address from './custom/Address';
import ReactTooltip from "react-tooltip";

const styles = theme => ({  
  paper: {
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    // width: theme.spacing.unit * 170,
    // maxWidth: theme.spacing.unit * 160,
    textAlign: 'left',
    justify: 'center',
    color: theme.palette.text.primary,
    // backgroundColor: '#d3ebf9c4',
    backgroundColor: '#ffffff',
  },
  textField: {
    "& label": {
      color: "grey"
    },
    "& label span": {
      color: "red"
    },
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  textFieldAddress: {
    width: theme.spacing.unit * 107,
  },
  selectInput: {
    marginTop: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 2,
    width: theme.spacing.unit * 24,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 435,
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 200,
      borderColor: '#fb0606'
    }), 
  },
  reactSelect: {
    width: theme.spacing.unit * 24,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 435,
    borderColor: '#fb0606',
    control: {
      borderColor: '#fb0606'
    }
  },
 
  subHead: {
    marginRight: theme.spacing.unit * 2,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    // backgroundColor: 'lightBlue',
    paddingTop: '32px',
    marginBottom: '0px',
    fontWeight: "660"
  },
  formMargin: {
    marginLeft: '0px'
  },
  questionPCT: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: "bold",
    marginBottom: '2px',
    color: '#000000'    
  },
  button: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "620",
    backgroundColor: "#324bb5",  
    margin: '22px',
    [theme.breakpoints.down('xs')]: { 
      fontSize: theme.typography.pxToRem(14),
      margin: '12px',
    }, 
  },
  infoIcon: {
    //     fontSize: theme.typography.pxToRem(18),
        verticalAlign: 'text-top',
        color: '#3939c8',
        paddingTop: '0px',
        paddingBottom: '12px',
      },

  tooltip: {
    width:300,
    maxWidth:'40%'
  },
})

class ThirdPartyInformation extends Component {
  constructor(props) {
    super(props);    
    this.state = {Q: '', location: 'Permanent Address in United States or Abroad', start: props.start, activeStep: props.activeStep, countries: [],
                    person: props.person, addresses: props.addresses, business: false, thirdpartylocator:'',
                    requiredFields: {person: false, address2: false, locator:props.locator}};
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const { start, person, addresses } = this.state
          
      if (nextProps.start !== start) {
        this.setState({ start: nextProps.start });
      }

      if (nextProps.person !== person) {
        this.setState({ person: nextProps.person });
      }
      if (nextProps.addresses !== addresses) {
        this.setState({ addresses: nextProps.addresses });
      }
    }
  }

  updatePerson = (person) => {
    this.setState({ person: person });
  } 

  updateAddress = (address, index) =>  {
    const { addresses } = this.state;  
    const newAddresses = addresses

    newAddresses[index] = address      

    this.setState({ addresses: newAddresses });      
  }

  handleError = (propertyName, allReqDone) => {
    const { requiredFields } = this.state; 

    const newRequiredFields = {
      ...requiredFields,
      [propertyName]: allReqDone,      
    };    
    
    this.setState({ requiredFields: newRequiredFields });
  }

  handleChangeChecked = propertyName => event => {
    if (propertyName === 'BU') {
      this.setState({ business: event.target.checked });
    } else {
      const { person } = this.state;
      const newPerson = {
        ...person,
        isBank: event.target.checked
      }
      this.setState({ person: newPerson });
    }
  }

  selectPCT = (selection) => {
    if (selection === 'Q4') {
      this.setState({ Q: selection, location: 'Address of the Pick up Location' })
    } else if (selection === 'Q3' || selection === 'Q1') {
      this.setState({ Q: selection, location: 'Business Address of the Shipper' })
    } else if (selection === 'Q2') {
      this.setState({ Q: selection, location: 'Address of the Currency Originator' })
    }
  }

  render = () => {
    const { classes } = this.props
    const { start, person, addresses, requiredFields, business, Q, location } = this.state
    return (
      <div>       
            <Paper className={classes.paper}>

            {(start.pct === 'Y' && Q === '') &&  
            <Grid>
              <Typography className={classes.questionPCT1} align='center'>Does the Currency or Monetary Instrument belong to the Shipper or Currency Originator?
              <span data-tip="" data-for='currTrans'>                  
                  <IconButton aria-label="Info">
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton> </span>
                  <ReactTooltip id ='currTrans' className={classes.tooltip}>
                    {"The actual address location of business where the currency is picked up from."}
                    </ReactTooltip>
              </Typography>              
              <Grid container justifyContent="center">
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Q1')} >
                  Shipper
                </Button>
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Q2')} >
                  Currency Originator
                </Button>
              </Grid>
            </Grid>
            }
             {(start.pct === 'Y' && Q === 'Q1') &&  
            <Grid>
              <Typography className={classes.questionPCT1} align='center'>Where is the Currency or Monetary Instrument being picked up from?
              <span data-tip="" data-for='currTrans'>                  
                  <IconButton aria-label="Info">
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton> </span>
                  <ReactTooltip id ='currTrans' className={classes.tooltip}>
                    {"The actual address location of business where the currency is picked up from."}
                    </ReactTooltip>
              </Typography>              
              <Grid container justifyContent="center">
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Q3')} >
                  Shipper's Place of Business
                </Button>
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Q4')} >
                  Different Pick up Location
                </Button>
              </Grid>
            </Grid>
            }

            {(start.pct === 'N' || Q === 'Q2' || Q === 'Q3' || Q === 'Q4') &&
              <Grid>
                <FormControlLabel
                  className={classes.formMargin}
                  control={
                  <Checkbox
                    checked={business ? true: false}
                    onChange={this.handleChangeChecked('BU')}
                    value={business}
                  /> }
                  label="Business"
                />

                {business && 
                <FormControlLabel
                  className={classes.formMargin}
                  control={
                  <Checkbox
                    checked={person.isBank ? true: false}
                    onChange={this.handleChangeChecked('BA')}
                    value={person.isBank}
                  /> }
                  label="Business is Bank"
                />
                }

                <Person person={person} thirdpartylocator='thirdparty' updatePerson={this.updatePerson} handleError={this.handleError} thirdParty={true}
                      business={business} />
                             
                <Grid>
                  <Typography className={classes.subHead}>{location}</Typography>
                </Grid>

                <Address address={addresses[2]} index='2' thirdpartylocator='thirdparty' updateAddress={this.updateAddress} handleError={this.handleError} />                

                <NavigationButtons thirdparty={person} addresses={addresses} 
                          allReqDone={requiredFields.person && requiredFields.address2} />
              </Grid>
             }            
          </Paper>
       </div>
    );
  }
}

export default withStyles(styles) (ThirdPartyInformation);