/* eslint-disable */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button, FormControlLabel, Checkbox, Tooltip, Divider } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SignatureCanvas from 'react-signature-canvas'
import Popup from 'reactjs-popup';
import NavigationButtons from '../container/NavigationButtons';
import MDTextField from './custom/MDTextField';
import AsyncReactSelect from './custom/AsyncReactSelect';
import Person from './custom/Person';
import Address from './custom/Address';
import CurrencyItem from './CurrencyItem';
import dateFormat from 'dateformat';
import ReactTooltip from "react-tooltip";



const styles = theme => ({  
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: 'left',
    // justify: 'center',
    color: theme.palette.text.primary,
    backgroundColor: '#ffffff',
    borderRadius: '12px'
  },
  paperSummary: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),  
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: '#ffffff',    
  },
  paperSummaryEdit: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: 'left',
    justify: 'center',
    color: theme.palette.text.primary,
    backgroundColor: '#bfc8ff',
    boxShadow: '4px -4px 6px 1px #0014ff',
    borderRadius: '12px',
  },
  textField: {
    '& label': {
      color: '#525252'
    },
    '& label span': {
      color: '#9C2F36'
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 260
  },
  sigCanvas: {
    border: '1px solid #000000',
    cursor: 'pointer',
    width: 620,
    height: 100,
    [theme.breakpoints.down('xs')]: {
      width: 272,
      height: 74
    }, 
  },
  signLabel: {
    fontWeight: '550',
    fontSize: theme.typography.pxToRem(15),
  },
  checkboxBackground: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    backgroundColor: '#c9dbf7'
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: '440',    
    fontSize: theme.typography.pxToRem(12.5),  
  },
  button: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "600", 
    padding: '4px',
    margin: '4px',
    textTransform:'capitalize'
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(18),
    verticalAlign: 'text-top',
    marginRight:'12px'
  },
  fab: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  modal: {
    fontSize: theme.typography.pxToRem(16),
    opacity: '1',
    backgroundColor: 'transparent',
 },
 modalHeader: {
   textTransform: 'uppercase',
   fontSize: '18px',
   fontWeight: '500',
   textAlign: 'center',
   marginTop: '10px',
   marginBottom: '12px',
   lineHeight: '1.1'
 },
 modalContent: {
   marginBottom: theme.spacing(3),
   fontSize: '16px',
   padding: '15px',
   position: 'relative',
   fontFamily: 'Roboto,Calibri,Tahoma,Arial,sans-serif',
 },
 modalClose: {
   cursor: 'pointer',
   display: 'block',
   lineHeight: theme.typography.pxToRem(20),
   fontSize: theme.typography.pxToRem(22),
   backgroundColor: 'transparent',   
   position: 'absolute',    
   padding: '2px 5px',    
   right: '0px',
   top: '-1px', 
 },
 buttonBar: {
  height: 68,
}, 
 cancelButton: {
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: 130
},
confirmButton: {
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: 200
},

iconButton: {
   marginLeft:'-5px',
   width:30
},
headerText: {
  fontSize: 16,
  fontWeight: 600,
  [theme.breakpoints.down('xs')]: {
    display: 'none'
  }, 
},
headerText2: {
  fontSize: 16,
  fontWeight: 600,
  [theme.breakpoints.down('xs')]: {
    fontSize: 10,
  }, 
},
staticText: {
  fontSize: 16,
},
largeWide: {
  width: 324,
  marginLeft: '4px',
  [theme.breakpoints.down('xs')]: {
    width: 92
  },     
},
xWide: {
  width: '368px',
  marginLeft: '24px',
},
mediumWide: {
  width: 148,
  marginLeft: '4px',
  [theme.breakpoints.down('xs')]: {
    display: 'none'
  },  
},
mediumWide2: {
  width: 148,
  marginLeft: '4px',
  [theme.breakpoints.down('xs')]: {
    width: 62
  },  
},
smallWide: {
  width: '114px',
  marginLeft: '4px',
  [theme.breakpoints.down('xs')]: {
    display: 'none'
  },  
},
smallWide2: {
  width: '114px',
  marginLeft: '4px',
  [theme.breakpoints.down('xs')]: {
    width: 42
  },  
},
divider: {
  marginTop: '8px',
  backgroundColor: "#e2e2e2",
  height: '2px'
},
secondaryHeading: {
  paddingRight: '10px',
  fontSize: theme.typography.pxToRem(15),
  textAlign:'end',
  color: '#000000',  
  fontWeight: 'bold',
  height: '20px',  
},
root: {
  [theme.breakpoints.down('xs')]: {
    display: 'none'
  }, 
}
})
 
class ReviewSign extends Component {
  constructor(props) {
    super(props);
    this.state = { activeStep: props.activeStep, person: props.person, addresses: props.addresses,  exportImport: props.exportImport, 
              carrier: props.carrier, mailship: props.mailship, ship: props.ship, required: props.required,
              thirdParty: props.thirdParty, currencies: props.currencies, coins: props.coins, others: props.others, sameAddress: false, open: false, reviewPage:false,
              review: {...this.props.review, signDate: dateFormat(new Date(), "UTC:yyyy-mm-dd")}, alertType: 'R', incurrencysummary: true,
              showClear: false, declaration: false, acknowledgement: false, 
              personalsummary: true, addresssummary: true, exportimportsummary: true, mailshipSummary: true, currencysummary: true, thirdPartySummary: true, locator:'',
              requiredFields: { signName: false, person: true, thirdParty: true, 
                exportImport: true, departureCountry: true, departurePort: true, arriveCountry: true, arrivePort: true,
                mailship: true, shipDate: true, receiveDate: true,
                address0: true, address1: true, address2: true, address3: true }};
  }

  static getDerivedStateFromProps(nextProps, prevState) {   
    if (nextProps.activeStep !== prevState.activeStep) {
      if(nextProps.person !== prevState.person) {          
        return { activeStep: nextProps.activeStep, person: nextProps.person }
      } else if(nextProps.thirdParty !== prevState.thirdParty) {        
        return { activeStep: nextProps.activeStep, thirdParty: nextProps.thirdParty }
      } else if(nextProps.addresses !== prevState.addresses) {      
        return { activeStep: nextProps.activeStep, addresses: nextProps.addresses }
      } else if(nextProps.exportImport !== prevState.exportImport) {      
        return { activeStep: nextProps.activeStep, exportImport: nextProps.exportImport }
      // } else if(nextProps.carrier !== prevState.carrier) {      
      //   return { activeStep: nextProps.activeStep, carrier: nextProps.carrier }
      } else if(nextProps.mailship !== prevState.mailship) {      
        return { activeStep: nextProps.activeStep, mailship: nextProps.mailship, carrier: nextProps.carrier, ship: nextProps.ship }
      // } else if(nextProps.ship !== prevState.ship) {      
      //   return { activeStep: nextProps.activeStep, ship: nextProps.ship }
      } else if(nextProps.required !== prevState.required) {      
        return { activeStep: nextProps.activeStep, required: nextProps.required }
      } else if(nextProps.currencies !== prevState.currencies || nextProps.coins !== prevState.coins || nextProps.others !== prevState.others) {          
        return { activeStep: nextProps.activeStep, currencies: nextProps.currencies, coins: nextProps.coins, others: nextProps.others }
      // } else if(nextProps.currencies !== prevState.currencies) {        
      //   return { activeStep: nextProps.activeStep, currencies: nextProps.currencies }
      // } else if(nextProps.coins !== prevState.coins) {        
      //   return { activeStep: nextProps.activeStep, coins: nextProps.coins }
      // } else if(nextProps.others !== prevState.others) {        
      //   return { activeStep: nextProps.activeStep, others: nextProps.others }
      } else return { activeStep: nextProps.activeStep }
    } else 
      return null
  }

  handleChange = (propertyName, value) => {
    const { review } = this.state;

    const newReview = {
      ...review,
      [propertyName]: value
    };
    this.setState({ review: newReview });
  }

  handleDeclarationChecked = (propertyName) => (event) => {
    const { declaration } = this.state;
    this.setState({ declaration: declaration? false : true });
    this.handleChange('declaration', declaration? false : true)
  }

  handleAcknowledgementChecked = (propertyName) => (event) => {
    const { acknowledgement } = this.state;
    this.setState({ acknowledgement: acknowledgement? false : true });
    this.handleChange('acknowledgement', acknowledgement? false : true)
  }

  clearSign = () => {
    this.sigCanvas.clear()
    this.setState({showClear: false})
  }

  enableClearButton = () => {
    this.setState({showClear: true})
  }

  captureSignature = () => {
    const { review } = this.state;

    const newReview = {
      ...review,
      sigImageBase64: this.sigCanvas.getCanvas().toDataURL('image/png')
    };
    this.setState({review: newReview})    
  }

  closepopup(event) {
    this.setState({ open: false })
  }

  toggleSummary = (selection) => {    
    const { personalsummary, thirdPartySummary, exportimportsummary, mailshipSummary, currencysummary } = this.state;
        
    if (selection === 'PI') {
      this.setState({personalsummary: personalsummary? false : true})
      if (!personalsummary) {        
        const { person, addresses, requiredFields, } = this.state;

        if (!requiredFields.person || !requiredFields.address0 || !requiredFields.address1) {
          this.setState({personalsummary: false, open: true, alertType: 'R'})
        } else {
          this.props.updatePerson(person);
          this.props.updateAddress(addresses);
        }        
      }
    } else if (selection === 'TP') {
      this.setState({thirdPartySummary: thirdPartySummary? false : true})
      if (!thirdPartySummary) {
        const { thirdParty, addresses, requiredFields } = this.state;
        if (!requiredFields.person || !requiredFields.address2) {
          this.setState({thirdPartySummary: false, open: true, alertType: 'R'})
        } else {
          this.props.updateThirdParty(thirdParty);
          this.props.updateAddress(addresses);
        }        
      }
    } else if (selection === 'EI') {
      this.setState({exportimportsummary: exportimportsummary? false : true})
      if (!exportimportsummary) {
        const { exportImport, requiredFields } = this.state;
        if (!requiredFields.exportImport) {
          this.setState({exportimportsummary: false, open: true, alertType: 'R'})
        } else {
          this.props.updateExportImport(exportImport);
        }
      }
    } else if (selection === 'MS') {
      this.setState({mailshipSummary: mailshipSummary? false : true})
      if (!mailshipSummary) {
        const { mailship, addresses, requiredFields } = this.state;
        if (!requiredFields.mailship || !requiredFields.address3) {
          this.setState({mailshipSummary: false, open: true, alertType: 'R'})
        } else {
          this.props.updateMailShip(mailship);
          this.props.updateAddress(addresses);
        }
      }    
    } else if (selection === 'MI') {
      this.setState({currencysummary: currencysummary? false : true})     
      let currtotal = 0;  

      if (!currencysummary) {
        const { currencies, coins, others, incurrencysummary } = this.state;
  
        currencies.map(curr => (currtotal += Math.round(curr.usdEquivalent)));
        if (Math.round(currtotal) < 10000 || Math.round(currtotal) > 2147483647 ) {          
          this.setState({currencysummary: false, open: true, alertType: 'M'})
        } else if (!incurrencysummary) {
          this.setState({currencysummary: false})
        } else {
          this.props.updateCurrencies(currencies);          
        }
      }
    }    
  }

  handleChangeChecked = (propertyName) => (event) => {
    const { addresses, sameAddress  } = this.state;
    const addressP = addresses[0]
    let newAddress

    newAddress = {
      ...addressP,
      type: '1'
    };
    
    addresses[1] = newAddress    
    this.setState({ addresses: addresses, sameAddress: sameAddress? false : true });
  }

  updatePersonSummary = (person) => {
    this.setState({ person: person });
  }

  updateThirdPartySummary = (person) => {
    const { thirdParty } = this.state;
    const newThirParty = {
      ...thirdParty,
      ...person
    }
    this.setState({ thirdParty: newThirParty });
  }

  updateMailShipSummary = (propertyName, value) => {   
    const { mailship } = this.state;
    const newMailShip = {
      ...mailship,
      [propertyName]: value,
      
    };
    this.setState({ mailship: newMailShip});
  }

  updateMailShipPersonSummary = (person) => {
    const { mailship } = this.state;
    const newMailShip = {
      ...mailship,
      first: person.first,
      last: person.last,
      middle: person.middle,
      business: person.business
    }
    this.setState({ mailship: newMailShip });
  }

  updateAddressSummary = (address, index) =>  {
    const { addresses, sameAddress } = this.state;  
    const newAddresses = addresses

    newAddresses[index] = address      
    
    if (index === '0' && sameAddress) {
      newAddresses[1] = address
    }

    this.setState({addresses: newAddresses, sameAddress: (index === '1'? false : sameAddress)});      
  }

  updateExportImportSummary = (propertyName, value) => {   
    const { exportImport } = this.state;
    const newExportImport = {
      ...exportImport,
      [propertyName]: value,
      
    };
    this.setState({ exportImport: newExportImport});
  }

  updateCurrency = (currency, incurrencysummary, type, oper) =>  {
    // if(type === 'CUR') {
      const { currencies } = this.state;
      const selectedIndex = currencies.map(function(e) { return e.id; }).indexOf(currency.id);
      
      let updatedCurrencies = currencies;
      const newCurrency = currencies[selectedIndex];
      const updatedCurrency = {...newCurrency, 
        amount: currency.amount,
        usdEquivalent: currency.amount >= 1 ? Math.round(currency.amount * currency.exchangeRate) : 0
      };
      let ctotal = 0;

      updatedCurrencies[selectedIndex] = updatedCurrency;
      
      // if (oper === 'DEL') {
      //   updatedCurrencies = currencies
      //   updatedCurrencies.splice(index, 1)
      // } else if ((currencies.length - 1) >= index) {
      //   updatedCurrencies = currencies.map(curr => (curr.id === index ? currency : curr))
      // } else {
      //   updatedCurrencies = [...currencies, currency]
      // }

      updatedCurrencies.map(curr => (ctotal += curr.usdEquivalent));

      this.setState({
        incurrencysummary: incurrencysummary,
        currencies: updatedCurrencies,
        currTotal: ctotal
      });      
    // }   
  }

  handleError = (propertyName, error) => {
    const { requiredFields, exportimportsummary, mailshipSummary } = this.state;

    let newRequiredFields = {
      ...requiredFields,
      [propertyName]: (propertyName === 'signName' || propertyName === 'departureCountry' ||
         propertyName === 'departurePort' || propertyName === 'arriveCountry' || propertyName === 'arrivePort')? !error : error,      
    };

    if (!exportimportsummary) {
      if (newRequiredFields.person && newRequiredFields.departureCountry && newRequiredFields.departurePort 
            && newRequiredFields.arriveCountry && newRequiredFields.arrivePort) {
        newRequiredFields = { ...newRequiredFields, exportImport: true };
      } else {
        newRequiredFields = { ...newRequiredFields, exportImport: false };
      }
    }

    if (!mailshipSummary) {
      if (newRequiredFields.person && newRequiredFields.shipDate && newRequiredFields.receiveDate) {
          newRequiredFields = { ...newRequiredFields, mailship: true };
      } else {
          newRequiredFields = { ...newRequiredFields, mailship: false };   
      }
    }
    
    this.setState({ requiredFields: newRequiredFields });
  }

  computeTotal = (currencies) => {
    let total = 0;
    currencies.map(currency => {
      return total += Number(Math.round(currency.usdEquivalent))
    })
    return total;
  };

  render = () => {
    const { classes, activeStep, start, currencies, reviewPage, } = this.props
    const { person, addresses, thirdParty, exportImport, carrier, mailship, ship, coins, others, review, showClear, personalsummary, 
            thirdPartySummary, addresssummary, exportimportsummary, mailshipSummary, currencysummary, requiredFields, alertType } = this.state

    return (
      <div>
            <Paper className={classes.paperSummary}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Typography className={classes.signLabel}  align='left' gutterBottom>Personal Information</Typography>         
                {personalsummary && <Button size="small" variant="contained" color="primary" className={classes.button} onClick={() => this.toggleSummary('PI')}>
                  edit
                  <EditIcon className={classes.rightIcon} />                  
                </Button>}
                {!personalsummary && <Button size="small" variant="contained" color="error" className={classes.button} onClick={() => this.toggleSummary('PI')}>
                  Save
                  <SaveIcon className={classes.rightIcon} />                  
                </Button>}                
              </Grid>
              <Person person={person} reviewPage={true} locator='Review'  updatePerson={this.updatePersonSummary} summary={personalsummary} 
                      business={start.personalOrBusiness === 'B'? true : false} handleError={this.handleError} />

              <Typography className={classes.signLabel}  align='left' gutterBottom>{start.personalOrBusiness === 'B'? 'Business ' : undefined}
                Permanent Address in United States or Abroad
              </Typography>
              <Address address={addresses[0]} index='0' reviewPage={true} locator='Review' updateAddress={this.updateAddressSummary} summary={personalsummary} handleError={this.handleError} />
              
              {start.personalOrBusiness === 'P' &&
              <Typography className={classes.signLabel}  align='left' gutterBottom>Address while in the United States</Typography>
              }
              {start.personalOrBusiness === 'P' &&
              <Address address={addresses[1]} index='1' reviewPage={true} locator='Review' updateAddress={this.updateAddressSummary} summary={personalsummary} handleError={this.handleError} />
              }
            </Paper>
            
            { (start.pct === 'Y' || start.mailingOrPhysical === 'P') &&
            <Paper className={classes.paperSummary}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Typography className={classes.signLabel}  align='left' gutterBottom>
                          {exportImport.exportOrImport === 0? 'Import' : 'Export'} Information</Typography>
                {exportimportsummary && <Button size="small" variant="contained" color="primary" className={classes.button} onClick={() => this.toggleSummary('EI')}>
                  Edit
                  <EditIcon className={classes.rightIcon} />                  
                </Button>}
                {!exportimportsummary && <Button size="small" variant="contained" color="error" className={classes.button} onClick={() => this.toggleSummary('EI')}>
                  Save
                  <SaveIcon className={classes.rightIcon} />                  
                </Button>} 
              </Grid>              
              
              <Grid container direction="row" alignItems="center">
                <AsyncReactSelect name='departureCountry' label='Country of Departure' locator='Review' value={exportImport.departureCountry} placeholder='Search for Country' errorText='Please select country' 
                    optionsType='country' handleChange={this.updateExportImportSummary} required={true} disabled={exportImport.exportOrImport === 1 || exportimportsummary? true : false} 
                    summary={exportimportsummary} handleError={this.handleError} />
                <MDTextField name='departurePort' label='Departed City/Port' locator='Review' value={exportImport.departurePort}  handleChange={this.updateExportImportSummary} required={true} 
                    disabled={exportimportsummary? true : false} summary={exportimportsummary} handleError={this.handleError} />
                <AsyncReactSelect name='arriveCountry' label='Country of Arrival' locator='Review' value={exportImport.arriveCountry} placeholder='Search for Country' errorText='Please select country' 
                    optionsType='country' handleChange={this.updateExportImportSummary} required={true} disabled={exportImport.exportOrImport === 0 || exportimportsummary? true : false}
                    summary={exportimportsummary} handleError={this.handleError} />
                <MDTextField name='arrivePort' label='Arrival City/Port' locator='Review' value={exportImport.arrivePort}  handleChange={this.updateExportImportSummary} required={true} 
                    disabled={exportimportsummary? true : false} summary={exportimportsummary} handleError={this.handleError} />                
              </Grid>
            </Paper>
            }

{/* Mailed or Shipped - Regular Workflow */}
          {(start.pct === 'N' && start.mailingOrPhysical === 'M') && 
             <Paper className={classes.paperSummary}>
             <Grid container direction="row" alignItems="center" justifyContent="space-between">
               <Typography className={classes.signLabel}  align='left' gutterBottom>
                         {mailship.sendOrReceive === 0? 'Shipping' : 'Receiving'} Information</Typography>
               {mailshipSummary && <Button size="small" variant="contained" color="primary" className={classes.button} onClick={() => this.toggleSummary('MS')}>
                 Edit
                 <EditIcon className={classes.rightIcon} />                  
               </Button>}
               {!mailshipSummary && <Button size="small" variant="contained" color="error" className={classes.button} onClick={() => this.toggleSummary('MS')}>
                 Save
                 <SaveIcon className={classes.rightIcon} />                  
               </Button>} 
             </Grid> 

             <Person person={mailship} reviewPage={true} locator='Review' updatePerson={this.updateMailShipPersonSummary} summary={mailshipSummary} shiprecv={true} 
               business={mailship.business !== ''? true : false} handleError={this.handleError} />
               {/* <Person person={thirdParty} updatePerson={this.updateMailShipPersonSummary} summary={mailshipSummary} thirdParty={true} 
                     business={mailship.business !== ''? true : false} handleError={this.handleError} /> */}
             
             <Grid container direction="row" alignItems="center">
             {(mailship.sendOrReceive === -1 || mailship.sendOrReceive === 0) &&
               <MDTextField name='shipDate' label='Date Shipped' locator='Review' value={mailship.shipDate} type='date' handleChange={this.updateMailShipSummary} required={true} 
                   disabled={mailshipSummary? true : false} summary={mailshipSummary} handleError={this.handleError} />                
             }
             {mailship.sendOrReceive === 1 &&
               <MDTextField name='receiveDate' label='Date Received' locator='Review' value={mailship.receiveDate} type='date' handleChange={this.updateMailShipSummary} required={true} 
                   disabled={mailshipSummary? true : false} summary={mailshipSummary} handleError={this.handleError} />                
             }
               <MDTextField name='methodOfShipment' label='Method of Shipment' locator='Review' value={mailship.methodOfShipment}  handleChange={this.updateMailShipSummary} required={false} 
                   disabled={mailshipSummary? true : false} summary={mailshipSummary} handleError={this.handleError} />                
               <MDTextField name='carrierName' label='Carrier Name' locator='Review' value={mailship.carrierName}  handleChange={this.updateMailShipSummary} required={false} 
                   disabled={mailshipSummary? true : false} summary={mailshipSummary} handleError={this.handleError} />                    
             </Grid>

             <Typography className={classes.signLabel}  align='left' gutterBottom>{mailship.sendOrReceive === 0? 'Ship To' : 'Received From'}</Typography>
             <Address address={addresses[3]} index='3' reviewPage={true} locator='Review' updateAddress={this.updateAddressSummary} summary={mailshipSummary} handleError={this.handleError} />    
           </Paper>

            }

{/* Mailed or Shipped - Currency Transporter Workflow */}
          {(start.pct === 'Y' && start.mailingOrPhysical === 'M') && 
            <Paper className={classes.paperSummary}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Typography className={classes.signLabel}  align='left' gutterBottom>Shipping/Receiving Information</Typography>

                {mailshipSummary && <Button size="small" variant="contained" color="primary" className={classes.button} onClick={() => this.toggleSummary('MS')}>
                  Edit
                  <EditIcon className={classes.rightIcon} />                  
                </Button>}
                {!mailshipSummary && <Button size="small" variant="contained" color="error" className={classes.button} onClick={() => this.toggleSummary('MS')}>
                  Save
                  <SaveIcon className={classes.rightIcon} />                  
                </Button>} 
              </Grid> 
              
              <Grid container direction="row" alignItems="center">             
                <MDTextField name='shipDate' label='Date Shipped' locator='Review' value={mailship.shipDate} type='date' handleChange={this.updateMailShipSummary} required={true} 
                    disabled={mailshipSummary? true : false} summary={mailshipSummary} handleError={this.handleError} />             
                <MDTextField name='methodOfShipment' label='Method of Shipment' locator='Review' value={mailship.methodOfShipment}  handleChange={this.updateMailShipSummary} required={false} 
                    disabled={mailshipSummary? true : false} summary={mailshipSummary} handleError={this.handleError} />                
              </Grid>

              <Typography className={classes.signLabel}  align='left' gutterBottom>Carrier Information</Typography>
              <Person person={carrier} reviewPage={true} locator='Review' updatePerson={this.updateMailShipPersonSummary} summary={mailshipSummary} shiprecv={true} 
                business={carrier.business !== ''? true : false} handleError={this.handleError} />
              
              <Typography className={classes.signLabel}  align='left' gutterBottom>Receiver Information</Typography>
              <Person person={mailship} reviewPage={true} locator='Review' updatePerson={this.updateMailShipPersonSummary} summary={mailshipSummary} shiprecv={true} 
                business={mailship.business !== ''? true : false} handleError={this.handleError} />
              {/* <Typography className={classes.signLabel}  align='left' gutterBottom>Receiver Address</Typography> */}
              <Address address={addresses[3]} index='3' reviewPage={true} locator='Review' updateAddress={this.updateAddressSummary} summary={mailshipSummary} handleError={this.handleError} />

              <Typography className={classes.signLabel}  align='left' gutterBottom>Shipper Information</Typography>
              <Person person={ship} reviewPage={true} locator='Review' updatePerson={this.updateMailShipPersonSummary} summary={mailshipSummary} shiprecv={true} 
                business={ship.business !== ''? true : false} handleError={this.handleError} />
              {/* <Typography className={classes.signLabel}  align='left' gutterBottom>Shipper Address</Typography> */}
              <Address address={addresses[4]} index='3' reviewPage={true} locator='Review' updateAddress={this.updateAddressSummary} summary={mailshipSummary} handleError={this.handleError} />    
            </Paper>
            }

            {(start.myselfOrSomeone === 'S') && 
            <Paper className={classes.paperSummary}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Typography className={classes.signLabel}  align='left' gutterBottom>Third Party Information</Typography>         
                {thirdPartySummary && <Button size="small" variant="contained" color="primary" className={classes.button} onClick={() => this.toggleSummary('TP')}>
                  Edit
                  <EditIcon className={classes.rightIcon} />                  
                </Button>}
                {!thirdPartySummary && <Button size="small" variant="contained" color="error" className={classes.button} onClick={() => this.toggleSummary('TP')}>
                  Save
                  <SaveIcon className={classes.rightIcon} />                  
                </Button>}                
              </Grid>              
              <Person person={thirdParty} reviewPage={true} updatePerson={this.updateThirdPartySummary} summary={thirdPartySummary} thirdParty={true} 
                      business={thirdParty.business !== ''? true : false} locator='Review' handleError={this.handleError} />
              
              <Typography className={classes.signLabel}  align='left' gutterBottom>Third Party Address</Typography>              
              <Address address={addresses[2]} index='2' reviewPage={true} locator='Review' updateAddress={this.updateAddressSummary} summary={thirdPartySummary} handleError={this.handleError} />              
            </Paper>
            }

            <Paper className={classes.paperSummary}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">              
              <Typography className={classes.signLabel}  align='left' gutterBottom>Monetary instruments Information</Typography>
               {currencysummary && <Button size="small" variant="contained" color="primary" className={classes.button} onClick={() => this.toggleSummary('MI')}>
                  Edit
                  <EditIcon className={classes.rightIcon} />                  
                </Button>}
                {!currencysummary && <Button size="small" variant="contained" color="error" className={classes.button} onClick={() => this.toggleSummary('MI')}>
                  Save
                  <SaveIcon className={classes.rightIcon} />                  
                </Button>}  
              </Grid>

              <Grid container direction="row" justifyContent="space-around" alignItems="flex-end"> 
                <Grid item className={classes.mediumWide}><Typography className={classes.headerText} id="info1">Type</Typography></Grid>
                <Grid item className={classes.largeWide}><Typography className={classes.headerText2} id="info2">Country/Currency</Typography></Grid>
                <Grid item className={classes.mediumWide2}><Typography className={classes.headerText2} id="info2">Amount</Typography></Grid>
                <Grid item className={classes.smallWide}><Typography className={classes.headerText} id="info2">Rate</Typography></Grid>
                <Grid item className={classes.smallWide2}><Typography className={classes.headerText2} id="info2">USD</Typography></Grid>
              </Grid>  

              {currencies.map((currency, index) =>
                <CurrencyItem activeStep={activeStep} currency={currency} index={currency.id} locator='Review' updateCurrency={this.updateCurrency} type='CUR' summary={currencysummary} />
              )}

              <Typography className={classes.secondaryHeading}> 
                Total: <NumberFormat value={(this.computeTotal(currencies, coins, others))} isNumericString={true} displayType={'text'} 
                          decimalScale={0} maximumfractiondigits={0} minimumfractiondigits= {0} thousandSeparator={true} prefix={'$'} /> USD
              </Typography> 

            <Grid className={classes.root} container direction="row" justifyContent="flex-start" alignItems="flex-end"> 
              <Grid item className={classes.xWide}><Typography className={classes.staticText} id="info1">*NI: Negotiable Instruments</Typography></Grid>
              <Grid item className={classes.xWide}><Typography className={classes.staticText} id="info2">*II: Incomplete Instruments</Typography></Grid>
            </Grid>                          

            </Paper>
            
            <Paper className={classes.paper}>
            <Grid container direction="column" alignItems="flex-start">
              <Typography className={classes.signLabel}  align='left' gutterBottom>Signature
              <span data-tip="Sign digitally on the signature area">
                <IconButton className={classes.iconButton} aria-label="Signature">
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton></span>
                 <ReactTooltip/>
                </Typography>
              <SignatureCanvas ref={(ref) => { this.sigCanvas = ref}}
                  penColor='black' backgroundColor='#ffffff' onBegin={this.enableClearButton} onEnd={this.captureSignature}
                  canvasProps={{className: classes.sigCanvas, 'aria-label':"canvas optional"}}></SignatureCanvas>

              {showClear &&
              <Button variant="text" color="secondary"                
                onClick={this.clearSign}
                className={classes.button}
              >
                Clear
              </Button>
              }
            </Grid>
            <Grid container direction="row" alignItems="center">
              <MDTextField name='signName' label='Name' value={review.signName} handleChange={this.handleChange} handleError={this.handleError} required={true} inputProps={{maxLength: 50,}} />
              <MDTextField name='signTitle' label='Title' value={review.signTitle}  handleChange={this.handleChange} required={false} inputProps={{maxLength: 50,}} />              
              <MDTextField name='signDate' label='Date of Signature' value={review.signDate}  disabled={true} required={true} />              
            </Grid>

            <Grid container direction="row" alignItems="center"  className={classes.checkboxBackground}>
              <FormControlLabel 
                    classes={{ label: classes.label }}
                      control={
                      <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={review.declaration}
                      onChange={this.handleDeclarationChecked('declaration')}
                      value={review.declaration}
                    />
                    }
                    label="Under penalties of perjury, I declare that I have examined this report, and to the best of my knowledge and belief is true, correct and complete."
              />                  
            </Grid>

            <Grid container direction="row" alignItems="center" className={classes.checkboxBackground}>
              <FormControlLabel
                    classes={{ label: classes.label }}
                      control={
                      <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={review.acknowledgement}
                      onChange={this.handleAcknowledgementChecked('acknowledgement')}
                      value={review.acknowledgement}
                    />
                    }
                    label="I acknowledge that I am the transporter of the shipment, or the person that sent or received the monetary instruments through mail or courier."
              />                  
            </Grid>
               
                <NavigationButtons review={review} allReqDone={requiredFields.signName && review.declaration && review.acknowledgement 
                    && personalsummary && exportimportsummary && thirdPartySummary && mailshipSummary && currencysummary} 
                     formCompleted={this.props.formCompleted} />
          </Paper>

          <Popup open={this.state.open} 
            modal closeOnDocumentClick={false} >
            {close => (
            <Grid className={classes.modal}>
            
            <Grid className={classes.modalHeader}>{alertType === 'R'? 'Required Information' : 'USD Equivalent Totals'}</Grid>
              <hr/>
                <Grid className={classes.modalContent}>
                  {alertType === 'R'? 'Please complete all the required fields before saving.' : 
                  'Total of all USD Equivalent is either less than $10,000 or more than $2,147,483,647. Please check the Amount entered.'}
                </Grid>
             
              <Grid className={classes.buttonBar} container direction="row" justifyContent="center" alignItems="center">
                <Button variant="outlined" color="secondary" onClick={() => {this.closepopup()}} className={classes.cancelButton}>Close</Button>               
              </Grid>
            </Grid>
            )}
        </Popup>

       </div>
    );
  }
}

export default withStyles(styles) (ReviewSign);