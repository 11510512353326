const coins = (state = [coin], action) => {
    switch (action.type) {
      case 'RESET_COIN_ITEM':
        return [coin]
      case 'ADD_COIN_ITEM':
        let newCurrencyItem = {...coin, id:state.length}       
        return [...state, newCurrencyItem]
      case 'REMOVE_COIN_ITEM':
        let remainingcoins = [
          ...state.slice(0, action.id),
          ...state.slice(action.id + 1)
        ]        
        return remainingcoins
      case 'UPDATE_COINS':
        let newState = action.coins;
        return newState        
      default:
        return state    
    }
  }
  
  const coin = {
      id: 0,
      country: '',
      currencyName: '',
      exchangeRate: '',
      amount: '',
      usdEquivalent: '',
  }

  export default coins