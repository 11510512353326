import { connect } from 'react-redux'
import ThirdPartyInformation from '../component/ThirdPartyInformation'

const mapStateToProps = state => ({
  start: state.start,
  activeStep: state.activeStep,
  person: state.thirdparty,
  addresses: state.addresses
})

const mapDispatchToProps = (dispatch) => ({
  
})

export default connect(mapStateToProps, mapDispatchToProps) (ThirdPartyInformation);
