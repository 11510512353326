import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  reqstar: {
    color: '#9C2F36',
    marginRight: '2px', 
  },
  headingline3: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '400',
  },
})

class RequiredText extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container direction="row">
        <Typography className={classes.reqstar}>*</Typography>
        <Typography className={classes.headingline3}>required fields</Typography>
      </Grid>
    )
  }y
}

export default withStyles(styles) (RequiredText);