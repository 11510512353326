import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Grid } from '@material-ui/core';
import  ProgressCircle  from './errorHandling/ProgressCircle';
import ErrorDialog from './errorHandling/ErrorDialog';
import { trackPromise } from 'react-promise-tracker'
import { Create } from '../service/CreateProvisionalCMIR';

const styles = theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
    [theme.breakpoints.down('xs')]: { 
      width: 112,
    }, 
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonBar: {
    height: 100,
  }, 
});

class HorizontalStepperButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeStep: props.activeStep, nextStep: props.nextStep, 
      previousStep: props.previousStep, errorMessage:'', recaptchaToken: null, recaptchaTokenVerify: false};
  }

  getRecapthcaSiteKeyFromProxy = () => {    
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      return process.env.REACT_APP_RECAP_SITE_KEY;
    } else {           
      var req = new XMLHttpRequest();
      req.open('GET', document.location, false);
      req.send(null);
      var headers = req.getAllResponseHeaders();
      var splitted = headers.split('\r\n');  
      // console.log('split ' + JSON.stringify(splitted));      
      let obj = splitted.find(x => x.startsWith('referer_first_let'));
      let token = obj.substring(19);
      return token;
    }
  }

  getRecapthcaSecretKeyFromProxy = () => {   
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      return process.env.REACT_APP_RECAP_SECRET_KEY;
    } else {        
      var req = new XMLHttpRequest();
      req.open('GET', document.location, false);
      req.send(null);
      var headers = req.getAllResponseHeaders();
      var splitted = headers.split('\r\n');  
      // console.log('split ' + JSON.stringify(splitted));      
      let obj = splitted.find(x => x.startsWith('referer_next_let'));
      let token = obj.substring(18);
      return token;
    }
}

  reCaptchaCallback = (token) => {
    // Here you will get the final recaptchaToken!!!  
    console.log(JSON.stringify(token), "<= your recaptcha token")
    this.setState({ recaptchaToken: token })

    fetch('/siteverify?secret='+this.getRecapthcaSecretKeyFromProxy()+'&response='+token, { 
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'        
      }      
    })
    .then(response => response.json())
    .then(result => {
        let jsonresult = JSON.stringify(result)
        console.log('verify response ' + jsonresult)

        if (result.success) {          
          this.setState({ recaptchaTokenVerify: true })
        }
    })
  }

  testOnClick(event){
    trackPromise(new Promise((resolve, reject) =>{setTimeout(function(){resolve('result')},900000)}));
    this.props.updateStart(this.props.start);
  }

  resetAll() {
    this.props.resetStep();
    this.props.resetStart();
    this.props.resetPerson();
    this.props.resetAddress();
    this.props.resetExportImport();
    this.props.resetMailShip();
    this.props.resetThirdParty();
    this.props.resetCurrencyItem();
    this.props.resetCoinItem();
    this.props.resetOtherItem();
    this.props.resetReviewSign();
    this.props.resetReceipt();
  }

  onClick(event) {
    if (this.props.activeStep === 0) {
      //This is for the Get Started Panel
      // this.props.updateStart(this.props.start);
    } else 
    if (this.props.activeStep === 1) {
      this.props.updatePerson(this.props.person);
      this.props.updateAddress(this.props.addresses);
      // if (this.props.start.pct === 'N') {
      //   this.props.nextStep();
      // }
    // eslint-disable-next-line
    } else if ((this.props.activeStep === 2 && this.props.start.mailingOrPhysical === 'P')  ||
    // eslint-disable-next-line
                this.props.activeStep === 2 && this.props.start.pct === 'Y') {
      this.props.updateExportImport(this.props.exportImport);
    } else if (this.props.activeStep === (this.props.start.pct === 'N'? 2 : 3) && this.props.start.mailingOrPhysical === 'M') {
      this.props.updateMailShip(this.props.mailship, this.props.mailshipPerson);
      this.props.updateAddress(this.props.addresses);
      if (this.props.start.pct === 'Y') {
        this.props.updateCarrier(this.props.carrier);
        this.props.updateShip(this.props.ship);
      }
      // console.log('addr in stepper ' + JSON.stringify(this.props.addresses))
    } else if (this.props.activeStep === (this.props.start.pct === 'N'? 3 : 4) && this.props.start.myselfOrSomeone === 'S') {
      this.props.updateThirdParty(this.props.thirdparty);
      this.props.updateAddress(this.props.addresses);
    } else if (this.props.activeStep === (this.props.start.pct === 'Y'? 5 : this.props.start.myselfOrSomeone === 'S'? 4 : 3)) {
      this.props.updateCurrencies(this.props.currencies);
      // this.props.updateCoins(this.props.coins);
      // this.props.updateOthers(this.props.others);
    } else if (this.props.activeStep === (this.props.start.pct === 'Y'? 6 : this.props.start.myselfOrSomeone === 'S'? 5 : 4)) {    
      this.props.formCompleted(false)  
      this.props.updateReviewSign();

      let filledAddresses = [...this.props.addresses];

      //Check to see if address is pre-sliced
      if(filledAddresses.length === 5){
        //Remove unused addresses (third party & ship/receive        
        if (this.props.start.pct === 'N') {
          if (this.props.start.mailingOrPhysical !== 'M') {
            filledAddresses.splice(-1, 2);
          } else {
            filledAddresses.splice(-1, 1);
          }          
        }
        if (this.props.start.myselfOrSomeone !== 'S') {
          filledAddresses.splice(2, 1);
        }
        if (this.props.start.personalOrBusiness === 'B') {
          filledAddresses.splice(1, 1);
        }
      }
      // console.log('after in stepper ' + JSON.stringify(this.props.addresses))

      trackPromise(
        Create(this.props.start, this.props.person, filledAddresses, this.props.thirdparty, this.props.exportImport, 
          this.props.carrier, this.props.mailship, this.props.ship,
               this.props.currencies, this.props.coins, this.props.others, this.props.review)
             .then(confirmationNumber => {this.props.updateReceipt(confirmationNumber); return new Promise((resolve, reject) => resolve('/confirm')) })
            //  .then(result => {return new Promise((resolve, reject) =>{setTimeout(function(){resolve(result)},3000)})}) //TODO: Dont add line to prd, test code for timeout
             .then(resolve => this.props.history.push(resolve))
             .catch(err => {console.log("HorizontalStepperButtons - Error caught Error: ".concat(err," message: ", err.message)); this.setState({errorMessage: err.message})})
      )    
    } else {     
      this.props.history.push('/')
    }
    if (this.props.activeStep !== (this.props.start.myselfOrSomeone === 'S'? 6 : 5))
      this.props.nextStep();
 }

  render() {
    const { classes, activeStep, start, previousStep, allReqDone } = this.props;
    const totalSteps = start.myselfOrSomeone === 'S'? 6 : 5;
    
    return (
      <div className={classes.root}>          
        <Grid className={classes.buttonBar} container direction="row" justify="flex-end" alignItems="center">
          <Button variant="outlined" color="secondary"            
            onClick={() => activeStep === 0? this.resetAll() : previousStep()}
            className={classes.button}>
            {activeStep === 0 ? 'Reset' : 'Previous'}
          </Button>

          <ProgressCircle/>

          {/* {console.log("Horizontal Stepper Buttons - errorMessage Value: "+ this.state.errorMessage)} */}
          {this.state.errorMessage && <ErrorDialog errorMessage={this.state.errorMessage} onDismiss={() =>this.setState({errorMessage: ''})}/>}       
          
          
          {/* {start.pct === 'N' && */}
          <Button variant="contained" color="primary" onClick={() => this.onClick()}
            disabled={(allReqDone !== undefined && !allReqDone)}
            className={classes.button}>
              {activeStep === totalSteps ? 'Complete' : 'Next'}
          </Button>
          {/* } */}
          
           {/* <GoogleReCaptchaProvider
            reCaptchaKey={this.getRecapthcaSiteKeyFromProxy()}
            useEnterprise="true"
           >
            <GoogleReCaptcha onVerify={this.reCaptchaCallback} />
           </GoogleReCaptchaProvider> */}
          {/* <ReCaptcha
            ref={ref => this.recaptcha = ref}
            sitekey={this.getRecapthcaSiteKeyFromProxy()}
            action='create_ecmir'
            verifyCallback={this.reCaptchaCallback}
            // render='explicit' 
            // size= 'compact'
        /> */}
        </Grid>
      </div>
    );
  }
}

HorizontalStepperButtons.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles(styles)(HorizontalStepperButtons));
