import { connect } from 'react-redux'
import MailShipCT from '../component/MailShipCT'

const mapStateToProps = state => ({
  start: state.start,
  activeStep: state.activeStep,
  mailship: state.mailship,
  ship: state.ship,
  carrier: state.carrier,
  addresses: state.addresses,
  person: state.person
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps) (MailShipCT);
