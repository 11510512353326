import React from 'react';
import Flag from 'react-world-flags'

const addresses = (state = initialAddresses, action) => {
    switch (action.type) {
      case 'RESET_ADDRESS':        
        return [{...initialAddress, type:'P'}, 
        {...initialAddress, type:'1', country:{
          value: 'USA',
          label: [<Flag code='USA' height="12" />, ' ',  'UNITED STATES'],      
          countryDescription: 'UNITED STATES'
        }}, 
        {...initialAddress, type:'O'}, 
        {...initialAddress, type:'S'},
        {...initialAddress, type:'R'}]
      case 'UPDATE_ADDRESS':  
        return action.addresses      
      default:
        return state    
    }
  }

  const initialAddress = {
    type: '',
    houseNumber: '',
    streetName: '',
    apartmentNumber: '',
    city: '',      
    state: '',
    country: '',
    zip: '',
    othercity: ''
}

  const initialAddresses = [{...initialAddress, type:'P'}, 
  {...initialAddress, type:'1', country:{
    value: 'USA',
    label: [<Flag code='USA' height="12" />, ' ',  'UNITED STATES'],      
    countryDescription: 'UNITED STATES'
  }}, 
  {...initialAddress, type:'O'}, 
  {...initialAddress, type:'S'},
  {...initialAddress, type:'R'}]

  export default addresses