export const getJWTTokenFromProxy = () => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    return process.env.REACT_APP_JWT_TOKEN;
  } else {    
    var req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);
    var headers = req.getAllResponseHeaders();
    var splitted = headers.split('\r\n');  
    // console.log('split ' + JSON.stringify(splitted));      
    let obj = splitted.find(x => x.startsWith('referer_intro_let'));
    let jwttoken = obj.substring(18);
    return jwttoken;
  }
}

const request = (receipt, email) =>  {
  return {
      // applicationDate: receipt.submissionDate,
      confirmationNumber: receipt.confirmationNumber,
      eMailAddress: email,
      // expirationDate: receipt.expireDate,
      // filerPersonOrBusiness: {key: receipt.business !== ''? 'B' : 'P'},
      // isCurrencyTransport: receipt.currencyTransporter === 'Y'? 'Y' : 'N',
      // name: {
      //   firstName: receipt.firstName,
      //   lastName: receipt.business !== ''? receipt.business : receipt.lastName
      // },
      // totalAmount: receipt.totalUSD.toLocaleString('en', { style: 'currency', currency: 'USD', useGrouping: true, maximumfractiondigits:0, minimumfractiondigits: 0 }),
      // attachment: (receipt.sigQrBase64).substring(22, receipt.sigQrBase64.length + 1)
            
  }
}

let provisionalCMIR = '/provisionalCMIR';

//this function will be used during unit test
export const url = (inurl) => {
  provisionalCMIR = inurl
}

export const SendEmail = (receipt, email) => {
  return fetch(provisionalCMIR + '/sendEmail', { 
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + getJWTTokenFromProxy(),
    },
    body: 
      JSON.stringify( request(receipt, email) )
  })        
  // Try to parse the response
  .then(response =>
     ({
      headers: response.headers,
      status: response.status
    })
  )
  .then(
    // Both fetching and parsing succeeded!
    ({ headers, status }) => {
      if (status >= 400) {
        // Status looks bad
        console.log('Bad status ' + headers)
      } else {
        // Status looks good
        console.log(headers.get('confirmationnumber'))
        return status
      }
    },
    // Either fetching or parsing failed!
    err => {
      console.log('Error ' + err)
    }
  );
}

export const downloadReceipt = (receipt) => {
  return fetch(provisionalCMIR + '/downloadConfirmation', { 
    method: "POST",
    headers: {
      'Accept': 'application/pdf',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJWTTokenFromProxy(),
    },
    body: 
      JSON.stringify( request(receipt, '') )
  })        
  // Try to parse the response
  .then(response => {
    let filename = response.headers.get('Content-Disposition').split('filename=')[1]
  
    response.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      // console.log(url)
      
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    })
  })
  
}
