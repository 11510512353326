import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import iconofficialflagUrl from '../images/usFlag.png';
import dhslogoUrl from '../images/dhs_logo.png';
import dhslogowhiteUrl from '../images/CBPlogoWhite.png'
import fincenlogoUrl from '../images/FINCEN_logo.png';
import headerflagUrl from '../images/US_flag_3.jpg';
import eCMIRLogoUrl from '../images/eCMIRBannerLogo.png';
import { Grid, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexgrow: 1,
    // marginTop: '5px',
  },
  cmirHeader: {
    display: 'flex',
    width: '100%',
    height:	'140px',
    /* min-height:     180px; */
    background: 'transparent',
    /* background: 	#112E51; */
    // justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  headerFlag: {   
    backgroundColor: '#1c3764', 
    backgroundImage: "url(" + headerflagUrl + ")",
    backgroundRepeat: "no-repeat",
    // backgroundPosition: "top right",
    backgroundPosition: "-440px 28px",
		backgroundSize: "auto",
		// backgroundAttachment: "fixed",			
    // float: 'left',
    // height: '128px',
    // width: '100%',
  },
  dhsSeal: {
    borderWidth: '0px',
    // width: '75px',
	  // height: '75px',	  
    margin: '10px',
    paddingLeft: '30px',
    [theme.breakpoints.down('xs')]: {      
      content: "url(" + dhslogoUrl + ")",
      height: '60px',
      paddingLeft: '16px',
    },
    [theme.breakpoints.up('sm')]: {      
      content: "url(" + dhslogoUrl + ")",
      height: '75px',
    },
    [theme.breakpoints.up('md')]: {      
      content: "url(" + dhslogowhiteUrl + ")",
      height: '65px',      
    },
    [theme.breakpoints.up('lg')]: {      
      content: "url(" + dhslogowhiteUrl + ")",
      height: '75px',
    },    
    // paddingRight: '4px',
    // borderRight: '1px solid white',
  },
  fincenSeal: {
    borderWidth: '0px',
    width: '75px',
	  height: '75px',	  
    margin: '10px',
    paddingLeft: '10px',
    [theme.breakpoints.down('xs')]: {      
      width: '60px',
	    height: '60px',	  
    },
  },
  usofficial: {
    color: '#fff',
    fontSize: '0.9em',    
    background: '#323232',    
    height:		    '28px',
    // minHeight:     '25px',
    width: '100%',
  },
  usofficialimg: {
    width: '22px',
    paddingLeft: '24px',
    // paddingTop: '6px'
  },  
  usofficialspan: {
    paddingLeft: '10px',
    fontFamily: 'Georgia',
    fontSize: '12px'
  },
  cbptext: {
    color: '#ffffff00',
    // paddingRight: '15px',
    borderRight: '1px solid white',
    display: 'block',
    // fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'Times New Roman, Times, serif',    
    [theme.breakpoints.down('xs')]: {      
      fontSize: '0px',
    },
    [theme.breakpoints.up('sm')]: {      
      fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.up('md')]: {      
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('lg')]: {      
      fontSize: theme.typography.pxToRem(18),
    }, 
  },
  cmirtext: {
    color: 'white',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '32px',    
    marginLeft: '32px',    
  },  
  cmirtextspan: {
    display: 'block',
  },

  grow: {
    flexgrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  tresuryText1: {
    color: '#FFFFFF',
    marginLeft: '7px',
    [theme.breakpoints.down('xs')]: {      
      fontSize: '0px',
    },
    [theme.breakpoints.up('sm')]: {      
      // fontSize: theme.typography.pxToRem(12),
      fontSize: '0px',
    },
    [theme.breakpoints.up('md')]: {      
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('lg')]: {      
      fontSize: theme.typography.pxToRem(22),
    },  
  },
  tresuryText2: {
    color: '#FFFFFF',
    marginLeft: '7px',
    [theme.breakpoints.down('xs')]: {      
      fontSize: '0px',
    },
    [theme.breakpoints.up('sm')]: {      
      fontSize: '0px',
      // fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.up('md')]: {      
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.up('lg')]: {      
      fontSize: theme.typography.pxToRem(16),
    },    
  },
  titleText1: {
    color: '#FFFFFF',    
    marginRight: '32px',
    // [theme.breakpoints.down('xs')]: {
    //   fontWeight: '525',
    //   fontSize: theme.typography.pxToRem(10),
    // },
    [theme.breakpoints.up('sm')]: {
      fontWeight: '550',
      fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.up('md')]: {
      fontWeight: '570',
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('lg')]: {      
      fontWeight: '600',
      fontSize: theme.typography.pxToRem(20),
    },
  },
  titleText2: {
    color: '#FFFFFF',    
    marginRight: '32px',
    [theme.breakpoints.down('xs')]: {
      // fontWeight: '525',
      fontSize: '0px',
    },
    [theme.breakpoints.up('sm')]: {
      // fontWeight: '550',
      fontSize: '0px',
    },
    [theme.breakpoints.up('md')]: {
      fontWeight: '570',
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('lg')]: {      
      fontWeight: '600',
      fontSize: theme.typography.pxToRem(20),
    },
  },
  ul: {
    listStyle: 'none',
    display: 'inline-flex',
    alignContent: 'flex-start',
    marginLeft: '10px',
    // marginBlockEnd: '0px',
    // marginBlockStart: '0px'
    // marginTop: '5px'
    },
  
  li: {
    fontSize: '1.0em',
    textAlign: 'center',
    paddingRight: '15px',
  },
  marginTop: {
    marginTop: '25px'
  },
  topheader: {
    padding: '8px 33px 7px 40px',
  },
  currencytext: {
    color: '#1E1E1E',
    fontWeight: '654',    
  },
  needhelpText: {    
    fontWeight: '500',
    color: '#87CEFA',
  },
  needHelpImage: {    
    paddingRight: '6px',    
  },
  ecmirlogo: {   
    marginRight: 3.5 +'em', 
    marginTop: 0.6 +'em', 
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginRight: 1.5 +'em', 
      height: "58px",
      width: "132px",
    },
    [theme.breakpoints.up('sm')]: {
      height: "68px",
      width: "188px",
    },
    [theme.breakpoints.up('md')]: {
      height: "78px",
      width: "220px",
    },
    [theme.breakpoints.up('lg')]: {      
      height: "78px",
      width: "220px",
    },
  }
});

class Header extends Component {

  render = () => {
  const { classes } = this.props;
  return (
<Grid className={classes.headerFlag}>    
    <Grid className={classes.cmirHeader}>        
        <Grid container className={classes.usofficial} alignItems={"center" }>            
            <img className={classes.usofficialimg} id="usflag" src={iconofficialflagUrl} alt="US Flag"/>
            <span className={classes.usofficialspan}>Official Website of the Department of Homeland Security</span>            
        </Grid>

        <Grid container className={classes.root} alignItems={"flex-start"} direction={"row"} justifyContent={"space-between"}>            
          <Grid>
            <Grid container alignItems={"flex-start"} direction={"row"} >             
              <Grid >
              <a href="https://www.cbp.gov" target="_blank" rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <img className={classes.dhsSeal} id="dhslogo" src={dhslogowhiteUrl}
                title="Official Seal of the U.S. Customs and Border Protection, U.S. Department of Homeland Security" alt="DHS Logo"/>             
              </a>
              </Grid>              
              <Grid className={classes.marginTop}>              
                <Typography className={classes.cbptext}>|</Typography>
                <Typography className={classes.cbptext}>|</Typography>
              </Grid>
              
              <Grid >
                <a href="https://www.fincen.gov/" target="_blank" rel='noopener noreferrer' style={{ textDecoration: 'none' }}>              
                <img className={classes.fincenSeal} id="fincenlogo" src={fincenlogoUrl} 
                title="Financial Crimes Enforcement Network, U.S. Department of the Treasury" alt="FINCEN Logo"/>
                </a>
              </Grid>

              <Grid className={classes.marginTop}>              
                <Typography className={classes.tresuryText1}>U.S. Treasury</Typography>
                <Typography className={classes.tresuryText2}>Financial Crimes Enforcement Network</Typography>
              </Grid>
            </Grid>
            </Grid>    

            <Grid>
              <Grid container alignItems={"flex-end"} direction={"column"} justifyContent={"center"}>
              <NavLink to="/" rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <img className={classes.ecmirlogo} id="ecmirlogo" title="FinCEN Form 105" src={eCMIRLogoUrl} alt="eCMIR Logo"/>
              </NavLink>
              </Grid>
            </Grid>         
        </Grid>                     
    </Grid>
  </Grid>
  );
}
}


export default withStyles(styles)(Header);



