import { connect } from 'react-redux'

import { resetStart, resetStep, resetPerson, resetAddress, resetThirdParty, resetMailShip, resetExportImport, 
            resetCurrencyItem, resetCoinItem, resetOtherItem, resetReviewSign, resetReceipt,
            nextStep, previousStep, updateStart, updatePerson, updateAddress, updateThirdParty, updateExportImport, 
            updateCarrier, updateMailShip, updateShip,
            updateCurrencies, updateCoins, updateOthers, updateReviewSign, updateReceipt} from '../actions'
import HorizontalStepperButtons from '../component/HorizontalStepperButtons'

const mapStateToProps = state => ({  
  activeStep: state.activeStep,
  start: state.start,
  person: state.person,
  addresses: state.addresses,
  thirdparty: state.thirdparty,
  exportImport: state.exportImport,
  carrier: state.carrier,
  mailship: state.mailship,
  ship: state.ship,
  currencies: state.currencies,
  coins: state.coins,
  others: state.others,
  // review: state.review,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  nextStep: () => dispatch(nextStep()),
  previousStep: () => dispatch(previousStep()),
  updateStart: () => dispatch(updateStart(ownProps.start)),
  updatePerson: () => dispatch(updatePerson(ownProps.person)),
  updateAddress: () => dispatch(updateAddress(ownProps.addresses)),
  updateThirdParty: () => dispatch(updateThirdParty(ownProps.thirdparty)),
  updateExportImport: () => dispatch(updateExportImport(ownProps.exportImport)),
  updateCarrier: () => dispatch(updateCarrier(ownProps.carrier)),
  updateMailShip: () => dispatch(updateMailShip(ownProps.mailship, ownProps.mailshipPerson)),
  updateShip: () => dispatch(updateShip(ownProps.ship)),
  updateCurrencies: () => dispatch(updateCurrencies(ownProps.currencies)),
  updateCoins: () => dispatch(updateCoins(ownProps.coins)),
  updateOthers: () => dispatch(updateOthers(ownProps.others)),
  updateReviewSign: () => dispatch(updateReviewSign(ownProps.review)),
  updateReceipt: (confirmationNumber) => dispatch(updateReceipt(confirmationNumber)),

  resetStart: () => dispatch(resetStart()),
  resetStep: () => dispatch(resetStep()),
  resetPerson: () => dispatch(resetPerson()),
  resetAddress: () => dispatch(resetAddress()),
  resetExportImport: () => dispatch(resetExportImport()),
  resetMailShip: () => dispatch(resetMailShip()),
  resetThirdParty: () => dispatch(resetThirdParty()),
  resetCurrencyItem: () => dispatch(resetCurrencyItem()),
  resetCoinItem: () => dispatch(resetCoinItem()),
  resetOtherItem: () => dispatch(resetOtherItem()),
  resetReviewSign: () => dispatch(resetReviewSign()),
  resetReceipt: () => dispatch(resetReceipt()),  
})

export default connect(mapStateToProps, mapDispatchToProps) (HorizontalStepperButtons);
