import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Paper, Button, Grid, Divider } from '@material-ui/core'
import Popup from 'reactjs-popup';
import { withStyles } from '@material-ui/core/styles'
import Needhelp from '../images/needhelp.jpg'
import Getstarted from '../images/getStarted.png';
import Personal from '../images/personal.png';
import OriginAndDestination from '../images/originanddestination.png';
import CurrencyInfomation from '../images/currencyInformation.png';
import Currency from '../images/currencies_background.jpg';
import ReviewandSign from '../images/reviewandSign.png';
import ShipReceive from '../images/shiprecv.png';
import ThirdParty from '../images/thirdparty.png';
import { LoadCombinedReference } from '../service/ReferenceData';

const styles = theme => ({
  root: {
    flexGrow: 1,    
    // marginTop: '5px',
    // marginBottom: '16px',
  },
  landingHeader: {
    display: 'flex',
    width: '100%',
    background: 'transparent',    
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  landing: {    
    backgroundRepeat: "no-repeat",
		backgroundPosition: "top center",
		backgroundSize: "cover",		
    float: 'left',
    width: '100%',
  },
  paper: {
    backgroundColor: '#1c3764',
    backgroundImage: "url(" + Currency + ")",    
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
    backgroundSize: "cover",
  },
  currencyimg: {    
    backgroundImage: "url(" + Currency + ")",    
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    backgroundSize: "cover",
  },
  subpaper: {    
    paddingTop: '20px',
    paddingBottom: '20px',    
    color: theme.palette.text.primary,
    backgroundColor: '#DCDCDC'
  },
  topheader: {
    padding: '0px 33px 3px 40px',
  },
  currencytext: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 15,
    },   
  },
  currencytext1: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 0,
    },       
  },
  button: {
    marginLeft: theme.spacing(5.4),
    marginRight: theme.spacing(1),
    marginTop: '20px',
    marginBottom: '20px',
    width: theme.spacing(54),
    height: '48px',
    borderRadius: '16px',
    backgroundColor: '#142849',
    fontFamily: 'Roboto Medium, sans-serif, Roboto',
    fontSize: '19px',
    fontWeight: '600',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(9),      
      width: theme.spacing(30),
    },
  },
  button2: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: '20px',
    marginBottom: '20px',
    width: theme.spacing(56),
    height: '48px',
    borderRadius: '16px',
    backgroundColor: '#142849',
    fontFamily: 'Roboto Medium, sans-serif, Roboto',
    fontSize: '19px',
    fontWeight: '600',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),      
      width: theme.spacing(30),
    },
  },
  iconBar: {
    marginTop: theme.spacing(1),
    height: 100,
  },

  formfill: {  
    paddingBottom: '24px',
    color: '#895505',
    fontWeight: 'bold'
  },
  linefill: {    
    color: '#CD853F',
    fontWeight: 'bold',
  },
  needHelpImage: {    
    marginTop: '6px',    
  },
  needhelpText: {
    marginLeft: '0px',
    fontWeight: 'bold',
    color: '#3440E4',
    textDecoration: 'none',
    textTransform: 'none'
  },
  stepsdivider: {
    backgroundColor: '#895505',
  },
  stepsline: {
    height: 30,
    color: '#895505',
    marginRight: '30px',
    fontWeight: 'bold',
    display: 'inline',
    [theme.breakpoints.down('xs')]: { 
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {      
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {      
      display: 'inline',
    },
    [theme.breakpoints.up('lg')]: {      
      display: 'inline',
    },
  },
  formcard: {
    width: '520px',
    marginTop: theme.spacing(8),
    marginBottom: '92px',
    marginRight: theme.spacing(6),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    [theme.breakpoints.down('xs')]: { 
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  formtext1: {
    color: '#1E1E1E',
    fontWeight: '600',
    fontSize: '18px',
    paddingTop: '20px',
    lineHeight: '1.2',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      paddingTop: '8px',
    },
  },
  formtext2: {
    color: '#1E1E1E',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '1.2',
    [theme.breakpoints.down('xs')]: {      
      fontSize: '16px',
    },
  }, 
  reportcard1: {
    paddingTop: '12px',
    fontFamily: 'Roboto Medium, sans-serif, Roboto',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '1.2',
    [theme.breakpoints.down('xs')]: {      
      fontSize: '20px',
    },
  },
  reportcard2: {
    paddingBottom: theme.spacing(1),
    fontFamily: 'Roboto Medium, sans-serif, Roboto',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '1.2',
    [theme.breakpoints.down('xs')]: {      
      fontSize: '20px',
    },
  },
  reportcard3: {
    borderTop: '64px solid #895505',
    borderRightWidth: '41px',
    borderRightStyle: 'solid',
    borderRightColor: '#1c3764',
  },
  reportext: {
    color: 'white',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '22px',
    fontWeight: 'bold',
    width: '450px',
    marginLeft: '50px',
    marginTop: '-15px',
    marginBottom: '8px',
  },
  getStarted: {
    height: 1,
    color: '#483D8B',
    fontWeight: '500',
    paddingLeft: '3px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px'    
  },
  personal: {
    height: '20px',
    color: '#483D8B',
    fontWeight: '500',
    paddingLeft: '4px',    
  },  
  review: {
    height: '20px',
    color: '#483D8B',
    fontWeight: '500',
    paddingLeft: '4px',    
  },
  numberFont: {
    height: '31px',
    color: '#895505',
    fontSize: 37,
    marginBlockStart: '0px',
    marginBlockEnd: '0px'
  },
  fillervertical: {
    paddingTop: '32px',
    paddingBottom: '16px'
  },
  fillerright: {
    marginRight: '60px'
  },
  heightSteps: {
    justifyContent: 'center',    
    [theme.breakpoints.down('xs')]: { 
      height: '550px',
    },
    [theme.breakpoints.up('sm')]: {      
      height: '550px',
    },
    [theme.breakpoints.up('md')]: {      
      height: '120px',
    },
    [theme.breakpoints.up('lg')]: {      
      height: '120px',
    },
  },
  imageSize: {
    [theme.breakpoints.down('xs')]: { 
      height: '40px',
    },
    [theme.breakpoints.up('sm')]: {      
      height: '40px',
    },
    [theme.breakpoints.up('md')]: {      
      height: '45px',
    },
    [theme.breakpoints.up('lg')]: {      
      height: '55px',
    },
  },
  popupContent: {    
    width: '80%'
 },
 modal: {
  fontSize: theme.typography.pxToRem(16),
  opacity: '1',
  backgroundColor: 'white',
},
 modalHeader: {
   textTransform: 'uppercase',
   fontSize: '20px',
   fontWeight: '500',
   textAlign: 'center',
   marginTop: '12px',
   marginBottom: '14px',
   lineHeight: '1.1',
   [theme.breakpoints.down('xs')]: { 
    fontSize: '18px',
  },
 },
 modalContent: {
   marginBottom: theme.spacing(3),
   fontSize: '16px',
   padding: '15px',
   position: 'relative',
   fontFamily: 'Roboto,Calibri,Tahoma,Arial,sans-serif',
   [theme.breakpoints.down('xs')]: { 
    fontSize: '12px',
  },
 },
 modalClose: {
   cursor: 'pointer',
   display: 'block',
   lineHeight: theme.typography.pxToRem(20),
   fontSize: theme.typography.pxToRem(22),
   backgroundColor: 'transparent',   
   position: 'absolute',    
   padding: '2px 5px',    
   right: '0px',
   top: '-1px', 
 },
 buttonBar: {
  height: 68,
}, 
 cancelButton: {
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: 150,
  [theme.breakpoints.down('xs')]: { 
    fontSize: theme.typography.pxToRem(10),
    width: 115,
  },
},
confirmButton: {
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: 200,
  [theme.breakpoints.down('xs')]: { 
    fontSize: theme.typography.pxToRem(10),
    width: 149,
  },
},
});

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false, recaptchaToken: props.recaptchaToken};
  }

  componentDidMount() {
    LoadCombinedReference()
  }

  onClick(event) {
    this.props.history.push('/form')
  }

  openpopup(event) {
    this.props.formCompleted(false)
    this.setState({ open: true })
  }

  closepopup(event) {
    this.setState({ open: false })
  }

  continue(event) {
    this.props.history.push('/form')
  }

  showHelp(type) {
    this.props.history.push('/help')    
  }

  render = () => {
    const { classes } = this.props
    let isStartDisabled = this.props.isStartDisabled
    // console.log("Landing.js - isStartDisabled value: "+ isStartDisabled)    
  
    return (
  <Grid className={classes.landing}>    
    <Grid className={classes.landingHeader}>

    <Grid className={classes.root} >
        <Grid container className={classes.topheader} alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
        <Grid item>
            <Grid container alignItems={"flex-start"} direction={"row"} justifyContent={"flex-start"}>
              <Grid className={classes.currencytext} >FinCEN Form 105</Grid>              
              <Grid className={classes.currencytext1} ><span>&nbsp;</span>| Currency and Monetary Instrument Report (CMIR)</Grid>
            </Grid>  
          </Grid>
          <Grid item>
            <NavLink to="/help" target="help" rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
              <Grid container alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                <img src={Needhelp} alt="needhelp" style={{cursor: 'pointer'}}/>
                <Button autoCapitalize="false" className={classes.needhelpText}  align="center">Need Help?</Button>
              </Grid>
            </NavLink>
          </Grid>
        </Grid>

         <Grid container className={classes.paper} alignItems={"center"} direction={"row"} justifyContent={"flex-end"}> 
                <Grid item>
                  <Paper className={classes.formcard}>
                    <Typography align="center" className={classes.reportcard1}>
                        Report of International Transportation
                    </Typography>
                    <Typography align="center" className={classes.reportcard2}>
                        of Currency or Monetary Instruments
                    </Typography>
                    <Typography align="center" className={classes.formtext1}>                  
                        This form is available for filing to travelers and                   
                    </Typography>                  
                    <Typography align="center" className={classes.formtext2}>
                        currency transporters when entering or departing
                    </Typography>
                    <Typography align="center" className={classes.formtext2}>
                        or shipping or receiving from / to the United States                    
                    </Typography>
                    <Typography align="center" className={classes.formtext2}>
                        with an aggregate amount exceeding $10,000                       
                    </Typography>
                    <Button variant="contained" size="small" color="primary" disabled={isStartDisabled} onClick={() => this.openpopup()} className={classes.button}>
                      Get Started
                    </Button>                
               </Paper>
            </Grid>          
        </Grid>
       
    <Grid className={classes.subpaper} >
          <Grid container alignItems={"center"} justifyContent={"center"} className={classes.formfill} >       
            <p className={classes.stepsline}> ______________________________________</p> 
                  Steps for Filling out Form for Others
            <p className={classes.stepsline} style={{marginLeft:27}}>  _____________________________________________</p>
          </Grid>

       <Grid container className={classes.heightSteps} >        

        <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                  <img className={classes.imageSize} src={Getstarted} alt="getStarted.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>1</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Get<br></br>Started</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

        <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={Personal} alt="personal.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>2</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Personal<br></br>Information</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

        <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={OriginAndDestination} alt="originanddestination.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>3</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Origin and<br></br>Destination</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

        <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={CurrencyInfomation} alt="currencyInformation.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>4</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Monetary<br></br>Instruments</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

        <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={ReviewandSign} alt="reviewandSign.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>5</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Review<br></br>and Sign</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>
        </Grid>



        <Grid className={classes.subpaper} >
          <Grid container alignItems={"center"} justifyContent={"center"} className={classes.formfill} >       
            <p className={classes.stepsline}> ______________________________________</p> 
                  Steps for Filling out Form for Currency Transporter
            <p className={classes.stepsline} style={{marginLeft:27}}>  _____________________________________________</p>
          </Grid>

       <Grid container className={classes.heightSteps} >

       <Grid item xs={12} md={1}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                  <img className={classes.imageSize} src={Getstarted} alt="getStarted.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>1</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Get<br></br>Started</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

        <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={Personal} alt="personal.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>2</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Personal<br></br>Information</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

        <Grid item xs={12} md={1}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={OriginAndDestination} alt="originanddestination.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>3</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Origin and<br></br>Destination</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

        <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={ShipReceive} style={{ paddingLeft: '17px' }} alt="shipandreceive.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>4</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Shipper and<br></br>Receiver</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid> 

        <Grid item xs={12} md={1}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize}  src={ThirdParty} alt="thirdparty.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>5</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Third<br></br>Party</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

        <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={CurrencyInfomation} alt="currencyInformation.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>6</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Monetary<br></br>Instruments</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid> 

       <Grid item xs={12} md={2}>          
          <Grid container alignItems={"center"} direction={"column"} >
                <Grid item>
                <img className={classes.imageSize} src={ReviewandSign} alt="reviewandSign.png" />
                  <Divider className={classes.stepsdivider} />
                  <Grid container alignItems={"flex-start"} justifyContent={"space-evenly"} direction={"row"} >
                    <Grid item>
                      <p className={classes.numberFont}>7</p>
                    </Grid>  
                    <Grid item>  
                      <p className={classes.getStarted}>Review<br></br>and Sign</p>
                    </Grid>  
                  </Grid>     
                </Grid>   
              </Grid>
        </Grid>

       </Grid>
       </Grid>




        <Grid item className={classes.fillervertical}>
          <Grid className={classes.iconBar} container direction="row" justifyContent="center" alignItems="center">
            <Button variant="contained" size="small" color="primary" disabled={isStartDisabled} onClick={() => this.openpopup()} className={classes.button2}>
              Get Started
            </Button>
          </Grid>
        </Grid>

        </Grid>      
      </Grid>

      
      <Popup contentStyle={{ width: '80%' }} open={this.state.open} 
       modal closeOnDocumentClick={false} >
      {close => (
      <Grid className={classes.modal}>
       
      <Grid className={classes.modalHeader}>SECURITY NOTIFICATION</Grid>
        <hr/>
          <Grid className={classes.modalContent}>
          You are about to access a Department of Homeland Security computer system. This computer system and data 
          therein are property of the U.S. Government and provided for official U.S. Government information and use. There is no expectation of privacy when 
          you use this computer system. The use of a password or any other security measure does not establish an expectation of privacy. By using this system, 
          you consent to the terms set forth in this notice. You may not process classified national security information on this computer system. Access to this system 
          is restricted to authorized users only. Unauthorized access, use, or modification of this system or of data contained herein, or in transit to/from this system, 
          may constitute a violation of section 1030 of title 18 of the U.S. Code and other criminal laws. Anyone who accesses a Federal computer system without 
          authorization or exceeds access authority, or obtains, alters, damages, destroys, or discloses information, or prevents authorized use of information on 
          the computer system, may be subject to penalties, fines or imprisonment. This computer system and any related equipment is subject to monitoring for 
          administrative oversight, law enforcement, criminal investigative purposes, inquiries into alleged wrongdoing or misuse, and to ensure proper performance 
          of applicable security features and procedures. DHS may conduct monitoring activities without further notice.
          </Grid>                
        <hr/>
        <Grid className={classes.buttonBar} container direction="row" justifyContent="space-between" alignItems="center">
          <Button variant="outlined" color="secondary" onClick={() => {this.closepopup()}} className={classes.cancelButton}>Cancel & Exit</Button>
          <Button variant="contained" color="primary" onClick={() => this.continue()} className={classes.confirmButton}>Confirm & Continue</Button>
        </Grid>
      </Grid>
       )}
    </Popup>    

      </Grid>
</Grid>  
    );
  }
}

export default withStyles(styles)(Landing);