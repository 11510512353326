import React, { Component } from 'react';
import { Typography, Grid, AppBar, Tabs, Tab } from '@material-ui/core'
import NavigationButtons from '../container/NavigationButtons';
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import Flag from 'react-world-flags'
import MDTextField from './custom/MDTextField';
import AsyncReactSelect from './custom/AsyncReactSelect';

function TabContainer({ children, dir }) {
  return (
    <Grid>
    <Typography component="div" dir={dir} style={{ padding: 8 * 2 }}>
      {children}
    </Typography>
    </Grid>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

// const styles = theme => console.log(theme) || ({ 
const styles = theme => ({   
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    justify: 'center',
    color: theme.palette.text.primary,
    backgroundColor: '#ffffff',
  },
  textField: {
    "& label": {
      color: "grey"
    },
    "& label span": {
      color: "red"
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  subHead: {
    paddingTop: '32px',
    MarginBottom: '0px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tabColorDefault: {
    minHeight: '30px',
    backgroundColor: "#D1D6D7",    
  },
  tabColorSelected: {
    minHeight: '30px',
    backgroundColor: "#4D95A3",    
    color: "default",
    fontWeight: "760"
  }
})

class ExportImport extends Component {
  constructor(props) {
    super(props);
    this.state = {activeStep: props.activeStep, exportImport: props.exportImport, updatePerson: props.updatePerson,
                    value: props.exportImport.exportOrImport === -1? 0 : props.exportImport.exportOrImport, error: false, errorText: '', onUSA: true, uniqId:props.uniqId,
                    requiredFields: {departureCountry: false, departurePort: false, arriveCountry: false, arrivePort: false }};
  }

  static getDerivedStateFromProps(nextProps, prevState) {    
    if (nextProps.activeStep === 4) {
      if(nextProps.exportImport !== prevState.exportImport) {          
        return { activeStep: nextProps.activeStep, exportImport: nextProps.exportImport }
      } return null;
    }    
  }

  handleChange = (propertyName, value) => {  
    const { exportImport } = this.state;
    const newExportImport = {
      ...exportImport,
      [propertyName]: value,
    }; 

    this.setState({ exportImport: newExportImport });
    
    let letter = /^[A-Za-z]+$/;
    let arriveCityPort = newExportImport.arrivePort.match(letter)? newExportImport.arrivePort.toUpperCase(): newExportImport.arrivePort;
    let DepartCityPort = newExportImport.departurePort.match(letter)? newExportImport.departurePort.toUpperCase(): newExportImport.departurePort
    if(newExportImport.departurePort !=='' && newExportImport.arrivePort !=='' && arriveCityPort ===  DepartCityPort) {
      this.setState({ error: true, errorText: 'Arrival and Departure City/Port cannot be same' })
    } else {
      // reset, the errorText
      this.setState({ error: false, errorText: ''})
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ error: false, errorText: '' })  
    const { exportImport } = this.state;
    const newExportImport = {
      ...exportImport,
      exportOrImport: value,
      departurePort: "",
      arrivePort:"",
      departureCountry: value === 1? {
        value: 'USA',
        label: [<Flag code='USA' height="12" />, ' ',  'UNITED STATES'],      
        countryDescription: 'UNITED STATES'
      } : "",
      arriveCountry: value === 0? {
        value: 'USA',
        label: [<Flag code='USA' height="12" />, ' ',  'UNITED STATES'],      
        countryDescription: 'UNITED STATES'
      } : "",    

    };
   
     this.setState({ value: value, exportImport: newExportImport }); 
   
  };

  handleError = (propertyName, error) => {
    const { requiredFields } = this.state;

    const newRequiredFields = {
      ...requiredFields,
      [propertyName]: !error,      
    };
    
    this.setState({ requiredFields: newRequiredFields });
  }

  render = () => {    
    const { classes,locator } = this.props
    const { exportImport, value, error, errorText, requiredFields, onUSA } = this.state
    return (
      <div>
            {/* <Paper className={classes.paper}>                 */}
                <Grid>
                  <AppBar position="relative" color="inherit" className={classes.paper} >
                    <Tabs
                      value={value}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      // textColor="secondary"
                      variant="fullWidth"
                    >
                      <Tab className={value === -1 || value === 0? classes.tabColorSelected : classes.tabColorDefault} label="Import" />
                      <Tab className={value === 1? classes.tabColorSelected : classes.tabColorDefault} label="Export" />
                    </Tabs>
                  
                  {(value === -1 || value === 0) && 
                  <TabContainer>
                   <Grid container direction="row" alignItems="center">
                      <AsyncReactSelect name='departureCountry' label='Departure Country'  value={exportImport.departureCountry} 
                          optionsType='country' placeholder='Search for Country' errorText='Please select country' onUSA={onUSA} handleChange={this.handleChange} handleError={this.handleError} uniqId={locator==='Review'?'departureCountry'+locator:'departureCountry'}  required={true} />
                      <MDTextField name='departurePort' label='Departure City/Port' value={exportImport.departurePort} handleChange={this.handleChange} handleError={this.handleError} required={true} inputProps={{maxLength: 50, id:locator==='Review'?'departurePort'+locator:'departurePort'}} />                      
                    </Grid>
                    <Grid container direction="row" alignItems="center">  
                      <AsyncReactSelect name='arriveCountry' label='Arrival Country' value={exportImport.arriveCountry} optionsType='country' uniqId={locator==='Review'?'arriveCountry'+locator:'arriveCountry'}  summary={true} />
                      <MDTextField name='arrivePort' label='Arrival City/Port' value={exportImport.arrivePort} handleChange={this.handleChange} handleError={this.handleError} error={error} errorText={errorText} required={true} inputProps={{maxLength: 50, id:locator==='Review'?'arrivePort'+locator:'arrivePort'}} />                      
                    </Grid>
                  </TabContainer>}

                  {(value === 1) &&                   
                  <TabContainer dir='row'>
                    <Grid container direction="row" justify="flex-end"  alignItems="center">                      
                      <AsyncReactSelect name='departureCountry' label='Departure Country' value={exportImport.departureCountry} optionsType='country' uniqId={locator==='Review'?'departureCountry'+locator:'departureCountry'}  summary={true} />
                      <MDTextField name='departurePort' label='Departure City/Port' value={exportImport.departurePort} handleChange={this.handleChange} handleError={this.handleError} required={true}  inputProps={{maxLength: 50, id:locator==='Review'?'departurePort'+locator:'departurePort'}} />                      
                    </Grid>
                    <Grid container direction="row" justify="flex-end" alignItems="center">                      
                      <AsyncReactSelect name='arriveCountry' label='Arrival Country'   value={exportImport.arriveCountry} 
                          optionsType='country' placeholder='Search for Country' errorText='Please select country' onUSA={onUSA} handleChange={this.handleChange} handleError={this.handleError}  uniqId={locator==='Review'?'arriveCountry'+locator:'arriveCountry'}  required={true} inputProps={{maxLength: 50, id:locator==='Review'?'arrivePort'+locator:'arrivePort'}} />
                      <MDTextField name='arrivePort' label='Arrival City/Port' value={exportImport.arrivePort} handleChange={this.handleChange} handleError={this.handleError} error={error} errorText={errorText} required={true} />                      
                    </Grid>
                  </TabContainer>}
               </AppBar>                  
             </Grid>

             <NavigationButtons exportImport={exportImport} 
                   allReqDone={(value === 0? requiredFields.departureCountry : true) && requiredFields.departurePort &&
                    (value === 1? requiredFields.arriveCountry : true) && requiredFields.arrivePort && !error} />              
          {/* </Paper>        */}
       </div>
    );
  }
}

export default withStyles(styles) (ExportImport);