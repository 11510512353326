import React, { Component } from 'react';
import {withRouter} from 'react-router';
import { Typography, Paper, Button, Grid, FormControlLabel, Checkbox, IconButton } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NavigationButtons from '../container/NavigationButtons';
import { withStyles } from '@material-ui/core/styles'
import logo from '../images/green_check.png';
import ReactTooltip from "react-tooltip";

const styles = theme => ({  
  paper: {
    padding: theme.spacing(2),    
    textAlign: 'left',    
    color: theme.palette.text.primary,    
    backgroundColor: '#ffffff',
    borderRadius: '12px'
  },  
  question: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: "bold",
    marginBottom: '28px',
    color: '#000000'    
  },
  questionPCT: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: "bold",
    marginBottom: '2px',
    color: '#000000'    
  },
  questionPCT1: {
    fontSize: theme.typography.pxToRem(19),
    marginBottom: '28px',
    color: '#000000'    
  },
  questionExempt: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: "bold",
    // marginBottom: '28px',
    color: '#000000'    
  },
  confirmation: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "500",
    color: '#000000',
    marginLeft: '60px',
    [theme.breakpoints.down('xs')]: { 
      marginLeft: '14px',
    }, 
  },
  exemptText: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: "bold",
    // marginBottom: '2px',
    color: '#000000'    
  },
  exemptText1: {
    fontSize: theme.typography.pxToRem(17),
    fontStyle: 'italic',
    // fontWeight: "bold",
    // marginBottom: '2px',
    color: '#000000'    
  },
  exemptText2: {
    fontSize: theme.typography.pxToRem(17),
    fontStyle: 'italic',
    // fontWeight: "bold",
    marginBottom: '28px',
    color: '#000000'    
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: '600',    
    fontSize: theme.typography.pxToRem(18),
    // paddingLeft: '6px', 
    // paddingRight: '8px'
  },
  button: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "620",
    backgroundColor: "#324bb5",  
    margin: '22px',
    [theme.breakpoints.down('xs')]: { 
      fontSize: theme.typography.pxToRem(14),
      margin: '12px',
    }, 
  },
  infoIcon: {
    //     fontSize: theme.typography.pxToRem(18),
        verticalAlign: 'text-top',
        color: '#3939c8',
        paddingTop: '0px',
        paddingBottom: '12px',
      },

  tooltip: {
    width:300,
    maxWidth:'40%'
  },
})
class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {start: props.start, activeStep: props.activeStep,
      exempt1: false, exempt2: false, exempt3: false, exempt4: false};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
      if(nextProps.start !== prevState.start) { 
        // console.log(JSON.stringify(nextProps.start) + ' ' + JSON.stringify(prevState.start))         
        return { start: nextProps.start }
        // if(prevState.start.personalOrBusiness !== '' && prevState.start.myselfOrSomeone !== '' 
        //     && prevState.start.mailingOrPhysical !== '') {
        //   return { start: nextProps.start }
        // }
      }
  }

  selectPCT = (selection) => {
    const { start } = this.state;

    const newStart = {
      ...start,
      pct: selection,
      mailingOrPhysical: selection === 'Y'? 'M' : start.mailingOrPhysical,
      myselfOrSomeone: selection === 'Y'? 'S' : start.myselfOrSomeone
    };

    this.setState({start: newStart})
    this.props.updateStart(newStart);
  }

  selectMailingOrPhysical = (selection) => {
    const { start } = this.state;

    const newStart = {
      ...start,
      mailingOrPhysical: selection
    };

    this.setState({start: newStart})
    this.props.updateStart(newStart);
  }

  selectPersonalOrBusiness = (selection) => {
    const { start } = this.state;

    const newStart = {
      ...start,
      personalOrBusiness: selection
    };

    this.setState({start: newStart})
    this.props.updateStart(newStart);
  }

  selectMyselfOrSomeone = (selection) => {
    const { start } = this.state;

    const newStart = {
      ...start,
      myselfOrSomeone: selection
    };

    this.setState({start: newStart})
    this.props.updateStart(newStart);
  }

  handleExemptionChecked = (propertyName) => (event) => {
    const { exempt1, exempt2, exempt3, exempt4 } = this.state;
    
    if (propertyName === 'exempt1') {
      this.setState({ exempt1: exempt1? false : true });
    } else
    if (propertyName === 'exempt2') {
      this.setState({ exempt2: exempt2? false : true });
    } else
    if (propertyName === 'exempt3') {
      this.setState({ exempt3: exempt3? false : true });
    } else
    if (propertyName === 'exempt4') {
      this.setState({ exempt4: exempt4? false : true });
    }
    // this.handleChange('acknowledgement', acknowledgement? false : true)
  }

  startOver(event) {    
    this.props.history.push('/');
  }

  render = () => {    
    const { classes } = this.props
    const { start, exempt1, exempt2, exempt3, exempt4 } = this.state

    return (
      // <div>
            <Paper className={classes.paper}>

              {start.pct === '' &&              
              <Grid>
              <Typography className={classes.questionPCT} align='center'>Are you a Professional Currency Transporter/Armored Car Company?
              <span data-tip="" data-for='currTrans'>                  
                  <IconButton aria-label="Info">
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton> </span>
                  <ReactTooltip id ='currTrans' className={classes.tooltip}>
                    {"A Professional Currency Transporter is any one engaged as a business in the transportation of currency, other monetary instruments, or commercial papers."}
                    </ReactTooltip>
              </Typography>              
              <Grid container justifyContent="center">
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Y1')} >
                  Yes
                </Button>
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('N')} >
                  No
                </Button>
              </Grid>
              </Grid>
              }

              {start.pct === 'Y1' && start.personalOrBusiness === '' &&
              <Grid>
              <Typography className={classes.questionPCT} align='center'>Check if exempted from filing?
              <span data-tip="" data-for='currTrans'>                  
                  <IconButton aria-label="Info">
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton> </span>
                  <ReactTooltip id ='currTrans' className={classes.tooltip}>
                    {"Check Exemption to see if you can exempt yourself from filing FinCEN CMIR -- 31 CFR §1010.340 (c)(9).  "  +
                    "If you are aware of the exemption conditions " + 
                    "and know that you can be exempted, you can skip this check."}
                    </ReactTooltip>
              </Typography>              
              <Grid container justifyContent="center">
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Y2')} >
                  Check Exemption
                </Button>
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Y')} >
                  Skip Check
                </Button>
              </Grid>
              </Grid>
              }

              {start.pct === 'Y2' && start.personalOrBusiness === '' &&
              <Grid>
                <Typography className={classes.questionExempt} align='center' gutterBottom>Exemption Check</Typography>
                <Typography className={classes.exemptText1} align='center' gutterBottom>**All of the below should be met</Typography>
                <Typography className={classes.exemptText2} align='center' gutterBottom>**Other exemptions are not applicable when ‘common carriers of currencies’ is involved in the transportation
                <span data-tip="" data-for='currTrans'>                  
                  <IconButton aria-label="Info">
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton> </span>
                  <ReactTooltip id ='currTrans' className={classes.tooltip}>
                    {"Although the CMIR regulations include a number of exemptions that apply to other parties, a common carrier of currency may not claim for itself any exemption for filing a CMIR that might be applicable to other parties. For example, banks, foreign banks or brokers or dealers in securities are themselves exempted from filing a CMIR with respect to currency or other monetary instruments that they mail or ship via the postal service or a common carrier. A common carrier of currency cannot apply this exemption to itself (although, as described above, the limited exemption may apply). For example, if a common carrier of currency picks up at an airport a cargo of currency air-shipped to a U.S. bank from abroad, the common carrier has an obligation to file a CMIR even though the bank does not. -- 31 CFR §1010.340 (c)."}
                    </ReactTooltip>
                </Typography>

                <Grid container alignItems={"center"} justifyContent={"flex-start"}>
                  <FormControlLabel
                    classes={{ label: classes.label }}
                      control={
                      <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      checked={exempt1}
                      onChange={this.handleExemptionChecked('exempt1')}
                      value={exempt1}
                    />
                    }
                    label="The transportation consists of currency or other monetary instruments 
                    imported into the United States or exported out of the United States"
                  />
                </Grid>
                <Grid container alignItems={"center"} justifyContent={"flex-start"}>
                  <FormControlLabel
                    classes={{ label: classes.label }}
                      control={
                      <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      checked={exempt2}
                      onChange={this.handleExemptionChecked('exempt2')}
                      value={exempt2}
                    />
                    }
                    label="The transportation takes place overland"
                  />
                </Grid>
                <Grid container alignItems={"center"} justifyContent={"flex-start"}>
                  <FormControlLabel
                    classes={{ label: classes.label }}
                      control={
                      <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      checked={exempt3}
                      onChange={this.handleExemptionChecked('exempt3')}
                      value={exempt3}
                    />
                    }
                    label="The transportation takes place between a bank or a broker or dealer in 
                    securities, on the U.S. side, and a non-U.S. person, on the foreign side"
                  />
                </Grid>
                <Grid container alignItems={"center"} justifyContent={"flex-start"}>
                  <FormControlLabel
                    classes={{ label: classes.label }}
                      control={
                      <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      checked={exempt4}
                      onChange={this.handleExemptionChecked('exempt4')}
                      value={exempt4}
                    />
                    }
                    label="The shipment is picked up or delivered at the established office of the 
                    bank or a broker or dealer in securities on the U.S"
                  />
                </Grid>



                <Grid container justifyContent="center">  
                  <Button className={classes.button} disabled={exempt1 && exempt2 && exempt3 && exempt4}
                  variant="contained" color="primary" onClick={() => this.selectPCT('Y')} >
                    Proceed
                  </Button>
                  <Button className={classes.button} disabled={!exempt1 || !exempt2 || !exempt3 || !exempt4}
                   variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Y3')} >
                    Exempted
                  </Button>                  
                </Grid>
              </Grid>
              }

             {start.pct === 'Y3' &&
              <Grid>
              <Typography className={classes.question} align='center' gutterBottom>You are Exempted from Filing</Typography>
              <Grid container justifyContent="center">
                <Button className={classes.button} variant="contained" color="primary" onClick={() => this.startOver()} >
                  Exit to Home
                </Button>
                {/* <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPCT('Y')} >
                  Still Proceed to File
                </Button> */}
              </Grid>
              </Grid>
              }

              {(start.pct === 'N' || start.pct === 'Y') && start.personalOrBusiness === '' &&             
              <Grid>
              <Typography className={classes.question} align='center' gutterBottom>Are you transporting currency or monetary instruments for personal or business purposes?</Typography>
              <Grid container justifyContent="center">
                <Button className={classes.button} variant="contained" color="primary" onClick={() => this.selectPersonalOrBusiness('P')} >
                  Personal
                </Button>
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectPersonalOrBusiness('B')} >
                  Business
                </Button>
              </Grid>
              </Grid>
              }

              {start.personalOrBusiness !== '' && start.mailingOrPhysical === '' &&              
              <Grid>
              <Typography className={classes.question} align='center' gutterBottom>How are you transporting the currency or monetary instruments?</Typography>
              <Grid container justifyContent="center">               
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectMailingOrPhysical('P')} >
                  Physically Transporting
                </Button>
                <Button className={classes.button} variant="contained" color="primary" onClick={() => this.selectMailingOrPhysical('M')} >
                  Mailing / Shipping
                </Button>
              </Grid>
              </Grid>
              }

              {start.personalOrBusiness !== '' && start.mailingOrPhysical !== '' && start.myselfOrSomeone === '' &&
              <Grid>
              <Typography className={classes.question} align='center' gutterBottom>Does the currency or monetary instruments belong to the traveler or someone else?</Typography>
              <Grid container justifyContent="center">
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectMyselfOrSomeone('M')} >
                  Traveler 
                </Button>
                <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={() => this.selectMyselfOrSomeone('S')} >
                  Someone Else
                </Button>
              </Grid>
              </Grid>
              }
              
              {start.mailingOrPhysical !== '' && start.personalOrBusiness !== '' && start.myselfOrSomeone !== '' &&
              // <Grow in={true} {...{ timeout: 2000 } }>
              <Grid>
                <Typography className={classes.question} align='center' gutterBottom>Review your selections. Press Next to Continue.</Typography>
                {start.pct === 'Y' &&
                <Typography  className={classes.confirmation} justifyContent='center' align='left' gutterBottom>
                    <img src={logo} alt="Logo" height="15px" width="20px" style={{marginRight: 0.5 +'em'}}/>
                    Professional Currency Transporter/Armored Car Company
                </Typography>
                }
                {start.pct === 'Y' &&
                <Typography  className={classes.confirmation} justifyContent='center' align='left' gutterBottom>
                    <img src={logo} alt="Logo" height="15px" width="20px" style={{marginRight: 0.5 +'em'}}/>
                    {start.personalOrBusiness === 'P'? 'Personal Currency Carrier' : 'Business Currency Carrier'}
                </Typography>
                }
                {start.pct === 'N' &&
                <Typography  className={classes.confirmation} justifyContent='center' align='left' gutterBottom>
                    <img src={logo} alt="Logo" height="15px" width="20px" style={{marginRight: 0.5 +'em'}}/>
                    {start.personalOrBusiness === 'P'? 'Personal Travel' : 'Business Travel'}
                </Typography>
                }
                {start.pct === 'N' &&
                <Typography  className={classes.confirmation} justifyContent='center' align='left' gutterBottom>
                  <img src={logo} alt="Logo" height="15px" width="20px" style={{marginRight: 0.5 +'em'}}/>
                  {start.mailingOrPhysical === 'M'? 'Mailing / Shipping' : 'Physically Transporting'}
                </Typography>
                }
                 {start.pct === 'N' &&
                <Typography className={classes.confirmation} align='left' gutterBottom>
                  <img src={logo} alt="Logo" height="15px" width="20px" style={{marginRight: 0.5 +'em'}}/>
                  {start.myselfOrSomeone === 'M'? 'Currency or Monetary Instrument belongs to Traveler' : 'Currency or Monetary Instrument belongs to Someone Else'}
                </Typography>
                }
                <NavigationButtons start={start} />
              </Grid>
              // </Grow>
              }
          </Paper>       
      //  </div>
    );
  }
}

export default withRouter(withStyles(styles)(Start));