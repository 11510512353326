import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import LoadingOverlay from './LoadingOverlay'

const ProgressCircle = () => {
    const { promiseInProgress } = usePromiseTracker();    
    return(
        (promiseInProgress === true) ?  <LoadingOverlay/> : null
    )
} ;

export default ProgressCircle;