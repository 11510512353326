import { connect } from 'react-redux'
import ExportImport from '../component/ExportImport'

const mapStateToProps = state => ({
  start: state.start,
  activeStep: state.activeStep,
  exportImport: state.exportImport,
  person: state.person,
  
  addresses: state.addresses
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps) (ExportImport);
