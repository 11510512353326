import { updateReceipt } from '../actions'
import dateFormat from 'dateformat'
import ip from 'ip'

export const getJWTTokenFromProxy = () => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    return process.env.REACT_APP_JWT_TOKEN;
  } else {    
    var req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);
    var headers = req.getAllResponseHeaders();
    var splitted = headers.split('\r\n');  
    // console.log('split ' + JSON.stringify(splitted));      
    let obj = splitted.find(x => x.startsWith('referer_intro_let'));
    let jwttoken = obj.substring(18);
    return jwttoken;
  }
}

const formPartOne = (person, exportImport, carrier, mailship, ship) => {
  // console.log("mailship: " + JSON.stringify(mailship));
  // console.log("ship: " + JSON.stringify(ship));
  return { 
    person: {
      lastName: person.business !== ''? person.business : person.last,
      firstName: person.first,
      middleName: person.middle,
      dob: person.business !== ''? undefined : dateFormat(person.dob, "UTC:yyyymmdd"),
      citizenship: person.coc.value
    }, 
    filerPersonOrBusiness: {key: person.business !== ''? 'B' : 'P'},
    identificationNumber: person.idnum,
    filerIdentification: 'S',   
    documentDetails: {
      documentNumber: person.passport,
      issueCountryCode: person.passportCountry.value,
      documentIssueDate: person.coc.value === 'USA'? undefined : dateFormat(person.visaDate, "UTC:mm/dd/yyyy"),
      alienRegistrationNumber: person.passportCountry.value === 'USA'? undefined : person.alienNumber    
    },
    visaIssuedPlace: {
      city: person.visaIssued,
      // state: 'NY',
      // iscCountryCode: 'USA'
    },    
    exportImportCode: exportImport.exportOrImport !== -1? (exportImport.exportOrImport === 1? 'A' : 'B') : undefined,
    exportImportFrom: exportImport.exportOrImport !== -1? ({
      city: exportImport.departurePort,
      // state: 'FL',
      iscCountryCode: exportImport.departureCountry.value
    }) : undefined,
    exportImportTo: exportImport.exportOrImport !== -1? ({
      city: exportImport.arrivePort,
      // state: 'PR',
      iscCountryCode: exportImport.arriveCountry.value
    }) : undefined,
    currencyShippedOrReceivedPersonOrBusiness: {key: ship.business !== ''? 'B' : 'P'},
    currencyReceivedPersonOrBusiness: {key: mailship.business !== ''? 'B' : 'P'},
    // shippedOrReceivedCode: mailship.sendOrReceive === -1 || mailship.sendOrReceive === 0? 'S' : 'R',
    shippedOrReceivedCode: mailship.sendOrReceive === -1 || mailship.sendOrReceive === 0 || mailship.sendOrReceive === 2? 'S' : undefined,
    receivedCode: mailship.sendOrReceive === 1 || mailship.sendOrReceive === 2? 'R' : undefined,
    receiverName: {
      lastName: mailship.sendOrReceive === 1 || mailship.sendOrReceive === 2?
        (mailship.business !== ''? mailship.business : mailship.last) : undefined,
      firstName: mailship.sendOrReceive === 1 || mailship.sendOrReceive === 2? mailship.first : undefined,
      middleName: mailship.sendOrReceive === 1 || mailship.sendOrReceive === 2? mailship.middle : undefined,
      // businessName: ''
    },
    shippedOrReceived: {
      lastName: mailship.sendOrReceive === -1 || mailship.sendOrReceive === 0? 
        (mailship.business !== ''? mailship.business : mailship.last) :
        (ship.business !== ''? ship.business : ship.last),
      firstName: mailship.sendOrReceive === -1 || mailship.sendOrReceive === 0? mailship.first : ship.first,
      middleName: mailship.sendOrReceive === -1 || mailship.sendOrReceive === 0? mailship.middle : ship.middle,
      // businessName: ''
    },
    shippedDate: mailship.sendOrReceive === -1 || mailship.sendOrReceive === 0 || mailship.sendOrReceive === 2? mailship.shipDate : undefined,
    receivedDate: mailship.sendOrReceive === 1? mailship.receiveDate : undefined,
    methodOfShipment: mailship.methodOfShipment,    
    carrierPersonOrBusiness: {key: carrier.business !== ''? 'B' : 'P'},
    nameOfCarrier: {
      lastName: carrier.business !== ''? carrier.business : carrier.last,
      firstName: carrier.first,
      middleName: carrier.middle,
      businessName: mailship.sendOrReceive === 2? undefined : mailship.carrierName,
    },
    numberOfShippers: '1'

    // shippedTo: mailship.first + ' ' + mailship.middle + ' ' + mailship.last,
    // receivedFrom: mailship.first + ' ' + mailship.middle + ' ' + mailship.last,
    // otherIdentificationNumber: 'R457885',
    // otherIdentificationIssuedBy: 'FRA',
    // otherIdentificationType: 'P'
    }
 }

const currenciesList = (currencies, coins, others) =>
    currencies.map((currency, index) => ({
      sequence: index + 1,   
      currencyIndicator: currency.type.value === 'cash'? 'Y' : 'N',
      coinIndicator: currency.type.value === 'coin'? 'Y' : 'N',   
      otherMonetaryInstrumentsIndicator: (currency.type.value !== 'cash' && currency.type.value !== 'coin')? 'Y' : 'N',
      otherMonetaryInstrumentsType: (currency.type.value !== 'cash' && currency.type.value !== 'coin')? currency.type.label : undefined,
      currencyCountryCode: currency.country.value,
      currencyAmount: currency.type === 'cash'? currency.amount : undefined,
      coinAmount: currency.type === 'coin'? currency.amount : undefined,
      otherMonetaryInstrumentsAmount: (currency.type !== 'cash' && currency.type !== 'coin')? currency.amount : undefined,
      currencyName: currency.currencyName,
      currencyISCCountryCode: currency.country.value,
      foreignExchangeRate: currency.exchangeRate,
      totalDollarAmount: Math.round(currency.usdEquivalent)
    }))

  // Array.prototype.concat(
  // currencies.map((currency, index) => ({
  //     sequence: index + 1,   
  //     currencyIndicator: 'Y',
  //     coinIndicator: 'N',   
  //     otherMonetaryInstrumentsIndicator: 'N',
  //     currencyCountryCode: currency.country.value,
  //     currencyAmount: currency.amount,
  //     currencyName: currency.currencyName,
  //     currencyISCCountryCode: currency.country.value,
  //     foreignExchangeRate: currency.exchangeRate,
  //     totalDollarAmount: Math.round(currency.usdEquivalent)
  //   })),
  //   coins.map((coin, index) => ({
  //     sequence: currencies.length + index + 1,   
  //     currencyIndicator: 'N',
  //     coinIndicator: 'Y',   
  //     otherMonetaryInstrumentsIndicator: 'N',
  //     currencyCountryCode: coin.country.value,
  //     coinAmount: coin.amount,
  //     currencyName: coin.currencyName,
  //     currencyISCCountryCode: coin.country.value,
  //     foreignExchangeRate: coin.exchangeRate,   
  //     totalDollarAmount: Math.round(coin.usdEquivalent)
  //   })),
  //   others.map((other, index) => ({
  //     sequence: currencies.length + coins.length + index + 1,   
  //     currencyIndicator: 'N',
  //     coinIndicator: 'N',   
  //     otherMonetaryInstrumentsIndicator: 'Y',
  //     otherMonetaryInstrumentsType: other.otherType.otherTypeDescription,
  //     currencyCountryCode: other.country.value,
  //     otherMonetaryInstrumentsAmount: other.amount,
  //     currencyName: other.currencyName,
  //     currencyISCCountryCode: other.country.value,
  //     foreignExchangeRate: other.exchangeRate,   
  //     totalDollarAmount: Math.round(other.usdEquivalent)
  //   }))  
  // )

const addressList = (addresses) => {
   return addresses.map((address) => ({
      addressType: address.type,
      city: address.city.value ==="OTHER"? address.othercity: address.city.value !== undefined? address.city.label : address.city,
      state: address.state.value !== undefined? address.state.value : address.state,
      country: address.country.value,
      iscCountryCode: address.country.value,
      countyName: '',
      streetName: address.streetName,
      zip: address.zip
   }))}

const formPartTwo = (thirdparty) => {
  return {
    behalfPersonInfo: {      
      firstName: thirdparty.first,
      // fourthName: '',
      lastName: thirdparty.business !== ''? thirdparty.business : thirdparty.last,
      middleName: thirdparty.middle,
      // otherName: ''
    },
    //Behalf person is Agent or not. Use N if behalf person Last Name is entered. Use Y if Business Name is entered
    behalfPersonOrAgent: thirdparty.business !== ''? 'Y' : 'N',
    behalfPersonOrBusiness: {key: thirdparty.business !== ''? 'B' : 'P'}, //'P' 'B' 'X'-unknown
    businessType: thirdparty.businessTypeOrProfession,
    isBusinessABank: thirdparty.isBank? 'Y' : 'N',
    numberOfOwners: '1'
  }
}

const formPartFour = (review) => {
  return {
    isFormSighned : review.sigImageBase64? 'Y' : 'N',
    personReportingName: review.signName,
    personReportingTitle: review.signTitle,
    reportingDate: dateFormat(review.signDate, "UTC:mm/dd/yyyy"),
    acknowledgeTerms: review.declaration && review.acknowledgement? 'Y' : 'N',
    signature: (review.sigImageBase64).substring(22, review.sigImageBase64.length + 1)
  }
}

const provisionalCmirRecord = (start, person, addresses, thirdparty, exportImport, carrier, mailship, ship, currencies, coins, others, review) => {
   return {
    cmirRecord: {
      portCode: '9999',
      formPartOne: formPartOne(person, exportImport, carrier, mailship, ship),
      formPartTwo: thirdparty.business !== '' || thirdparty.last !== ''? formPartTwo(thirdparty) : undefined,
      // formPartThree: formPartThree,
      formPartFour: formPartFour(review),
      addressList: addressList(addresses),
      currencies: currenciesList(currencies, coins, others)      
    },
    ipAddress: ip.address(),
    isCurrencyTransport: start.pct
    // ipAddress: '2001:0db8:85a3:0000:0000:8a2e:0370:7334',
    // eMailAddress: 'TEST@ECMIR.COM',
    // phoneNumber: '787-787-7878'
  }
}

const request = (start, person, addresses, thirdparty, exportImport, carrier, mailship, ship, currencies, coins, others, review) =>  {
  return {
    // header: header,
    provisionalCmirRecord: provisionalCmirRecord(start, person, addresses, thirdparty, exportImport, carrier, mailship, ship, currencies, coins, others, review)
  }
}

export const UpdateReceipt = () => {
  // console.log('receiptcheck ' + receipt.confirmationNumber)
  updateReceipt()
  // console.log('receiptcheck2 ' + receipt.confirmationNumber)
}

export const Create = (start, person, addresses, thirdparty, exportImport, carrier, mailship, ship, currencies, coins, others, review) => { 
  console.log("Creating provisional cmir")
  return fetch('/provisionalCMIR/createProvisionalCMIR', { 
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJWTTokenFromProxy(),
    },
    body: 
      JSON.stringify( request(start, person, addresses, thirdparty, exportImport, carrier, mailship, ship, currencies, coins, others, review) )
  })        
  // Try to parse the response
  .then(response => 
      ({
        headers: response.headers,
        status: response.status,
        statusText: response.statusText
      })
  )
  .then(
    // Both fetching and parsing succeeded!
    ({ headers, status, statusText }) => {
      if (status >= 400) {
        // Status looks bad
        console.log('Bad status ' + headers)
        throw new Error("Error Code: ".concat(status,statusText))
      } else {
        // Status looks good
        console.log(headers.get('confirmationnumber'))

        // Set Up Info Needed for the Receipt Page
        receipt.totalUSD = 0.00;
        receipt.statusCode = status
        receipt.confirmationNumber = headers.get('confirmationnumber')
        receipt.firstName = person.first
        receipt.lastName = person.last
        receipt.business = person.business
        receipt.submissionDate = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', hour12: false}).format(Date.now())
        receipt.expireDate = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', hour12: false}).format(new Date().getTime() + ((3*24*60*60*1000)))
        currencies.map((currency) => receipt.totalUSD += Number(Math.round(currency.usdEquivalent)))
        receipt.currencyTransporter = mailship.sendOrReceive === 2? 'Y' : 'N'
        receipt.workflow = start.mailingOrPhysical
        
        return receipt
      }
    },
    // Either fetching or parsing failed!
    err => {
      console.log('Error ' + err)
      throw new Error(err)
    })
}

let receipt = {
  statusCode: '',
  confirmationNumber: '',
  firstName: '',
  lastName: '',
  business: '',
  submissionDate: '',
  expireDate: '',
  totalUSD: 0.00,
  currencyTransporter: 'N',
  workflow: ''
}