const request = (address) =>  {
  return {
      address1: address.streetName,
      city: address.city === null ? "" : address.city.value === undefined ? address.city : address.city.label,
      state: address.state.value === undefined? address.state : address.state.value,
      country: address.country.value,
      postalcode : address.zip,
      hint: 'trillium,raw'      
  }
}

let addressURL = '/json?';

//this function will be used during unit test
export const url = (inurl) => {
    addressURL = inurl + '/json?'
}

export const validate = (address) => {
  // var url = new URL('https://10.159.89.42:18443/json?address1=8005+Lake+drive&city=manassas&state=va&country=us&postalcode=20111&hint=trillium,raw')
  // console.log(address)
  var url = addressURL + new URLSearchParams(request(address));
  // console.log('url ' + url)  

  return fetch(url, {
    method: "GET",
    headers: {
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
    },
  })        
  // Try to parse the response
  .then(response => {
    // console.log(response)
    return response.json()
  })
  .then(data => {
    // console.log(data)
    return data
  })  
  .catch(function (error) {
    console.log('request failed ', error)
  })
  
}